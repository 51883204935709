export const userHasHousemapPassQuestion = {
	nepali: "तपाईको घर नक्सा पास भएको छ ?",
	english: "Do your house map passed or not?",
};

export const userHasHousemapPass = [
	{
		nepali: "नक्सा मात्र पास भएको",
		english: "Yes",
		value: "1",
	},
	{
		nepali: "सम्पन्नताको प्रमाणपत्र भएको",
		english: "No",
		value: "2",
	},
	{
		nepali: "घर नक्सा पास बनाउनु पर्ने",
		english: "No",
		value: "3",
	},
];

export const isNapiQn = {
	english: "",
	nepali: "नापी भएको छ ?",
};

export const isNapi = [
	{
		english: "",
		nepali: "छ",
		value: "1",
	},
	{
		english: "",
		nepali: "छैन",
		value: "2",
	},
];

export const aelaniJaggaKisimQn = {
	english: "",
	nepali: "ऐलेनी जग्गा कुन किसिमको हो?",
};

export const aelaniJaggaKisim = [
	{
		english: "",
		nepali: "वगर र वाटो",
		value: "1",
	},
	{
		english: "",
		nepali: "खोलाधार",
		value: "2",
	},
	{
		english: "",
		nepali: "कुलो",
		value: "3",
	},
	{
		english: "",
		nepali: "वन क्षेत्र",
		value: "4",
	},
	{
		english: "",
		nepali: "गाउव्लक",
		value: "5",
	},
];

export const hasChildWorkerQn = {
	english: "",
	nepali:
		"तपाईको घरबाट १६ वर्षभन्दा कम उमेरका केटा - केटी अरुकोमा  काम गर्न गएका छन् ? (बाल श्रमिक)",
};

export const hasChildWorker = [
	{
		english: "",
		nepali: "छन्",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैनन्",
		value: "no",
	},
];

export const maleChildWorkerQn = {
	english: "",
	nepali:
		"तपाईको घरबाट १६ वर्षभन्दा कम उमेरका कति जना  केटाहरु (बालक ) कस्तो  काम गर्न गएका छन् ? (बाल श्रमिक)",
};

export const maleChildDomesticWorkerQn = {
	english: "",
	nepali: "घरेलुकामदार",
};

export const maleChildHotelWorkerQn = {
	english: "",
	nepali: "होटलतथा रेष्टुरेन्ट",
};

export const maleChildIndustryWorkerQn = {
	english: "",
	nepali: "उद्योगतथा व्यापार",
};

export const maleChildOtherWorkerQn = {
	english: "",
	nepali: "अन्य",
};

export const maleChildWorker = [
	{
		english: "",
		nepali: "१ जना",
		value: "117",
	},
	{
		english: "",
		nepali: "२ जना",
		value: "118",
	},
	{
		english: "",
		nepali: "३ जना",
		value: "119",
	},
	{
		english: "",
		nepali: "४ जना",
		value: "120",
	},
	{
		english: "",
		nepali: "५ वा ५ भन्दा बढी",
		value: "121",
	},
];

export const femaleChildWorkerQn = {
	nepali:
		"तपाईको घरबाट १६ वर्षभन्दा कम उमेरका केटी (बालिका ) कहाँ  काम गर्न गएका छन् ? (बाल श्रमिक)",
	english: "Do your any family member beleow age of 16 are working?",
};

export const femaleChildDomesticWorkerQn = {
	english: "",
	nepali: "घरेलुकामदार",
};

export const femaleChildHotelWorkerQn = {
	english: "",
	nepali: "होटल तथा रेष्टुरेन्ट",
};

export const femaleChildIndustryWorkerQn = {
	english: "",
	nepali: "उद्योग तथा व्यापार",
};

export const femaleChildOtherWorkerQn = {
	english: "",
	nepali: "अन्य",
};

export const femaleChildWorker = [
	{
		english: "",
		nepali: "0",
		value: "407",
	},
	{
		english: "",
		nepali: "१ जना",
		value: "408",
	},
	{
		english: "",
		nepali: "२ जना",
		value: "409",
	},
	{
		english: "",
		nepali: "३ जना",
		value: "410",
	},
	{
		english: "",
		nepali: "४ जना",
		value: "411",
	},
	{
		english: "",
		nepali: "५ वा ५ भन्दा बढी",
		value: "412",
	},
];

export const totalFamilyIncomeQn = {
	nepali: "परिवारको जम्मा आम्दानी कति छ?",
	english: "Family total income",
};

export const isconflictVictimQn = {
	english: "",
	nepali: "परिवारमा द्वन्द्वबाट प्रत्यक्ष रुपले पीडित कोहि छन् ?",
};

export const isconflictVictim = [
	{
		english: "",
		nepali: "छन्",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैनन्",
		value: "no",
	},
];

export const yesConflictVictimQn = {
	english: "",
	nepali: "यदि छ भने किसिममा संख्या उल्लेख गर्नुहोस |",
};

export const conflictVictimDeathQn = {
	english: "",
	nepali: "मृत्यु",
};

export const conflictVictimInjured = {
	english: "",
	nepali: "घाइते अपाङ्गता",
};

export const conflictVictimPainQn = {
	english: "",
	nepali: "शारीरिकयातना",
};

export const conflictVictimOtherQn = {
	english: "",
	nepali: "अन्य",
};

export const conflictVictim = [
	{
		english: "",
		nepali: "१ जना",
		value: "222",
	},
	{
		english: "",
		nepali: "२ जना",
		value: "333",
	},
	{
		english: "",
		nepali: "३ जना",
		value: "444",
	},
	{
		english: "",
		nepali: "४ जना",
		value: "555",
	},
	{
		english: "",
		nepali: "५ वा ५ भन्दा बढी",
		value: "666",
	},
];

export const houseStoreyQn = {
	english: "",
	nepali: "घरकति तल्लाको छ |",
};

export const houseStorey = [
	{
		english: "",
		nepali: "भुई - तल्ला",
		value: "1",
	},
	{
		english: "",
		nepali: "एक तल्ला",
		value: "2",
	},
	{
		english: "",
		nepali: "दुई तल्ला",
		value: "3",
	},
	{
		english: "",
		nepali: "तीन तल्ला",
		value: "4",
	},
	{
		english: "",
		nepali: "चार तल्ला",
		value: "5",
	},
	{
		english: "",
		nepali: "पांच  वा पांच भन्दा बढी",
		value: "6",
	},
];

export const wasteManagementQn = {
	english: "",
	nepali: "घरबाट निस्केको फोहोर कहाँ विसर्जन गर्नु हुन्छ ?",
};

export const wasteManagement = [
	{
		english: "",
		nepali: "निश्चित स्थान / खाल्डो",
		value: "1",
	},
	{
		english: "",
		nepali: "करेसाबारीमा",
		value: "2",
	},
	{
		english: "",
		nepali: "जथाभावी",
		value: "3",
	},
];

export const wasteOrganicYesNoQn = {
	english: "",
	nepali: "फोहोर  जैविक - अजैविक छुट्टै ब्वास्थापन गर्नु भएको छ ?",
};

export const wasteOrganic = [
	{
		english: "",
		nepali: "छ",
		value: "1",
	},
	{
		english: "",
		nepali: "छैन",
		value: "2",
	},
];

export const houseRoofFarmQn = {
	english: "",
	nepali: "घरमा करेसाबारी छ ?",
};

export const houseRoofFarm = [
	{
		english: "",
		nepali: "छ",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैन",
		value: "no",
	},
];

export const roofFarmMalQn = {
	english: "",
	nepali: "करेसाबारीमा कस्तो मल प्रयोग गर्नु भएको छ ?",
};

export const roofFarmMal = [
	{
		english: "",
		nepali: "जैविक",
		value: "1",
	},
	{
		english: "",
		nepali: "रसयेनिक",
		value: "2",
	},
	{
		english: "",
		nepali: "कम्पोस्ट / गोवर",
		value: "3",
	},
	{
		english: "",
		nepali: "छासमिस",
		value: "4",
	},
];

export const roofFarmBisadiQn = {
	english: "",
	nepali: "करेसाबारीमा बिसदी प्रयोग गर्नुहुन्छ ?",
};

export const roofFarmBisadi = [
	{
		english: "",
		nepali: "छ",
		value: "Y",
	},
	{
		english: "",
		nepali: "छैन",
		value: "N",
	},
];

export const drinkingWaterOtherQn = {
	english: "",
	nepali: "अन्य",
};

export const drinkingWaterOther = [
	{
		nepali: "धारा / पाईप",
		english: "Water pipe in home",
		value: "tap_home",
	},
	{
		nepali: "टुबेल / हाते पम्प",
		english: "Public pipe",
		value: "handpump",
	},
	{
		nepali: "ढाकिएको इनार / कुवा",
		english: "Tubewell / handpump",
		value: "well_cover",
	},
	{
		nepali: "खुला इनार / कुवा",
		english: "Well",
		value: "well_uncover",
	},
	{
		nepali: "मूलधारा",
		english: "Well uncover",
		value: "main_water",
	},
	{
		nepali: "नदी खोला",
		english: "Mulko Pani",
		value: "river",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "others",
	},
];

export const ifDauraQn = {
	english: "",
	nepali: "यदि दाउरा भए दाउराको स्रोत कुन हो ?",
};

export const ifDaura = [
	{
		english: "",
		nepali: "निजी वन",
		value: "1",
	},
	{
		english: "",
		nepali: "सामुदायिक वन",
		value: "2",
	},
	{
		english: "",
		nepali: "कबुलियती वन",
		value: "3",
	},
	{
		english: "",
		nepali: "सरकारी वन",
		value: "4",
	},
	{
		english: "",
		nepali: "अन्यः",
		value: "5",
	},
];

export const cookingStoveQn = {
	english: "",
	nepali: "खाना पकाउने चुल्होको प्रयोगः",
};

export const cookingStove = [
	{
		english: "",
		nepali: "अगेनो वा परम्परागत चुल्हो",
		value: "1",
	},
	{
		english: "",
		nepali: "धुवाँ रहित चुल्हो",
		value: "2",
	},
	{
		english: "",
		nepali: "भुसे चुल्हो",
		value: "3",
	},
	{
		english: "",
		nepali: "ग्याँस चुल्हो",
		value: "4",
	},
	{
		english: "",
		nepali: "विद्युतीय चुल्हो",
		value: "5",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "6",
	},
];

export const houseCommunicationQn = {
	english: "",
	nepali: "घरमा प्रयोग हुने सञ्चारको साधनः",
};

export const houseCommunication = [
	{
		english: "",
		nepali: "रेडियो",
		value: "radio",
	},
	{
		english: "",
		nepali: "टेलिभिजन",
		value: "television",
	},
	{
		english: "",
		nepali: "टेलिफोन/मोबाइल",
		value: "mobile",
	},
	{
		english: "",
		nepali: "इन्टरनेट",
		value: "internet",
	},
	{
		english: "",
		nepali: "फ्याक्स",
		value: "fax",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "other",
	},
];

export const toiletStatusQn = {
	english: "",
	nepali: "शौचालय प्रयोगको अवस्थाः",
};

export const toiletStatus = [
	{
		english: "",
		nepali: "चर्पी नभएको",
		value: "1",
	},
	{
		english: "",
		nepali: "फ्लस भएको (सेफ्टिक ट्याङ्की)",
		value: "2",
	},
	{
		english: "",
		nepali: "फ्लस भएको (सार्वजनिक ढल)",
		value: "3",
	},
	{
		english: "",
		nepali: "साधारण शौचालय",
		value: "4",
	},
	{
		english: "",
		nepali: "बाथ रुम भएको/नभएको",
		value: "5",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "6",
	},
];

export const homeCleaningStatusQn = {
	english: "",
	nepali: "व्यक्तिगत तथा घरायसी सरसफाईको अवस्था",
};

export const homeCleaningStatus = [
	{
		english: "",
		nepali: "साबुन पानीले हात धुने",
		value: "1",
	},
	{
		english: "",
		nepali: "फोहर व्यवस्थापन खाल्डो",
		value: "2",
	},
	{
		english: "",
		nepali: "भाँडा सुकाउने ठाउँ",
		value: "3",
	},
	{
		english: "",
		nepali: "घर आगन सरसफाई",
		value: "4",
	},
	{
		english: "",
		nepali: "गाइ भैंसी वस्तुभाउ राख्ने छुट्टै गोठ भएको",
		value: "5",
	},
	{
		english: "",
		nepali: "घरको भित्री भित्ता लिपपोत गरिएको",
		value: "6",
	},
	{
		english: "",
		nepali: "घरको बाहिरी भित्ता लिपेको",
		value: "7",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "8",
	},
];

export const selfFoodingStatusQn = {
	english: "",
	nepali: "खाद्यान्‍न आत्मनिर्भरताको अवस्थाः",
};

export const selfFoodingStatus = [
	{
		english: "",
		nepali: "३ महिनाभन्दा कम खान पुग्ने",
		value: "1",
	},
	{
		english: "",
		nepali: "४-६ महिना  खान पुग्ने",
		value: "2",
	},
	{
		english: "",
		nepali: "७-९ महिना  खान पुग्ने",
		value: "3",
	},
	{
		english: "",
		nepali: "१०-१२ महिना  खान पुग्ने",
		value: "4",
	},
	{
		english: "",
		nepali: "आफ्नो खेतबारीको उत्पादनबाट खान पुगेर बेचबिखन गर्ने",
		value: "5",
	},
];

export const ifFoodNotEnoughQn = {
	english: "",
	nepali: "खाद्यान्न अपुग भए अपुग खाद्यान्नको परिपूर्ति कसरी हुन्छ ?",
};

export const ifFoodNotEnough = [
	{
		english: "",
		nepali: "ज्याला मजदुरी",
		value: "labour",
	},
	{
		english: "",
		nepali: "जागिर/पेन्सन",
		value: "salary",
	},
	{
		english: "",
		nepali: "वैदेशिक रोजगारी",
		value: "foreign_employment",
	},
	{
		english: "",
		nepali: "कृषि उपज बिक्री",
		value: "agriculture",
	},
	{
		english: "",
		nepali: "पशुपक्षी बिक्री",
		value: "livestock",
	},
	{
		english: "",
		nepali: "अधिया/साझे कमाएर",
		value: "shared_earning",
	},
	{
		english: "",
		nepali: "ऋण सापटी",
		value: "loan",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "other",
	},
];

export const totalFamilyOnRentQn = {
	english: "",
	nepali: "यदी हुन्छ भने कति परिवार बस्नु हुन्छ ?",
};
export const rentMaleQn = {
	english: "",
	nepali: "भाडामा बस्ने पुरुषको संख्या |",
};
export const rentFemaleQn = {
	english: "",
	nepali: "भाडामा बस्ने महिलाको  संख्या |",
};

export const migratedUserQn = {
	english: "",
	nepali: "बसाईसरी आउनुभएको हो ?",
};

export const migratedUser = [
	{
		nepali: "हो",
		english: "",
		value: "yes",
	},
	{
		nepali: "होइन",
		english: "",
		value: "no",
	},
];

export const livingManagementQn = {
	nepali: "कुन बसाई सम्बन्धी व्यवस्था हो ?",
	english: "",
};

export const livingManagement = [
	{
		nepali: "सुकुम्बासी",
		english: "",
		value: "1",
	},
	{
		nepali: "विस्थापित",
		english: "",
		value: "2",
	},
	{
		nepali: "आरक्ष - विस्थापित",
		english: "",
		value: "3",
	},
	{
		nepali: "कुनैपनि होईन",
		english: "",
		value: "4",
	},
];

export const migratedTotalPeopleQn = {
	nepali: "कति जना बसाई सरि आउनु  भएको थियो?",
	english: "",
};

export const migratedMaleQn = {
	nepali: "पुरुष",
	english: "",
};

export const migratedFemaleQn = {
	nepali: "महिला",
	english: "",
};

export const migrationDateQn = {
	english: "",
	nepali: "कहिले (मिति) बसाई सरि आउनु भएको थियो?",
};

export const migrationReasonQn = {
	english: "",
	nepali: "बसाई सरि आउनुको मुख्य कारण के हो ?",
};

export const migrationReason = [
	{
		english: "",
		nepali: "द्वन्द्व",
		value: "11",
	},
	{
		english: "",
		nepali: "आर्थिक उन्नती",
		value: "12",
	},
	{
		english: "",
		nepali: "सामाजिक",
		value: "13",
	},
	{
		english: "",
		nepali: "व्यपार",
		value: "14",
	},
	{
		english: "",
		nepali: "नोकरी",
		value: "15",
	},
	{
		english: "",
		nepali: "अध्ययन",
		value: "16",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "17",
	},
];

export const yearlyFoodExpenseQn = {
	english: "",
	nepali: "खाद्यान्न अपुग भएमा किनेर खानुपर्दा वार्षिक सरदर कति खर्च लाग्छ ?",
};

export const familyLoanYesNoQn = {
	english: "",
	nepali: "परिवारमा ऋण छ कि छैन ?",
};

export const familyLoan = [
	{
		english: "",
		nepali: "छ",
		value: "1",
	},
	{
		english: "",
		nepali: "छैन",
		value: "2",
	},
];

export const howMuchFamilyLoanQn = {
	english: "",
	nepali: "छ भने कति छ ?",
};

export const nameFamilyLoanQn = {
	english: "",
	nepali: "कस्को नाममा छ ?",
};

export const familyLoanSourceQn = {
	english: "",
	nepali: "ऋणको श्रोतके हो ?",
};

export const familyLoanSource = [
	{
		english: "",
		nepali: "बैंक वित्तीय संस्थाबाट",
		value: "bank",
	},
	{
		english: "",
		nepali: "सहकारी",
		value: "cooperative",
	},
	{
		english: "",
		nepali: "अन्य कोष",
		value: "other_funds",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "other",
	},
];

export const familyLoanReasonQn = {
	english: "",
	nepali: "ऋण लिनुको उद्देश्यः",
};

export const previousLocationQn = {
	english: "",
	nepali: "बसाई सरि आउनु भएको पूर्ण ठेगाना ?",
};

export const nameFatherQn = {
	english: "",
	nepali: "बुवाको नाम के हो?",
};

export const nameGrandFatherQn = {
	english: "",
	nepali: "बजेको नाम के हो ?",
};

export const landFamilyMemberQn = {
	english: "",
	nepali: "तपाइको परिवार सदस्यको नाममा नेपाल भर कहि कतै जग्गा छ?",
};

export const whereAnyLandQn = {
	english: "",
	nepali: "कहा छ (जिल्ला, गा पा, नपा) उल्लेख गर्ने |",
};

export const selectIkaiQn = {
	english: "",
	nepali: "इकाई छान्नुहोस |",
};

export const selectIkai = [
	{
		english: "",
		nepali: "विघा र कटठा र धुर",
		value: "1",
	},
	{
		english: "",
		nepali: "रोपनी र आना र पैसा",
		value: "2",
	},
];

export const totalOwnedLand = {
	english: "",
	nepali: "कति जग्गा छ उल्लेख गर्नुहोस |",
};

export const whenBhogChalanQn = {
	english: "",
	nepali: "कहिले देखि भोग चलनमा छ (वर्षमा उल्लेख गर्नुहोस)",
};

export const nowLivingAgriQn = {
	english: "",
	nepali: "हाल वसोवास गरेको स्थानमा घर खेतीको अवस्था |",
};

export const nowLivingAgri = [
	{
		english: "",
		nepali: "घर मात्र",
		value: "1",
	},
	{
		english: "",
		nepali: "खेति मात्र",
		value: "2",
	},
	{
		english: "",
		nepali: "घर खेत दुवै",
		value: "3",
	},
];

export const onWhoseNameQn = {
	english: "",
	nepali: "कसको नाममा छ?",
};

export const migratedTotalPeople = [
	{
		nepali: "१ जना",
		english: "",
		value: "one",
	},
	{
		nepali: "२ जना",
		english: "",
		value: "two",
	},
	{
		nepali: "३ जना",
		english: "",
		value: "three",
	},
	{
		nepali: "४ जना",
		english: "",
		value: "four",
	},
	{
		nepali: "५ जना",
		english: "",
		value: "five",
	},
	{
		nepali: "सम्पूर्ण परिवार",
		english: "",
		value: "all_family",
	},
];

export const isAgriLandQn = {
	english: "",
	nepali: "कृषियोग्य जमिन छ ?",
};

export const isAgriLand = [
	{
		english: "",
		nepali: "छ",
		value: "1",
	},
	{
		english: "",
		nepali: "छैन",
		value: "2",
	},
];

export const isAgriLandTypeQn = {
	english: "",
	nepali: "कृषियोग्य जमिनको प्रकार",
};

export const isAgriLandType = [
	{
		english: "",
		nepali: "खेत",
		value: "farming",
	},
	{
		english: "",
		nepali: "पाखोबारी",
		value: "pakhobari",
	},
	{
		english: "",
		nepali: "बन (निजि)",
		value: "forest",
	},
	{
		english: "",
		nepali: "ऐलानी",
		value: "yelani",
	},
];

export const agriLandOwnerQn = {
	english: "",
	nepali: "कृषियोग्य जमिन र स्वामित्वः",
};

export const agriLandOwner = [
	{
		english: "",
		nepali: "पुरुषको नाममा भएको",
		value: "1",
	},
	{
		english: "",
		nepali: "महिलाको नाममा भएको",
		value: "2",
	},
	{
		english: "",
		nepali: "पुरूष र महिलाको संयुक्त स्वामित्व भएको",
		value: "3",
	},
	{
		english: "",
		nepali: "आफै कमाएको",
		value: "4",
	},
	{
		english: "",
		nepali: "अरुलाई कमाउन दिएको",
		value: "5",
	},
	{
		english: "",
		nepali: "अरुको कमाएको",
		value: "6",
	},
	{
		english: "",
		nepali: "बाँझो राखेको",
		value: "7",
	},
];

export const jaggaMapIkaiQn = {
	english: "",
	nepali: "जग्गा मापनको इकाई कुन हो ?",
};

export const jaggaMapIkai = [
	{
		english: "",
		nepali: "रोपनी / आना",
		value: "ropani_aana",
	},
	{
		english: "",
		nepali: "कठ्ठा / बिगाहा",
		value: "katha_bigha",
	},
];

export const howMuchKhetQn = {
	english: "",
	nepali: "खेत कति (रोपनी / आना) वा (कठ्ठा / बिगाहा) छ ?",
};

export const howMuchBanQn = {
	english: "",
	nepali: "बन कति रोपनी / आना / (कठ्ठा / बिगाहा)  छ ?",
};

export const howMuchPokhariQn = {
	english: "",
	nepali: "पाखोबारी कति (रोपनी / आना )वा (कठ्ठा / बिगाहा)  छ ?",
};

export const howMuchAelaniQn = {
	english: "",
	nepali: "ऐलानी कति (रोपनी / आना) / (कठ्ठा / बिगाहा) छ ?",
};

export const hasSichaiSubhidhaQn = {
	english: "",
	nepali: "सिचाई सुविधाः",
};

export const hasSichaiSubhidha = [
	{
		english: "",
		nepali: "छ",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैन",
		value: "no",
	},
];

export const sichaiSubhidhaTypeQn = {
	english: "",
	nepali: "सिचाई सुविधाको प्रकार",
};

export const sichaiSubhidhaType = [
	{
		english: "",
		nepali: "वर्षातको  समयमा सिंचाई",
		value: "1",
	},
	{
		english: "",
		nepali: "बाह्रै  महिना सिंचाई",
		value: "2",
	},
];

export const sichaiKhetQn = {
	english: "",
	nepali: "खेत",
};

export const sichaiKhet = [
	{
		english: "",
		nepali: "वर्षातको  समयमा सिंचाई",
		value: "1",
	},
	{
		english: "",
		nepali: "बाह्रै  महिना सिंचाई",
		value: "2",
	},
];

export const sichaiPokhariQn = {
	english: "",
	nepali: "पाखोबारी",
};

export const sichaiPokhari = [
	{
		english: "",
		nepali: "वर्षातको  समयमा सिंचाई",
		value: "1",
	},
	{
		english: "",
		nepali: "बाह्रै  महिना सिंचाई",
		value: "2",
	},
];

export const previousCropTypeQn = {
	english: "",
	nepali: "गत वर्षको विभिन्न बाली उत्पादनको प्रकार",
};

export const previousCropType = [
	{
		english: "",
		nepali: "धान",
		value: "paddy",
	},
	{
		english: "",
		nepali: "मकै",
		value: "maize",
	},
	{
		english: "",
		nepali: "गहुँ",
		value: "gahu",
	},
	{
		english: "",
		nepali: "कोदो",
		value: "kodo",
	},
	{
		english: "",
		nepali: "आलु",
		value: "potato",
	},
	{
		english: "",
		nepali: "दलहन",
		value: "daal",
	},
	{
		english: "",
		nepali: "तेलहन",
		value: "oilseed",
	},
	{
		english: "",
		nepali: "तरकारी",
		value: "vegetable",
	},
	{
		english: "",
		nepali: "फलफुल",
		value: "fruits",
	},
	{
		english: "",
		nepali: "नगदे - बाली",
		value: "cash_crops",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "others",
	},
];

export const DhanQn = {
	english: "",
	nepali: "धान",
};

export const MakaiQn = {
	english: "",
	nepali: "मकै",
};

export const GahuQn = {
	english: "",
	nepali: "गहुँ",
};

export const KodoQn = {
	english: "",
	nepali: "कोदो",
};

export const AaluQn = {
	english: "",
	nepali: "आलु",
};

export const DhalhanQn = {
	english: "",
	nepali: "दलहन",
};

export const TelhanQn = {
	english: "",
	nepali: "तेलहन",
};

export const TarkariQn = {
	english: "",
	nepali: "तरकारी",
};

export const FalfulQn = {
	english: "",
	nepali: "फलफुल",
};

export const productionQntity = [
	{
		english: "",
		nepali: "१- ५० के जी",
		value: "1",
	},
	{
		english: "",
		nepali: "५१ -१०० के जी",
		value: "2",
	},
	{
		english: "",
		nepali: "१०१ - २०० के जी",
		value: "3",
	},
	{
		english: "",
		nepali: "२०१ - ५०० के जी",
		value: "4",
	},
	{
		english: "",
		nepali: "५०१ - १००0 के जी",
		value: "5",
	},
	{
		english: "",
		nepali: "१००० के जी भन्दा बढी",
		value: "5_1",
	},
];

export const UkhuQn = {
	english: "",
	nepali: "उखु",
};

export const ukhuQntity = [
	{
		english: "",
		nepali: "१- ५० के जी",
		value: "1",
	},
	{
		english: "",
		nepali: "५१ -१०० के जी",
		value: "2",
	},
	{
		english: "",
		nepali: "१०१ - १५० के जी",
		value: "3",
	},
	{
		english: "",
		nepali: "१५१ - २०० के जी",
		value: "4",
	},
	{
		english: "",
		nepali: "२०१ - ५०० के जी",
		value: "5",
	},
	{
		english: "",
		nepali: "५०१ - १००० के जी",
		value: "6",
	},
	{
		english: "",
		nepali: "१००० के जी भन्दा बढी",
		value: "7",
	},
];

export const SanpatQn = {
	english: "",
	nepali: "सनपाट",
};

export const sanpatQntity = [
	{
		english: "",
		nepali: "१- ५० के जी",
		value: "1",
	},
	{
		english: "",
		nepali: "५१ -१०० के जी",
		value: "2",
	},
	{
		english: "",
		nepali: "१०१ - १५० के जी",
		value: "3",
	},
	{
		english: "",
		nepali: "१५१ - २०० के जी",
		value: "4",
	},
	{
		english: "",
		nepali: "२०१ - ५०० के जी",
		value: "5",
	},
	{
		english: "",
		nepali: "५०१ - १००० के जी",
		value: "6",
	},
	{
		english: "",
		nepali: "१००० के जी भन्दा बढी",
		value: "7",
	},
];

export const alaichiQn = {
	english: "",
	nepali: "अलैची",
};

export const alaichQntity = [
	{
		english: "",
		nepali: "१- ५० के जी",
		value: "1",
	},
	{
		english: "",
		nepali: "५१ -१०० के जी",
		value: "2",
	},
	{
		english: "",
		nepali: "१०१ - १५० के जी",
		value: "3",
	},
	{
		english: "",
		nepali: "१५१ - २०० के जी",
		value: "4",
	},
	{
		english: "",
		nepali: "२०१ - ५०० के जी",
		value: "5",
	},
	{
		english: "",
		nepali: "५०१ - १००० के जी",
		value: "6",
	},
	{
		english: "",
		nepali: "१००० के जी भन्दा बढी",
		value: "7",
	},
];

export const otherQn = {
	english: "",
	nepali: "अन्य",
};

export const annaBaliBikriQn = {
	english: "",
	nepali: "अन्न -  बाली बिक्री गर्नुहुन्छ |",
};

export const annaBaliBikri = [
	{
		english: "",
		nepali: "गर्दछौ",
		value: "1",
	},
	{
		english: "",
		nepali: "गर्दैनै",
		value: "2",
	},
];

export const salesQntity = [
	{
		english: "",
		nepali: "०-५० के. जी",
		value: "1",
	},
	{
		english: "",
		nepali: "५१ - १०० के जी",
		value: "2",
	},
	{
		english: "",
		nepali: "१०१ - २०० के. जी",
		value: "3",
	},
	{
		english: "",
		nepali: "२०१ - ५०० के. जी",
		value: "4",
	},
	{
		english: "",
		nepali: "५०१ - १००० के. जी",
		value: "5",
	},
	{
		english: "",
		nepali: "१००० के जी भन्दा बढी",
		value: "5_1",
	},
];

export const khetMaleWorkerQn = {
	english: "",
	nepali: "खेती गर्न लाग्ने श्रम शंख्या (पुरुष)",
};

export const khetFemaleWorkerQn = {
	english: "",
	nepali: "खेती गर्न लाग्ने श्रम शंख्या (महिला )",
};

export const naturalDisasterPiditQn = {
	english: "",
	nepali: "गत ३ वर्षभित्र बाढी, पहिरो, असिन लगायत प्रकोपबाट पीडित हुनुहुन्छ ?",
};

export const naturalDisasterPidit = [
	{
		english: "",
		nepali: "छ",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैन",
		value: "no",
	},
];

export const whichDisasterPiditQn = {
	english: "",
	nepali: "यदि छ भने कुन प्रकोपबाट पीडित हुनुहुन्छ",
};

export const harmTypeQn = {
	english: "",
	nepali: "कस्तो प्रकारको  क्षति भएको थियो ?",
};

export const harmType = [
	{
		english: "",
		nepali: "मानबिय क्षती",
		value: "1",
	},
	{
		english: "",
		nepali: "धन - सम्पति  क्षेती",
		value: "2",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "3",
	},
];

export const howManyHumanAffectedQn = {
	english: "",
	nepali: "मानवीय क्षति भएको थियो भने कति जना प्रभवित  हुनु भएको थियो ?",
};

export const howMuchDhanAffectedQn = {
	english: "",
	nepali: "धन - सम्पति क्षति भएको थियो भने कति ( ने. रु  रकममा ) भएको थियो ?",
};

export const familyExpenseQn = {
	english: "",
	nepali: "पारिवारिक खर्च के - के मा हुन्छ |",
};

export const familyExpense = [
	{
		english: "",
		nepali: "खाद्यान्न खरिद",
		value: "1",
	},
	{
		english: "",
		nepali: "विद्यालय शिक्षा",
		value: "2",
	},
	{
		english: "",
		nepali: "स्वास्थ्य उपचार",
		value: "3",
	},
	{
		english: "",
		nepali: "लत्ताकपडा खरिद",
		value: "4",
	},
	{
		english: "",
		nepali: "सामाजिक चाडपर्व/धर्म संस्कार",
		value: "5",
	},
	{
		english: "",
		nepali: "कर, तिरो, सेवा शुल्क",
		value: "6",
	},

	{
		english: "",
		nepali: "पानी, विजुली, इन्धन",
		value: "7",
	},
	{
		english: "",
		nepali: "घडी रेडियो टेलिफोन आदि खरिद",
		value: "8",
	},
	{
		english: "",
		nepali: "सञ्चार खर्च",
		value: "9",
	},
	{
		english: "",
		nepali: "ऋण फिर्ता",
		value: "10",
	},
	{
		english: "",
		nepali: "ब्याज",
		value: "11",
	},
	{
		english: "",
		nepali: "लघु - उद्योग",
		value: "12",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "13",
	},
];

export const foodExpenseQn = {
	english: "",
	nepali: "खाद्यान्न खरिदमा हुने खर्च |",
};

export const educationExpenseQn = {
	english: "",
	nepali: "विद्यालय शिक्षामा हुने खर्च",
};

export const healthExpenseQn = {
	english: "",
	nepali: "स्वास्थ्य उपचारमा हुने खर्च",
};

export const clothingExpenseQn = {
	english: "",
	nepali: "लत्ताकपडा खरिदमा हुने खर्च",
};

export const festivalExpenseQn = {
	english: "",
	nepali: "सामाजिक चाडपर्व/धर्म संस्कारमा हुने खर्च",
};

export const taxExpenseQn = {
	english: "",
	nepali: "कर, तिरो, सेवा शुल्कमा हुने खर्च",
};

export const LaguExpenseQn = {
	english: "",
	nepali: "लघु उद्योगमा हुने खर्च |",
};

export const houseExpenseQn = {
	english: "",
	nepali: "पानी, विजुली, इन्धनमा हुने खर्च |",
};

export const entertainmentExpenseQn = {
	english: "",
	nepali: "घडी रेडियो टेलिफोन आदि खरिदमा हुने खर्च",
};

export const communicationExpenseQn = {
	english: "",
	nepali: "सञ्चारमा हुने खर्च |",
};

export const loanExpenseQn = {
	english: "",
	nepali: "ऋण फिर्तामा हुने खर्च |",
};

export const intrestExpenseQn = {
	english: "",
	nepali: "ब्याजमा हुने खर्च |",
};

export const isAnimalRearingQn = {
	english: "",
	nepali: "पशु - पालन गर्नु भएको छ ?",
};

export const isAnimalRearing = [
	{
		english: "",
		nepali: "छ",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैन",
		value: "no",
	},
];

export const cowQn = {
	english: "",
	nepali: "गाइगोरु / चौरी कति छन् ?",
};

export const buffaloQn = {
	english: "",
	nepali: "भैसी - रागा कति छन् ?",
};

export const horseQn = {
	english: "",
	nepali: "घोडा - खच्चर कति छन्?",
};

export const sheepQn = {
	english: "",
	nepali: "बाख्रा, भेडा, च्याङ्ग्रा कति छन् ?",
};

export const pigQn = {
	english: "",
	nepali: "सुँगुर/बंगुर कति छन् ?",
};

export const rabbitQn = {
	english: "",
	nepali: "खरायो कति छन् ?",
};

export const duckQn = {
	english: "",
	nepali: "हाँस  परेवा कति छन् ?",
};
export const chickenQn = {
	english: "",
	nepali: "कुखुरा कति छन् ?",
};
export const fishQn = {
	english: "",
	nepali: "माछा  (पोखरीमा उल्लेख गर्ने )",
};
export const beeQn = {
	english: "",
	nepali: "मौरी (घारमा उल्लेख गर्ने )",
};

export const producedAnimalProductQn = {
	english: "",
	nepali: "पशुधनबाट हुने उद्पादन",
};

export const producedAnimalProduct = [
	{
		english: "",
		nepali: "ऊन",
		value: "wool",
	},
	{
		english: "",
		nepali: "मासु",
		value: "meat",
	},
	{
		english: "",
		nepali: "दुध",
		value: "milk",
	},
	{
		english: "",
		nepali: "मह",
		value: "honey",
	},
	{
		english: "",
		nepali: "अण्डा",
		value: "egg",
	},
];

export const milkQn = {
	english: "",
	nepali: "दुध कति उद्पादन हुन्छ (लीटर)",
};

export const meatQn = {
	english: "",
	nepali: "मासू कति उत्पादन हुन्छ (के.जीमा )",
};

export const woolQn = {
	english: "",
	nepali: "ऊन  कति उत्पादन हुन्छ (के.जी मा )",
};

export const honeyQn = {
	english: "",
	nepali: "मह  कति उत्पादन हुन्छ (के.जी मा )",
};

export const eggQn = {
	english: "",
	nepali: "अन्डा कति उत्पादन हुन्छ (गोटा मा )",
};

export const whereGoingIfSickQn = {
	english: "",
	nepali:
		"परिवारमा कुनै सदस्य विरामी भएमा उपचारका लागि सर्वप्रथम कहाँ जानुहुन्छ ?",
};

export const whereGoingIfSick = [
	{
		english: "",
		nepali: "स्वास्थ्य चौकी",
		value: "1",
	},
	{
		english: "",
		nepali: "धामी/झाँक्री/लामा",
		value: "2",
	},
	{
		english: "",
		nepali: "अस्पताल",
		value: "3",
	},
];

export const whereGoingFamilyDeliveryQn = {
	english: "",
	nepali: "परिवारमा प्रसुतिका लागि सर्वप्रथम कहाँ जानुहुन्छ ?",
};

export const whereGoingFamilyDelivery = [
	{
		english: "",
		nepali: "नजिकको स्वास्थ्य संस्था",
		value: "nearBy_clinic",
	},
	{
		english: "",
		nepali: "अस्पताल/क्लिनिक",
		value: "hospital",
	},
	{
		english: "",
		nepali: "घरमै",
		value: "home",
	},
];

export const whichSaltUsingQn = {
	english: "",
	nepali: "घरमा कस्तो नुन प्रयोग गर्नुहुन्छ ?",
};

export const whichSaltUsing = [
	{
		english: "",
		nepali: "धुलो खुल्ला - नुन",
		value: "no",
	},
	{
		english: "",
		nepali: "आयोडिन भएको नुन  ( दुई बच्चाको छाप भएको )",
		value: "yes",
	},
];

export const childrenGoingSchoolQn = {
	english: "",
	nepali: "बालबालिका स्कुल जानछन् ?",
};

export const childrenGoingSchool = [
	{
		english: "",
		nepali: "जानछन्",
		value: "yes",
	},
	{
		english: "",
		nepali: "जादैनन्",
		value: "no",
	},
];

export const whereGoingSchoolQn = {
	english: "",
	nepali: "बालबालिका कहाँ अध्यनरत छन् ?",
};

export const whereGoingSchool = [
	{
		english: "",
		nepali: "नगरपालिका / गाउँपालिका अन्तर्गतका सरकारी विद्यालयमा",
		value: "1",
	},
	{
		english: "",
		nepali: "नगरपालिका / गाउँपालिका बाहिरका बोर्डिंग  विद्यालयमा",
		value: "2",
	},
	{
		english: "",
		nepali: "नगरपालिका / गाउँपालिका अन्तर्गतका बोर्डिंग  विद्यालयमा",
		value: "3",
	},
	{
		english: "",
		nepali: "नगरपालिका / गाउँपालिका बाहिरका सरकारी  विद्यालयमा",
		value: "4",
	},
];

export const isNotMentalChildMaleQn = {
	english: "",
	nepali: "अपाङ्गता नभएको",
};

export const isNotMentalChildMale = [
	{
		english: "",
		nepali: "३-५ वर्ष",
		value: "1",
	},
	{
		english: "",
		nepali: "५-१३ वर्ष",
		value: "२",
	},
	{
		english: "",
		nepali: "१४- १८ वर्ष",
		value: "३",
	},
];

export const isMentalChildMaleQn = {
	english: "",
	nepali: "अपाङ्गता भएको",
};

export const isMentalChildFemaleQn = {
	english: "",
	nepali: "अपाङ्गता भएको",
};

export const isNotMentalChildFemaleQn = {
	english: "",
	nepali: "अपाङ्गता नभएको",
};

export const mentalChild = [
	{
		english: "",
		nepali: "३-५ वर्ष",
		value: "3_to_5",
	},
	{
		english: "",
		nepali: "५-१३ वर्ष",
		value: "5_to_13",
	},
	{
		english: "",
		nepali: "१४- १८ वर्ष",
		value: "14_to_18",
	},
];

export const reasonNotGoingSchoolQn = {
	english: "",
	nepali: "विद्यालय नजनु / छोड्नुका कारण मुख्य कारण के हो ?",
};

export const playGroupQn = {
	english: "",
	nepali: "बाल विकास",
};

export const prePrimaryQn = {
	english: "",
	nepali: "आधारभूततह",
};

export const primaryQn = {
	english: "",
	nepali: "मा.वि. तह",
};

export const schoolReachingTime = [
	{
		english: "",
		nepali: "१५मिनेटभन्दा कम",
		value: "less_than_15min",
	},
	{
		english: "",
		nepali: "१५-३० मिनेट",
		value: "15_to_30min",
	},
	{
		english: "",
		nepali: "३० - ६० मिनेट",
		value: "30_to_60min",
	},
	{
		english: "",
		nepali: "१घण्टा भन्दा बढी",
		value: "more_than_1hour",
	},
];

export const wardQn = {
	english: "",
	nepali: "वडाकार्यालय",
};

export const serviceCenterQn = {
	english: "",
	nepali: "नजिककोसेवा केन्द्र",
};

export const palikaQn = {
	english: "",
	nepali: "गाउँ / नगरपालिकाको कार्यपालिकाको कार्यालय",
};

export const districtSadarmukanQn = {
	english: "",
	nepali: "जिल्लासदरमुकाम",
};

export const communityQn = {
	english: "",
	nepali: "सामुदायिकस्वास्थ्य इकाई केन्द्र",
};

export const healthQn = {
	english: "",
	nepali: "स्वास्थ्यचौकी पुग्‍न",
};

export const hospitalQn = {
	english: "",
	nepali: "अस्पतालरहेको स्थान पुग्‍न",
};

export const officeReachingTime = [
	{
		english: "",
		nepali: "३०मिनेटभन्दा कम",
		value: "less_than_30min",
	},
	{
		english: "",
		nepali: "३०-६० मिनेट",
		value: "30_to_60min",
	},
	{
		english: "",
		nepali: "१घण्टा देखि २ घण्टा",
		value: "1_to_2hour",
	},
	{
		english: "",
		nepali: "२घण्टादेखि ४ घण्टा सम्म",
		value: "2_to_4hour",
	},
	{
		english: "",
		nepali: "४घण्टा भन्दा बढी",
		value: "more_than_4hour",
	},
];

export const houseDecisionQn = {
	english: "",
	nepali: "घर  व्यवहार सम्बन्धी विषयको निर्णय",
};

export const houseWorkQn = {
	english: "",
	nepali: "घरायसी  काममा संलग्न",
};

export const bankRelatedQn = {
	english: "",
	nepali: "बैकमा  खाता सञ्चालन",
};

export const consumerQn = {
	english: "",
	nepali: "उपभोक्ता  समितिका सहभागिता",
};

export const businessRelatedQn = {
	english: "",
	nepali: "उद्योग  व्यापारमा सहभागिता",
};

export const schoolActivitiesQn = {
	english: "",
	nepali: "विद्यालय  व्यवस्थापनमा सहभागिता",
};

export const houseExpenseDecisionQn = {
	english: "",
	nepali: "घरायसी  खर्चको निर्णय",
};

export const maleFemaleOption = [
	{
		english: "",
		nepali: "पुरुष",
		value: "male",
	},
	{
		english: "",
		nepali: "महिला",
		value: "female",
	},
];
