import React from "react";
import { Card } from "react-bootstrap";
import {
  agriCropType,
  agriProductionType,
  foodForMonth,
  insecticideType,
} from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";
import {
  isAgriLandQn,
  isAgriLand,
  isAgriLandTypeQn,
  isAgriLandType,
  agriLandOwnerQn,
  agriLandOwner,
  jaggaMapIkaiQn,
  jaggaMapIkai,
  howMuchKhetQn,
  howMuchBanQn,
  howMuchPokhariQn,
  howMuchAelaniQn,
  hasSichaiSubhidhaQn,
  hasSichaiSubhidha,
  sichaiSubhidhaTypeQn,
  sichaiSubhidhaType,
  sichaiKhetQn,
  sichaiKhet,
  sichaiPokhariQn,
  sichaiPokhari,
  previousCropTypeQn,
  previousCropType,
  DhanQn,
  MakaiQn,
  GahuQn,
  KodoQn,
  AaluQn,
  DhalhanQn,
  TelhanQn,
  TarkariQn,
  FalfulQn,
  productionQntity,
  UkhuQn,
  ukhuQntity,
  SanpatQn,
  sanpatQntity,
  alaichiQn,
  alaichQntity,
  otherQn,
  annaBaliBikriQn,
  annaBaliBikri,
  salesQntity,
  khetMaleWorkerQn,
  khetFemaleWorkerQn,
  isAnimalRearingQn,
  isAnimalRearing,
  cowQn,
  buffaloQn,
  horseQn,
  sheepQn,
  pigQn,
  rabbitQn,
  duckQn,
  chickenQn,
  fishQn,
  beeQn,
  producedAnimalProductQn,
  producedAnimalProduct,
  milkQn,
  meatQn,
  woolQn,
  honeyQn,
  eggQn,
} from "../../data/newHouseSurvey";

const AgricultureDetail = ({ agriculture }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>कृषि उत्पादन</Card.Title>
        {console.log(agriculture)}
        <h6>
          {isAgriLandQn[selectedLanguage]}:{" "}
          {agriculture.hasAgricultureLand ? "छ" : "छैन"}
        </h6>

        {agriculture.hasAgricultureLand && (
          <>
            {agriculture.agricultureLandType?.map((land) => {
              return (
                <>
                  <h6>कृषियोग्य जमिनको प्रकार: {land.landType}</h6>
                  <h6>
                    कृषियोग्य जमिनको क्षेत्र: {land.area.ropani} /{" "}
                    {land.area.aana}
                  </h6>
                  <hr />
                </>
              );
            })}

            <h6>
              {agriLandOwnerQn[selectedLanguage]}:{" "}
              {agriculture.landOwnership?.map((owner) => {
                return (
                  <>
                    {agriLandOwner.find((i) => i.value === owner)
                      ? agriLandOwner.find((i) => i.value === owner)[
                          selectedLanguage
                        ]
                      : ""}
                  </>
                );
              })}
            </h6>

            <h6>जग्गा मापनको इकाई: {agriculture.landMeasurementType} </h6>

            <h6>
              {hasSichaiSubhidhaQn[selectedLanguage]}:{" "}
              {agriculture.irrigation.hasFacility ? "छ" : "छैन"}
            </h6>

            {agriculture.irrigation.hasFacility && (
              <>
                <h6>
                  सिचाई सुविधाको प्रकार:{" "}
                  {sichaiSubhidhaType.find(
                    (i) => i.value === agriculture.irrigation.facilityType
                  )
                    ? sichaiSubhidhaType.find(
                        (i) => i.value === agriculture.irrigation.facilityType
                      )[selectedLanguage]
                    : ""}
                </h6>
              </>
            )}

            <h6>
              खेत :{" "}
              {sichaiKhet.find((i) => i.value === agriculture.irrigation.farm)
                ? sichaiKhet.find(
                    (i) => i.value === agriculture.irrigation.farm
                  )[selectedLanguage]
                : ""}
            </h6>

            <h6>
              पाखोबारी :{" "}
              {sichaiPokhari.find(
                (i) => i.value === agriculture.irrigation.pakhobari
              )
                ? sichaiPokhari.find(
                    (i) => i.value === agriculture.irrigation.pakhobari
                  )[selectedLanguage]
                : ""}
            </h6>

            <h6>
              गत वर्षको विभिन्न बाली उत्पादनको प्रकार:{" "}
              {agriculture.cropsType?.map((crops) => {
                return (
                  <>
                    {previousCropType.find((i) => i.value === crops)
                      ? previousCropType.find((i) => i.value === crops)[
                          selectedLanguage
                        ]
                      : ""}
                  </>
                );
              })}
            </h6>

            <h5>
              गत वर्षको विभिन्न बाली उत्पादन (स्थानीय परिमाणलाई के.जी. मा
              रुपान्तर गरेर)
            </h5>

            {/* <h6>
              {MakaiQn[selectedLanguage]}:{" "}
              {productionQntity.find(
                (i) => i.value === agriculture.maize.production
              )
                ? productionQntity.find(
                    (i) => i.value === agriculture.maize.production
                  )[selectedLanguage]
                : ""}
            </h6> */}

            <h6>
              अन्न - बाली बिक्री: {agriculture.hasSales ? "गर्दछौ" : "गर्दैनै"}
            </h6>
            {agriculture.hasSales && (
              <>
                <h6>
                  {MakaiQn[selectedLanguage]}:{" "}
                  {salesQntity.find((i) => i.value === agriculture.maize.sales)
                    ? salesQntity.find(
                        (i) => i.value === agriculture.maize.sales
                      )[selectedLanguage]
                    : ""}
                </h6>

                {/* more to be added  */}
              </>
            )}

            <h6>
            {khetMaleWorkerQn[selectedLanguage]}: {agriculture.manpowerMale}
            </h6>

            <h6>
            {khetFemaleWorkerQn[selectedLanguage]}: {agriculture.manpowerFemale}

            </h6>

            {/* <h6>
              {agriProductionType.find(
                (i) => i.value === agriculture.agricultureProductSalesType
              )
                ? agriProductionType.find(
                    (i) => i.value === agriculture.agricultureProductSalesType
                  )[selectedLanguage]
                : ""}
            </h6> */}
            {/* <h6>
              कृषि बाली अनुसार किट नाशक औषधि/मल को :
              {agriculture.insecticides ? "छ" : "छैन"}
            </h6> */}
            <h6>
              {foodForMonth.find((i) => i.value === agriculture.foodForMonth)
                ? foodForMonth.find(
                    (i) => i.value === agriculture.foodForMonth
                  )[selectedLanguage]
                : ""}
            </h6>

            {/* <h6>{agriculture.toolUsed}</h6>
            <h6>
              बिषादीको प्रकार :
              {insecticideType.find(
                (i) => i.value === agriculture.chemicalUsed
              ) !== undefined
                ? insecticideType.find(
                    (i) => i.value === agriculture.chemicalUsed
                  )[selectedLanguage]
                : ""}
            </h6> */}

            {/* <h6>मलको प्रयोग :{agriculture.fertilizerUse ? "छ" : "छैन"}</h6>
            <h6>मलको प्रकार :{agriculture.fertilizerType}</h6> */}
            {/* <h6>
              {" "}
              छ भने कस्तो किसिमको मल प्रयोग गर्नु हुन्छ?:
              {agriculture.seedNeed ? "छ" : "छैन"}
            </h6> */}
          </>
        )}

        {/* {agriculture.agricultureDetail?.map((agro) => {
          console.log("tuitle".agriculture.title);
          return (
            <>
              <h6>
                अन्नवालिको नाम :
                {agriCropType.find((i) => i.value === agro.title) !== undefined
                  ? agriCropType.find((i) => i.value === agro.title)[
                      selectedLanguage
                    ]
                  : ""}
              </h6>
              <h6>अन्नवालिको उत्पादन परिमाण :{agro?.production?.plantCount}</h6>
              <h6>
                अन्नवालिको उत्पादन परिमाण :
                {agro?.production?.quintal !== 0 &&
                  `${agro?.production?.quintal} quintal,`}
                {agro?.production?.kg !== 0 && `${agro?.production?.kg} kg`}
              </h6>
              <h6>अन्नवालिको विक्री परिमाण :{agro?.sale?.plantCount}</h6>
              <h6>
                अन्नवालिको विक्री परिमाण :
                {agro?.sale?.quintal !== 0 && `${agro?.sale?.quintal} quintal,`}
                {agro?.sale?.kg !== 0 && `${agro?.sale?.kg} kg`}
              </h6>
              <hr />
            </>
          );
        })}

         */}

      </Card.Body>
    </Card>
  );
};

export default AgricultureDetail;
