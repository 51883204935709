import React, { useState } from "react";
import { useEffect } from "react";
import ImageUploader from "../components/ImageUploader";
import LeafletMap from "../components/LeafletMap";
import SingleSelectInput from "../components/SingleSelectInput";
import { answererRelation } from "../data/advanceSearch";
import {
	answererName,
	answererRelationQuestion,
	gharNumber,
	isHouseOwnerQn,
	isHouseOwner,
	houseOwnerAgeQn,
	houseOwnerAge,
	houseOwnerContactQn,
	houseOwnerEmailQn,
	houseOwnerNameQn,
	ifOtherValueQuestion,
	latitude,
	livingType,
	livingtypeQuestion,
	longitude,
	toleFiveQn,
	toleFourQn,
	toleOneQn,
	toleSevenQn,
	toleSixQn,
	toleThreeQn,
	toleTwoQn,
	toleEightQn,
	toleNineQn,
	toleTenQn,
	toleElevenQn,
	toleTwelveQn,
	toleThirteenQn,
	toleFourteenQn,
	toleFifteenQn,
	toleSixteenQn,
	toleSeventeenQn,
	toleEighteenQn,
	toleNineteenQn,
	toleWardFive,
	toleWardFour,
	toleWardOne,
	toleWardSeven,
	toleWardSix,
	toleWardThree,
	toleWardTwo,
	toleWardEight,
	toleWardNine,
	toleWardTen,
	toleWardEleven,
	toleWardTwelve,
	toleWardThirteen,
	toleWardFourteen,
	toleWardFifteen,
	toleWardSixteen,
	toleWardSeventeen,
	toleWardEighteen,
	toleWardNineteen,
	genderQuestion,
	gender,
} from "../data/houseSurvey";
import {
	selectedLanguage,
	interviewerNameQuestion,
	interviewerName,
	ward,
	wardQuestion,
} from "../data/institute";
import { checkIfHouseExists } from "../modules/HomeSurvey/homeSurveyCrud";

const GhardhuriForm = (props) => {
	const [gharNumberExists, setGharNumberExists] = useState(true);
	const [currentWardNumber, setCurrentWardNumber] = useState(0);
	useEffect(() => {
		const ward = parseInt(sessionStorage.getItem("ward"));
		setCurrentWardNumber(ward);
	}, []);

	const wardData = [
		{
			question: toleOneQn,
			toleList: toleWardOne,
		},
		{
			question: toleTwoQn,
			toleList: toleWardTwo,
		},
		{
			question: toleThreeQn,
			toleList: toleWardThree,
		},
		{
			question: toleFourQn,
			toleList: toleWardFour,
		},
		{
			question: toleFiveQn,
			toleList: toleWardFive,
		},
		{
			question: toleSixQn,
			toleList: toleWardSix,
		},
		{
			question: toleSevenQn,
			toleList: toleWardSeven,
		},
		{
			question: toleEightQn,
			toleList: toleWardEight,
		},
		{
			question: toleNineQn,
			toleList: toleWardNine,
		},
		{
			question: toleTenQn,
			toleList: toleWardTen,
		},
		{
			question: toleElevenQn,
			toleList: toleWardEleven,
		},
	];
	const [selectedWard, setSelectedWard] = useState(0);
	console.log("form props", props);
	const { formik } = props;
	const { primaryHouse } = formik.values;
	const { zoom, lat, lng } = formik.values;
	const handleWardChange = (e) => {
		console.log(e.target.value);
		setSelectedWard(e.target.value - 1);
		formik.handleChange(e);
	};
	const addMarker = (e) => {
		console.log(e);
	};
	const handleHouseNumberChange = (e) => {
		formik.handleChange(e);
		checkIfHouseExists(e.target.value).then((res) => {
			console.log(res);
			if (!res.data.success) {
				setGharNumberExists(false);
			} else {
				setGharNumberExists(true);
			}
		});
	};
	return (
		<>
			<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<div class="col-xl-12 col-xxl-8">
					<div
						class="pb-5"
						data-wizard-type="step-content"
						data-wizard-state="current"
					>
						<h3 class="mb-10 font-weight-bold text-dark">घरधुरी विवरण</h3>
						<div className="row">
							<div className="col-lg-6">
								<div class="form-group">
									<label>{wardQuestion[selectedLanguage]}</label>
									{currentWardNumber === 0 ? (
										<SingleSelectInput
											id="ward"
											name="primaryHouse.wardNumber"
											value={primaryHouse.wardNumber}
											formik={formik}
											selectList={ward}
											handleWardChange={handleWardChange}
										/>
									) : (
										<input
											readOnly
											type="text"
											class="form-control form-control-solid form-control-lg"
											onChange={formik.handleChange}
											name="primaryHouse.wardNumber"
											value={
												primaryHouse.wardNumber
													? primaryHouse.wardNumber
													: currentWardNumber
											}
										/>
									)}
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group">
									<label>
										{currentWardNumber
											? wardData[
													currentWardNumber == 0
														? selectedWard
														: currentWardNumber - 1
											  ].question[selectedLanguage]
											: wardData[selectedWard].question[selectedLanguage]}
									</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={formik.handleChange}
										name="primaryHouse.toleName"
										value={primaryHouse.toleName}
									/>
								</div>
							</div>
							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{interviewerNameQuestion[selectedLanguage]}</label>
                  <SingleSelectInput
                    id="collectorName"
                    name="primaryHouse.collectorName"
                    value={primaryHouse.collectorName}
                    formik={formik}
                    selectList={interviewerName}
                    handleWardChange={handleWardChange}
                  />
                </div>
              </div> */}
							<div className="col-lg-6">
								<div class="form-group">
									<label>{gharNumber[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={handleHouseNumberChange}
										name="primaryHouse.houseNumber"
										value={primaryHouse.houseNumber}
										style={{ fontFamily: "Arial" }}
									/>
									{!primaryHouse.houseNumber && (
										<p className="invalid-feedback">
											कृपया अंग्रेजीमा अद्वितीय घर नम्बर चयन गर्नुहोस्
										</p>
									)}
									{primaryHouse.houseNumber && gharNumberExists && (
										<p className="invalid-feedback">
											घर नम्बर पहिले नै लिइएको छ।
										</p>
									)}
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseOwnerContactQn[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={formik.handleChange}
										name="primaryHouse.houseOwnerMobile"
										value={primaryHouse.houseOwnerMobile}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseOwnerNameQn[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={formik.handleChange}
										name="primaryHouse.houseOwner"
										value={primaryHouse.houseOwner}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{isHouseOwnerQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{isHouseOwner.map((value, i) => (
											<label class="radio radio-rounded">
												<input
													type="radio"
													name={`primaryHouse.isAnswererSelfHouseOwn`}
													defaultChecked={
														primaryHouse.isAnswererSelfHouseOwn == value.value
													}
													value={value.value}
													onChange={formik.handleChange}
													defaultChecked={
														primaryHouse.isAnswererSelfHouseOwn == value.value
													}
												/>
												<span></span>
												{value[selectedLanguage]}
											</label>
										))}
									</div>
								</div>
							</div>
							{primaryHouse.isAnswererSelfHouseOwn == "no" && (
								<div className="col-lg-6">
									<div class="form-group">
										<label>{answererRelationQuestion[selectedLanguage]}</label>
										<SingleSelectInput
											id="answererRelation"
											name="primaryHouse.answererRelation"
											value={primaryHouse.answererRelation}
											formik={formik}
											selectList={answererRelation}
										/>
									</div>
								</div>
							)}
							{primaryHouse.answererRelation == "other" && (
								<div className="col-lg-6">
									<div class="form-group">
										<label>{ifOtherValueQuestion[selectedLanguage]}</label>
										<input
											type="text"
											class="form-control"
											name="primaryHouse.answererRelationOther"
											onChange={formik.handleChange}
											value={primaryHouse.answererRelationOther}
										/>
									</div>
								</div>
							)}
							<div className="col-lg-6">
								<div class="form-group">
									<label>{genderQuestion[selectedLanguage]}</label>
									<SingleSelectInput
										id="selfHouseOwnGender"
										name="primaryHouse.selfHouseOwnGender"
										value={primaryHouse.selfHouseOwnGender}
										formik={formik}
										selectList={gender}
										handleWardChange={handleWardChange}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseOwnerAgeQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="selfHouseOwnAge"
										name="primaryHouse.selfHouseOwnAge"
										value={primaryHouse.selfHouseOwnAge}
										formik={formik}
										selectList={houseOwnerAge}
										handleWardChange={handleWardChange}
									/>
								</div>
							</div>
							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{houseOwnerEmailQn[selectedLanguage]}</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    onChange={formik.handleChange}
                    name="primaryHouse.houseOwnerEmail"
                    value={primaryHouse.houseOwnerEmail}
                  />
                </div>
              </div> */}

							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{answererName[selectedLanguage]}</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    onChange={formik.handleChange}
                    name="primaryHouse.answererName"
                    value={primaryHouse.answererName}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div class="form-group">
                  <label>{livingtypeQuestion[selectedLanguage]}</label>
                  <SingleSelectInput
                    id="livingType"
                    name="primaryHouse.livingType"
                    value={primaryHouse.livingType}
                    formik={formik}
                    selectList={livingType}
                  />
                </div>
              </div>
              {primaryHouse.livingType == "other" && (
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                    <input
                      type="text"
                      class="form-control"
                      name="primaryHouse.livingTypeOther"
                      onChange={formik.handleChange}
                      value={primaryHouse.livingTypeOther}
                    />
                  </div>
                </div>
              )} */}

							{primaryHouse.gharNumber && !gharNumberExists && (
								<div className="col-lg-6">
									<div class="form-group">
										<label>घरको फोटो</label>
										<ImageUploader
											fileListContent={props.fileListContent}
											uniqueIdentifier={primaryHouse.gharNumber}
											imageName="house"
										/>
									</div>
								</div>
							)}
						</div>
						<div className="row">
							<div className="col-lg-6">
								<div class="form-group">
									<label>{latitude[selectedLanguage]}</label>
									<input
										type="number"
										class="form-control form-control-solid form-control-lg"
										name="lat"
										placeholder={longitude[selectedLanguage]}
										value={formik.values.lat}
										onChange={formik.handleChange}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{longitude[selectedLanguage]}</label>
									<input
										type="number"
										class="form-control form-control-solid form-control-lg"
										name="lng"
										placeholder={longitude[selectedLanguage]}
										value={formik.values.lng}
										onChange={formik.handleChange}
									/>
								</div>
							</div>
							<div className="col-12">
								<div style={{ height: "50vh" }}>
									<LeafletMap
										zoom={zoom || 15}
										// position={position}
										lat={lat}
										lng={lng}
										addMarker={addMarker}
									>
										<p>
											स्थान प्राप्त गर्न<em>मार्कर</em> पिन गर्नुहोस
										</p>
									</LeafletMap>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GhardhuriForm;
