import React, { useEffect, useState } from "react";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";

import {
	hasChildWorkerQn,
	hasChildWorker,
	maleChildWorkerQn,
	maleChildDomesticWorkerQn,
	maleChildHotelWorkerQn,
	maleChildIndustryWorkerQn,
	maleChildOtherWorkerQn,
	maleChildWorker,
	femaleChildWorkerQn,
	femaleChildDomesticWorkerQn,
	femaleChildHotelWorkerQn,
	femaleChildIndustryWorkerQn,
	femaleChildOtherWorkerQn,
	femaleChildWorker,
} from "../data/newHouseSurvey";

const WomenChild = (props) => {
	useEffect(() => {}, []);
	console.log("form props", props);
	const { formik } = props;
	const { womenChild } = formik.values;

	return (
		<>
			<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<div class="col-xl-12 col-xxl-8">
					<div
						class="pb-5"
						data-wizard-type="step-content"
						data-wizard-state="current"
					>
						<h3 class="mb-10 font-weight-bold text-dark">महिला बच्चा</h3>
						<div className="row">
							<div className="col-lg-12">
								<div class="form-group">
									<label>{hasChildWorkerQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{hasChildWorker.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`womenChild.childLabour`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === womenChild.childLabour
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>
							{womenChild[`childLabour`] === "yes" && (
								<>
									<h3 class="mb-10 font-weight-bold text-dark">
										{maleChildWorkerQn[selectedLanguage]}
									</h3>

									<div className="col-lg-6">
										<div class="form-group">
											<label>
												{maleChildDomesticWorkerQn[selectedLanguage]}
											</label>
											<SingleSelectInput
												id="boyJobHousehold"
												name="womenChild.boyJobHousehold"
												value={womenChild.boyJobHousehold}
												formik={formik}
												selectList={maleChildWorker}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{maleChildHotelWorkerQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="boyJobHotel"
												name="womenChild.boyJobHotel"
												value={womenChild.boyJobHotel}
												formik={formik}
												selectList={maleChildWorker}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>
												{maleChildIndustryWorkerQn[selectedLanguage]}
											</label>
											<SingleSelectInput
												id="boyJobIndustry"
												name="womenChild.boyJobIndustry"
												value={womenChild.boyJobIndustry}
												formik={formik}
												selectList={maleChildWorker}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{maleChildOtherWorkerQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="boyJobOthers"
												name="womenChild.boyJobOthers"
												value={womenChild.boyJobOthers}
												formik={formik}
												selectList={maleChildWorker}
											/>
										</div>
									</div>
								</>
							)}

							{/*  */}

							{womenChild[`childLabour`] === "yes" && (
								<>
									<h3 class="mb-10 font-weight-bold text-dark">
										{femaleChildWorkerQn[selectedLanguage]}
									</h3>

									<div className="col-lg-6">
										<div class="form-group">
											<label>
												{femaleChildDomesticWorkerQn[selectedLanguage]}
											</label>
											<SingleSelectInput
												id="girlJobHousehold"
												name="womenChild.girlJobHousehold"
												value={womenChild.girlJobHousehold}
												formik={formik}
												selectList={femaleChildWorker}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>
												{femaleChildHotelWorkerQn[selectedLanguage]}
											</label>
											<SingleSelectInput
												id="girlJobHotel"
												name="womenChild.girlJobHotel"
												value={womenChild.girlJobHotel}
												formik={formik}
												selectList={femaleChildWorker}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>
												{femaleChildIndustryWorkerQn[selectedLanguage]}
											</label>
											<SingleSelectInput
												id="girlJobIndustry"
												name="womenChild.girlJobIndustry"
												value={womenChild.girlJobIndustry}
												formik={formik}
												selectList={femaleChildWorker}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>
												{femaleChildOtherWorkerQn[selectedLanguage]}
											</label>
											<SingleSelectInput
												id="girlJobOthers"
												name="womenChild.girlJobOthers"
												value={womenChild.girlJobOthers}
												formik={formik}
												selectList={femaleChildWorker}
											/>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default WomenChild;
