import React, { useMemo, useEffect, Component, useState } from "react";
import { Button, Card } from "react-bootstrap";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
// import {  } from "../../_metronic/_helpers/NumberFormatterHelper"
import { selectedLanguage } from "../../data/institute";

const PopulationSection = (props) => {
	const uiService = useHtmlClassService();
	const [labels, setLabels] = useState([]);
	const [labelsDesc, setLabelsDesc] = useState([]);
	const layoutProps = useMemo(() => {
		return {
			colorsGrayGray100: objectPath.get(
				uiService.config,
				"js.colors.gray.gray100"
			),
			colorsGrayGray700: objectPath.get(
				uiService.config,
				"js.colors.gray.gray700"
			),
			colorsThemeLightPrimary: objectPath.get(
				uiService.config,
				"js.colors.theme.light.primary"
			),
			colorsThemeBasePrimary: objectPath.get(
				uiService.config,
				"js.colors.theme.base.primary"
			),
			colorsThemeBaseSuccess: objectPath.get(
				uiService.config,
				"js.colors.theme.base.success"
			),
			colorsThemeBaseWarning: objectPath.get(
				uiService.config,
				"js.colors.theme.base.warning"
			),
			colorsThemeBaseDanger: objectPath.get(
				uiService.config,
				"js.colors.theme.base.danger"
			),
			colorsThemeLightSuccess: objectPath.get(
				uiService.config,
				"js.colors.theme.light.success"
			),
			colorsThemeLightWarning: objectPath.get(
				uiService.config,
				"js.colors.theme.light.warning"
			),
			colorsThemeLightDanger: objectPath.get(
				uiService.config,
				"js.colors.theme.light.danger"
			),
			fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
		};
	}, [uiService]);
	useEffect(() => {
		console.log(props.population);
		if (props.population.length) {
			const newData = props.population.map((val, index) => {
				if (["Male", "Female", "Third Gender"].includes(val.title.english)) {
					return { val: val.title[selectedLanguage], index, count: val.count };
				}
			});
			const newLabelDesc = newData.map((datum) => {
				if (datum) {
					return datum.count;
				}
			});
			const newLabels = newData.map((datum) => {
				if (datum) {
					return datum.val;
				}
			});
			const genderLabels = newLabels.filter((i) => i !== undefined);
			const genderValues = newLabelDesc.filter((i) => i !== undefined);
			setLabels(genderValues);
			setLabelsDesc(genderLabels);
		}
	}, [props.population]);
	useEffect(() => {
		if (props.population.length) {
			const element = document.getElementById("piechart-population");
			if (!element) {
				return;
			}
			let heightVal = "300px";
			// if (props.data?.data.length > 5 && !props.data?.containerClass.includes('12')) {
			//     heightVal = '620px'
			// }
			const height = heightVal;
			const options = getChartOptions(layoutProps, height);

			const chart = new ApexCharts(element, options);
			chart.render();
			return function cleanUp() {
				chart.destroy();
			};
		}
	}, [labels, labelsDesc]);

	function getChartOptions(layoutProps, height) {
		console.log(labels);
		const horizontalMr = 10;
		const horizontalMrSmall = 10;
		const options = {
			series: labels,
			chart: {
				height: height,
				type: "pie",
			},
			legend: {
				position: "bottom",
				fontSize: "17px",
				fontWeight: 500,
				markers: {
					width: 20,
					height: 20,
					radius: 5,
				},
				itemMargin: {
					horizontal: horizontalMr,
					vertical: 5,
				},
				formatter: function(seriesName, opts) {
					return [
						`<div class="d-flex flex-column"><div class="font-weight-bolder">${seriesName}</div></div>`,
					];
				},
			},
			dataLabels: {
				enabled: false,
			},
			plotOptions: {
				pie: {
					donut: {
						size: "90%",
						margin: 0,
						labels: {
							show: false,
							total: {
								show: true,
								showAlways: false,
								label: selectedLanguage == "nepali" ? "जम्मा: " : "Total: ",
								fontSize: "18px",
								fontFamily: "Helvetica, Arial, sans-serif",
								fontWeight: 600,
								color: "#373d3f",
								formatter: function(w) {
									return w.globals.seriesTotals.reduce((a, b) => {
										// if(a.includes)
										if (typeof a == "string" && typeof b == "string") {
											const c = a.replace(/,/g, "");
											const d = b.replace(/,/g, "");
											return parseInt(c) + parseInt(d);
										} else if (typeof a == "string" && typeof b !== "string") {
											const c = a.replace(/,/g, "");
											return parseInt(c) + b;
											// const d = b.replace(/,/g, '')
										} else if (typeof a !== "string" && typeof b == "string") {
											// const c = a.replace(/,/g, '')
											const d = b.replace(/,/g, "");
											return a + parseInt(d);
										}
										return a + b;
									}, 0);
								},
							},
							value: {
								formatter: function(val) {
									return val;
								},
							},
						},
					},
				},
			},
			responsive: [
				{
					breakpoint: 991,
					options: {
						legend: {
							position: "bottom",
							fontSize: "14px",
							fontWeight: 500,
							markers: {
								width: 15,
								height: 15,
								radius: 3,
							},
							itemMargin: {
								horizontal: horizontalMrSmall,
								vertical: 5,
							},
							formatter: function(seriesName, opts) {
								return [
									`<div class="d-flex flex-column"><div class="font-weight-bolder">${seriesName}</div></div>`,
								];
							},
						},
					},
				},
			],
			// plotOptions: {
			//     donut: {
			//         hollow: {
			//             margin: 0,
			//             size: "65%"
			//         },
			//         dataLabels: {
			//             showOn: "always",
			//             name: {
			//                 show: false,
			//                 fontWeight: "700",
			//             },
			//             value: {
			//                 color: layoutProps.colorsGrayGray700,
			//                 fontSize: "30px",
			//                 fontWeight: "700",
			//                 offsetY: 12,
			//                 show: true
			//             },
			//         },
			//         track: {
			//             background: layoutProps.colorsThemeLightSuccess,
			//             strokeWidth: '100%'
			//         }
			//     }
			// },
			colors: [
				layoutProps.colorsThemeBasePrimary,
				layoutProps.colorsThemeBaseSuccess,
				layoutProps.colorsThemeBaseWarning,
				layoutProps.colorsThemeBaseDanger,
				"#ffbd9b",
				"#0a1d37",
				"#346751",
				"#5e454b",
				"#47597e",
				"#4a1c40",
				"#4ca1a3",
				"#34656d",
			],
			stroke: {
				show: true,
				curve: "smooth",
				lineCap: "butt",
				colors: undefined,
				width: 4,
				dashArray: 0,
			},
			labels: labelsDesc,
			tooltip: {
				enabled: true,
				style: {
					fontSize: "12px",
					fontFamily: "Kalimati-Regular",
				},
			},
		};
		return options;
	}
	return (
		<Card>
			<Card.Body>
				<div className="d-flex">
					{/* <SVG height="40" width="40" className="primary-icon" src={toAbsoluteUrl(props.data?.icon)} alt="" /> */}
					<h4 className="pl-3 pt-3 font-weight-bolder">जनसंख्या विवरण</h4>
				</div>
				<div
					className="pie-chart-container"
					id="piechart-population"
					style={{ height: "300px" }}
				></div>
			</Card.Body>
		</Card>
	);
};
export default PopulationSection;
