import React from "react";
import { Card } from "react-bootstrap";

const AnimalDetail = ({ livestock }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    पशु पन्छी जानकारी
                </Card.Title>
                <h6>पशु पन्छी पाल्नु भएको :{livestock.hasAnimal ? "छ" : "छैन"}</h6>
                {livestock.hasAnimal && (
                    <>
                    <h6>गाइगोरु / चौरी कति छन् ? {livestock.cowCount}</h6>
                    <h6>भैसी - रागा कति छन् ? {livestock.buffaloCount}</h6>
                    <h6>घोडा - खच्चर कति छन्? {livestock.horseCount}</h6>
                    <h6>बाख्रा, भेडा, च्याङ्ग्रा कति छन् ? {livestock.goatCount}</h6>
                    <h6>सुँगुर/बंगुर कति छन् ? {livestock.pigCount}</h6>
                    <h6>खरायो कति छन् ? {livestock.rabbitCount}</h6>
                    <h6>हाँस परेवा कति छन् ? {livestock.duckCount}</h6>
                    <h6>कुखुरा कति छन् ? {livestock.chickenCount}</h6>
                    <h6>माछा (पोखरीमा उल्लेख गर्ने ) {livestock.fishPondCount}</h6>
                    <h6>मौरी (घारमा उल्लेख गर्ने ) {livestock.beeHiveCount}</h6>
                    <h6>पशुधनबाट हुने उद्पादन {
                        livestock.productionType?.map((productionType)=>{
                            return(
                                {productionType}
                            );
                        })}</h6>
                    </>
                )}
                    

        {/* <h6>पाल्नु भएको प्रायोजन :{livestock.animalHousePurpose}</h6>
                {
                    livestock.animalDetail?.map((animal) => (
                        <>
                            <h6>पशु पन्छी: {animal.name}</h6>
                            <h6>वार्षिक आम्दानी: {animal.annualIncome}</h6>
                            <h6>वार्षिक खर्च: {animal.sale}</h6>
                            <h6>पशु पन्छी : {animal.sale}</h6>
                            {
                                animal.specification.map((spec) => (
                                    <>
                                        <h6>पशु पन्छी प्रकार: {spec.title}</h6>
                                        <h6>पशु पन्छी मात्रा: {spec.quantity}</h6>
                                        <h6>पशु पन्छी एकाई: {spec.unit}</h6>
                                    </>
                                ))
                            }
                        </>
                    ))
                } */}
                
      </Card.Body>
    </Card>
  );
};

export default AnimalDetail;
