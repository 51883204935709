import { totalWard } from "./config.js";
// import getNepaliNumber from "../utils/getNepaliNumber.js";
export const placeholderName = {
	nepali: "को विस्तृत  जानकारी खोज्नुहोस् जस्तै: लिङ्ग =पुरुष ",
	english: "'s advanced search, gender: male",
};
// export const languages = ["नेपाली", "English"];
// export const selectedLanguage = "nepali";
export const houseNeutralSearch = ["wardNumber"];
export const HouseSearchIndex = [
	"birthCertificate",
	"livingType",
	"childVaccine",
	"permanentLivingAddress",
	"waterSource",
	"cookingSource",
	"dirtyWaterManagement",
	"waterPurification",
	"electricSource",
	"toilet_isToilet",
	"toilet_toiletNo",
	"toilet_toiletYes",
	"wasteDisposal",
	"road",
	"bankAccount",
	"saltUsed",
	"yearSettled",
	"houseCount",
	"houseAge",
	"houseType",
	"roofType",
	"hasLandDocument",
	"dhukutiKarobar",
	"doHouseCriteriaFullfill",
	"isHouseEarthquakeResisitance",
	"hasHouseMapPass",
	"nonAgriIncome",
	"nonAgriExpense",
	"agriIncome",
	"agriExpense",
	"home_homeBusinessName",
	"item_title",
	"agricultureProductSalesType",
	"agricultureProductionSalesType_crops",
	"agricultureProductionSalesType_daal",
	"agricultureProductionSalesType_oilseed",
	"agricultureProductionSalesType_vegetable",
	"agricultureProductionSalesType_fruit",
	"agricultureProductionSalesType_cash_crops",
	"detail_name",
	"illTreament",
	// "foreignCountry",
	// "abroadReason"
];

export const mapData = {
	house: [
		"wardNumber",
		"houseNumber",
		"oldVDC",
		"oldWardNumber",
		"maleNumber",
		"femaleNumber",
		"totalFamilyNumber",
		"livingType",
		"waterSource",
		"distanceToWaterSource",
		"cookingSource",
		"dirtyWaterManagement",
		"waterPurification",
		"electricSource",
		"toilet_isToilet",
		"toilet_toiletNo",
		"toilet_toiletYes",
		"wasteDisposal",
		"road",
		"bankAccount",
		"saltUsed",
		"yearSettled",
		"houseAge",
		"landUsedLiving",
		"houseType",
		"roofType",
		"hasLandDocument",
		"doHouseCriteriaFullfill",
		"isHouseEarthquakeResisitance",
		"hasHouseMapPass",
		"illTreament",
	],
	family: [
		// "house",
		// "fullName",
		"gender",
		// "dateOfBirth",
		"age",
		// "answererRelation",
		// "citizenshipNumber",
		// "citizenshipfront",
		// "citizenshipBack",
		// "contactNumber",
		// "livingStatus",
		"caste",
		// "caste",
		"religion",
		"motherTongue",
		"martialStatus",
		"healthCondition",
		"unhealthyDisease",
	],
	education: [
		"educationLevel",
		// "prePrimaryDetails",
		"primaryDetails",
		"secondaryDetails",
	],
	occupation: ["levelOneJob", "levelTwoJob", "levelThreeJob"],
	disable: ["isDisable", "disableType", "condition", "hasCard", "cardType"],
	abroad: ["abroadReason", "bideshiyekoYear", "foreignCountry"],
	death: [],
	houseFacility: ["item_title"],
	rent: [],
	land: [],
	agriculture: [
		"agricultureProductSalesType",
		"agricultureProductionSalesType_crops",
		"agricultureProductionSalesType_daal",
		"agricultureProductionSalesType_oilseed",
		"agragricultureProductionSalesType_vegetable",
		"agricultureProductionSalesType_fruit",
		"agricultureProductionSalesType_cash_crops",
	],
	agricultureDetail: [],
	liveStock: ["detail_name"],
	beeFishSilk: [], //contains nested fields to be searched
	economics: [
		"nonAgriIncome",
		"nonAgriExpense",
		"agriIncome",
		"agriExpense",
		"dhukutiKarobar",
	],
	skill: ["title"],
	business: ["home_homeBusinessName"],

	womenChild: [
		"birthCertificate",
		"contraceptiveUse",
		"deliveryFromDoctor",
		"childDeath",
		"pregnanctCheck",
		// "childNutrition",
		"childLabour",
		"childLabor",
		"earlyMarriage",
		"pregnantTetanus",
	],
	welfare: [],
};

export const isBoolean = [
	"hasCard",
	"voterId",
	"birthCertificate",
	"insurance_hasInsurance",
	"contraceptiveUse",
	"deliveryFromDoctor",
	"childDeath",
	"pregnanctCheck",
	// "childNutrition",
	"childLabour",
	"childLabor",
	"earlyMarriage",
	"pregnantTetanus",
	"dhukutiKarobar",
	"bankAccount",
];
export const booleanAttribute = {
	hasCard: "card_taken",
	voterId: "voter_id_yes",
	birthCertificate: "birth_cert_yes",
	insurance_hasInsurance: "yes",
	contraceptiveUse: "yes",
	deliveryFromDoctor: "delivery_yes",
	childDeath: "yes",
	pregnanctCheck: "check_yes",
	// childNutrition: "mal_nutri_yes",
	childLabour: "yes",
	childLabor: "yes",
	earlyMarriage: "e_marry_yes",
	pregnantTetanus: "tetanus_yes",
	dhukutiKarobar: "yes",
	bankAccount: "account_yes",
};

export const isInteger = ["wardNumber"];
export const nestedArray = [
	// "agricultureProductSalesType",
	"agricultureProductionSalesType_crops",
	"agricultureProductionSalesType_daal",
	"agricultureProductionSalesType_oilseed",
	"agricultureProductionSalesType_vegetable",
	"agricultureProductionSalesType_fruit",
	"agricultureProductionSalesType_cash_crops",
	"home_homeBusinessName",
	"insurance_hasInsurance",
	"insurance_insuranceType",
	"bloodGroup_bloodType",
	"toilet_isToilet",
	"toilet_toiletNo",
	"toilet_toiletYes",
	"detail_name",
	"item_title",
];
export const rangeValue = [
	"age",
	"nonAgriIncome",
	"nonAgriExpense",
	"agriIncome",
	"agriExpense",
];

export const searchTableHeader = [
	"क्र.स ",
	"वडा न.",
	"घर न.",
	"मृत्यु हुनेको नाम थर",
	"लिङ्ग",
	"उमेर ",
	"मृत्युको कारण ",
];

export const genderList = [
	{
		nepali: "पुरुष",
		english: "Male",
		value: "male",
	},
	{
		nepali: "महिला",
		english: "Female",
		value: "female",
	},
	{
		nepali: "तेश्रो लिंगी",
		english: "Third gender",
		value: "third_gender",
	},
];

export const casteList = [
	{
		english: "brahmin ",
		nepali: "ब्राहमण",
		value: "brahmin",
	},
	{
		english: "chhetri",
		nepali: "क्षेत्री",
		value: "chhetri",
	},
	{
		english: "magar",
		nepali: "मगर",
		value: "magar",
	},
	{
		english: "newar",
		nepali: "नेवार",
		value: "newar",
	},
	{
		english: "gurung",
		nepali: "गुरुङ",
		value: "gurung",
	},
	{
		english: "thakali",
		nepali: "थकाली",
		value: "thakali",
	},
	{
		english: "chantyal",
		nepali: "छन्त्याल",
		value: "chantyal",
	},
	{
		english: "dalit",
		nepali: "दलित",
		value: "dalit",
	},
	{
		english: "rai",
		nepali: "राई",
		value: "rai",
	},
	{
		english: "limbu",
		nepali: "लिम्बु",
		value: "limbu",
	},
	{
		english: "dunwar",
		nepali: "दनुवार",
		value: "dunwar",
	},
	{
		english: "tharu",
		nepali: "थारु",
		value: "tharu",
	},
	{
		english: "tamang",
		nepali: "तामांग",
		value: "tamang",
	},
	{
		english: "bote",
		nepali: "भोटे",
		value: "bote",
	},
	{
		english: "sada",
		nepali: "सदा",
		value: "sada",
	},
	{
		english: "muslim",
		nepali: "मुस्लिम",
		value: "muslim",
	},
	{
		english: "yadav",
		nepali: "यादव",
		value: "yadav",
	},
	{
		english: "thakuri",
		nepali: "ठकुरी",
		value: "thakuri",
	},
	{
		english: "other caste",
		nepali: "अन्य",
		value: "other_caste",
	},
];

export const religionList = [
	{
		english: "Hindu",
		nepali: "हिन्दु",
		value: "hindu",
	},
	{
		english: "Buddhist",
		nepali: "बौद्ध",
		value: "buddhist",
	},
	{
		english: "Muslim",
		nepali: "इस्लाम (मुस्लिम)",
		value: "muslim",
	},
	{
		english: "Christian",
		nepali: "क्रिस्टियन",
		value: "christian",
	},
	{
		english: "Kirat",
		nepali: "किंरात",
		value: "kirat",
	},
	{
		english: "jain",
		nepali: "जैन",
		value: "jain",
	},
	{
		english: "sikh",
		nepali: "शिख",
		value: "sikh",
	},
	{
		english: "Other",
		nepali: "अन्य",
		value: "other",
	},
];

export const educationList = [
	{
		nepali: "पढन नसक्ने",
		english: "cannotRead",
		value: "cannotRead",
	},
	{
		nepali: "लेख्न नसक्ने",
		english: "cannotWrite",
		value: "cannotWrite",
	},
	{
		nepali: "पढन लेख्न नसक्ने",
		english: "uneducate",
		value: "uneducate",
	},
	{
		nepali: "आधारभूततह",
		english: "primary",
		value: "primary",
	},
	{
		nepali: "प्रवेशिका/ S.E.E.",
		english: "see",
		value: "see",
	},
	{
		nepali: "प्रमाणपत्रतह (दश जोड दुई)",
		english: "higherSecondary",
		value: "higherSecondary",
	},
	{
		nepali: "स्‍नातकउतीर्ण",
		english: "bachelors",
		value: "bachelors",
	},
	{
		nepali: "स्‍नातकोत्तरउत्तीर्ण",
		english: "masters",
		value: "masters",
	},
	{
		nepali: "विद्यावारिधीहासिल",
		english: "phd",
		value: "phd",
	},
];

// export const preprima0.ryList = [
// 	{
// 		nepali: "१",
// 		english: "1",
// 		value: "class_1"
// 	},
// 	{
// 		nepali: "२",
// 		english: "2",
// 		value: "class_2"
// 	},
// 	{
// 		nepali: "३",
// 		english: "3",
// 		value: "class_3"
// 	},
// 	{
// 		nepali: "४",
// 		english: "4",
// 		value: "class_4"
// 	},
// 	{
// 		nepali: "५",
// 		english: "5",
// 		value: "class_5"
// 	}
// ];

export const primaryList = [
	{
		nepali: "१",
		english: "1",
		value: "class_1",
	},
	{
		nepali: "२",
		english: "2",
		value: "class_2",
	},
	{
		nepali: "३",
		english: "3",
		value: "class_3",
	},
	{
		nepali: "४",
		english: "4",
		value: "class_4",
	},
	{
		nepali: "५",
		english: "5",
		value: "class_5",
	},
	{
		nepali: "६",
		english: "6",
		value: "class_6",
	},
	{
		nepali: "७",
		english: "7",
		value: "class_7",
	},
	{
		nepali: "८",
		english: "8",
		value: "class_8",
	},
];

export const secondaryList = [
	{
		nepali: "९",
		english: "9",
		value: "class_9",
	},
	{
		nepali: "१०",
		english: "10",
		value: "class_10",
	},
	{
		nepali: "११",
		english: "11",
		value: "class_11",
	},
	{
		nepali: "१२",
		english: "12",
		value: "class_12",
	},
];

export const occupationList = [
	{
		nepali: "कृषि - पशुपालन",
		english: "Agriculture",
		value: "agriculture",
	},
	// {
	//     nepali: "कृषि तथा पशुपालन (व्यावसाहिक )",
	//     english: "Agriculture",
	//     value: "agriculture_animal_business",
	// },
	{
		nepali: "व्यापार",
		english: "Job",
		value: "business",
	},
	{
		nepali: "सरकारी नोकरी (अवकाश प्राप्तलाई पनि उल्लेख गर्ने)",
		english: "Industry",
		value: "industry",
	},
	{
		nepali: "जागिर - अन्य",
		english: "Unemployment",
		value: "gov_job",
	},
	{
		nepali: "ज्याला मजदुरी",
		english: "Lbor",
		value: "labor",
	},
	{
		nepali: "उद्योग",
		english: "Student",
		value: "industry",
	},
	// {
	// 	nepali: "गृहणी",
	// 	english: "House wife",
	// 	value: "house_wife",
	// },
	{
		nepali: "बैदेशिक रोजगार",
		english: "Foreign job",
		value: "foreign_job",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const jobList = [
	{
		nepali: "प्राइभेट",
		english: "Private",
		value: "private",
	},
	{
		nepali: "सरकारी (निजामती कर्मचारी )",
		english: "Government",
		value: "government",
	},
	{
		nepali: "करार कर्मचारी (सरकारी कार्यालय )",
		english: "karaar",
		value: "karaar",
	},
	{
		nepali: "सुरक्षा निकाय",
		english: "Security depart",
		value: "security",
	},
	{
		nepali: "N.G.O.  I.N.G.O संघ संस्था",
		english: "NGO",
		value: "ngo_ingo",
	},
	{
		nepali: "बैंक तथा वित्तिय संस्था",
		english: "Bank finance",
		value: "bank_finance",
	},
	{
		nepali: "चिकित्सक तथा स्वास्थकर्मी",
		english: "Health and health worker",
		value: "health",
	},
	{
		nepali: "शिक्षक/शिक्षिका",
		english: "Teacher",
		value: "teacher",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const healthconditionList = [
	{
		nepali: "स्वास्थ्य",
		english: "Health",
		value: "helathy",
	},
	{
		nepali: "सामान्य रोग लागेको",
		english: "Normal diseases patient",
		value: "normal_patient",
	},
	{
		nepali: "दिर्घ रोग लागेको",
		english: "Unhealthy",
		value: "unhelathy",
	},
];
export const insuranceType = [
	{
		nepali: "जीवन बीमा",
		english: "Life Insurance",
		value: "life_insurance",
	},
	{
		nepali: "स्वास्थ्य बीमा",
		english: "Health Insurance",
		value: "health_insurance",
	},
	{
		nepali: "कोरोना विमा",
		english: "Corona Insurance",
		value: "korona_insurance",
	},
];

export const hasInsuranceList = [
	{
		nepali: "छ",
		english: "Yes",
		value: true,
	},
	{
		nepali: "छैन",
		english: "No",
		value: false,
	},
];

export const unhealthy = [
	{
		nepali: "उच्च रक्तचाप",
		english: "High blood pressure",
		value: "high_blood_pre",
	},
	{
		nepali: "मुटु रोग",
		english: "Heart diseases",
		value: "heart",
	},
	{
		nepali: "डाइबेटिज/मधुमेह/सुगर",
		english: "Diabetes",
		value: "diabetes",
	},
	{
		nepali: "आस्थमा/दम",
		english: "Aasthama",
		value: "aasthama",
	},
	{
		nepali: "मृगोला सम्बन्धी",
		english: "Liver related",
		value: "liver",
	},
	{
		nepali: "HIV AIDS",
		english: "HIV AIDS",
		value: "hiv",
	},
	{
		nepali: "क्षयरोग (टि .बि. नया तथा पुरानो )",
		english: "",
		value: "tb",
	},
	{
		nepali: "हाडजोर्नी तथा नसा सम्बन्धि रोग (बाथ रोग )",
		english: "",
		value: "bornjoint",
	},
	{
		nepali: "क्षारे रोग",
		english: "",
		value: "epilepsy",
	},
	{
		nepali: "कडा खालको मानसिक रोग",
		english: "",
		value: "manasik",
	},
	{
		nepali: "रगत सम्बन्धि रोग",
		english: "",
		value: "thalassemia",
	},
	{
		nepali: "क्यान्सर",
		english: "",
		value: "cancer",
	},
	{
		nepali: "थाइराईड रोग",
		english: "",
		value: "thairoid",
	},
	{
		nepali: "पाठेघर झर्ने खस्ने रोग",
		english: "",
		value: "paatheghar",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

// export const teacherList = [
// 	{
// 		nepali: "स्थानीय श्रोत",
// 		english: "Local",
// 		value: "local"
// 	},
// 	{
// 		nepali: "सामुदायिक",
// 		english: "Government",
// 		value: "government"
// 	},
// 	{
// 		nepali: "राहत",
// 		english: "Rahat",
// 		value: "rahat"
// 	},
// 	{
// 		nepali: "नेपाल प्रहरी",
// 		english: "Nepal Police",
// 		value: "nepal_police"
// 	},
// 	{
// 		nepali: "सशस्त्र प्रहरी",
// 		english: "A P F",
// 		value: "a_p_f"
// 	},
// 	{
// 		nepali: "नेपाली सेना",
// 		english: "Nepal Army",
// 		value: "nepal_army"
// 	}
// ];
export const mentalcondition = [
	{
		nepali: "जन्मजात",
		english: "By birth",
		value: "by_birth",
	},
	{
		nepali: "जन्मदको समयमा",
		english: "Diseases",
		value: "birth_time",
	},
	{
		nepali: "जन्म पश्चात (रोगको कारण)",
		english: "Accident",
		value: "afterbirth_disease",
	},
	{
		nepali: "जन्म पश्चात (दुर्घटनाको कारण)",
		english: "janaaandolan",
		value: "afterbirth_accident",
	},
];

export const mentalType = [
	{
		nepali: "शारििरक अपाङ्गता",
		english: "Disability",
		value: "disability",
	},
	{
		nepali: "दृष्टि संबन्धि अपाङ्गता",
		english: "",
		value: "vision",
	},
	{
		nepali: "सुनाइ सम्बन्धी ",
		english: "Listening",
		value: "listening",
	},
	{
		nepali: "स्वर र वोलाई सम्बन्धि अपाङ्गता",
		english: "",
		value: "speak",
	},
	{
		nepali: "श्रावण दृष्टिबिहिन अपांगता",
		english: "",
		value: "hear_vision",
	},
	{
		nepali: "बौद्धिक अपाङ्गता",
		english: "Aged Mental",
		value: "aged_mental",
	},
	{
		nepali: "अटिज्म सम्बन्धि",
		english: "",
		value: "atijam",
	},
	{
		nepali: "अनुवंशिय रक्तस्रवा (हेमोफेलिया) सम्वन्धि अपाङ्गता",
		english: "",
		value: "hemophilia",
	},
	{
		nepali: "मानसिक रुपमा अपाङ्गता",
		english: "Mental",
		value: "mental",
	},
	{
		nepali: "बहु अपांगता",
		english: "many_disable",
		value: "many_disable",
	},
];

export const mentalCardYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];

export const voterIdYesNo = [
	{
		nepali: "छ",
		english: "yes",
		value: "voter_id_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "voter_id_no",
	},
];

export const mentalCardtype = [
	{
		nepali: "रातो (पूर्ण आसक्त) क वर्ग",
		english: "Red card",
		value: "1",
	},
	{
		nepali: "निलो (अरुको सहयोगबाट) ख वर्ग",
		english: "Blue card",
		value: "2",
	},
	{
		nepali: "पहेलो (कृतिम अंगको ) ग वर्ग",
		english: "Yellow card",
		value: "3",
	},
	{
		nepali: "सेतो (सामान्य) घ वर्ग्ग",
		english: "White card",
		value: "4",
	},
];

export const mothertongue = [
	{
		nepali: "डोटेली",
		english: "doteli",
		value: "doteli",
	},
	{
		nepali: "चौधरी",
		english: "chaudary",
		value: "chaudary",
	},
	{
		nepali: "बैतडी",
		english: "baitadi",
		value: "baitadi",
	},
	{
		nepali: "बाझागी",
		english: "bhajaghi",
		value: "bhajaghi",
	},
	{
		nepali: "अछामी",
		english: "achaame",
		value: "achaame",
	},
	{
		nepali: "राना",
		english: "rana",
		value: "rana",
	},
	{
		nepali: "नेपाली",
		english: "nepali",
		value: "nepali",
	},
	{
		nepali: "दैलेखी",
		english: "dailekhi",
		value: "dailekhi",
	},
	{
		nepali: "अन्य",
		english: "other",
		value: "other",
	},
];

export const bloodgroup = [
	{
		nepali: "O+",
		english: "O+",
		value: "o_positive",
	},
	{
		nepali: "O-",
		english: "O-",
		value: "o_negative",
	},
	{
		nepali: "A+",
		english: "A+",
		value: "a_positive",
	},
	{
		nepali: "A-",
		english: "A-",
		value: "a_neagtive",
	},
	{
		nepali: "B+",
		english: "B+",
		value: "b_positive",
	},
	{
		nepali: "B-",
		english: "B-",
		value: "b_negative",
	},
	{
		nepali: "AB+",
		english: "AB+",
		value: "ab_positive",
	},
	{
		nepali: "AB-",
		english: "AB-",
		value: "ab_neagtive",
	},
	{
		nepali: "थाहा छैन",
		english: "Donot know",
		value: "no_blood_group",
	},
];

export const marriage = [
	{
		nepali: "अविवाहित",
		english: "Unmarried",
		value: "unmarried",
	},
	{
		nepali: "विवाहित",
		english: "Married",
		value: "married",
	},
	{
		nepali: "बहु बिबाह",
		english: "Multi marriage",
		value: "multi_marriage",
	},
	{
		nepali: "पुन बिबाह",
		english: "Re married",
		value: "re_married",
	},
	{
		nepali: "बिधुर",
		english: "Widow",
		value: "widow",
	},
	{
		nepali: "बिधुवा",
		english: "Widol",
		value: "wido1",
	},
	{
		nepali: "सम्बन्ध बिच्छेद (विवाहित तर अलग बसेको)",
		english: "Divorce",
		value: "divorce",
	},
	{
		nepali: "विवाहित तर अलग बसेको",
		english: "Under age",
		value: "marriage_but_l",
	},
];
export const livingTypeList = [
	{
		nepali: "अस्थाई",
		english: "temporary",
		value: "temporary",
	},
	{
		nepali: "स्थाई",
		english: "Permanent",
		value: "permanent",
	},
	{
		nepali: "सुकुम्बासी",
		english: "sukumbaasi",
		value: "sukumbaasi",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const permanent = [
	{
		nepali: "अर्को वडा",
		english: "Next ward",
		value: "next_ward",
	},
	{
		nepali: "यही जिल्ला",
		english: "Same district",
		value: "same_district",
	},
	{
		nepali: "अर्को जिल्ला",
		english: "Next district",
		value: "next_district",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const drinkingwaterLists = [
	{
		nepali: "धारा / पाईप",
		english: "Water pipe in home",
		value: "tap_home",
	},
	{
		nepali: "टुबेल / हाते पम्प",
		english: "Public pipe",
		value: "handpump",
	},
	{
		nepali: "ढाकिएको इनार / कुवा",
		english: "Tubewell / handpump",
		value: "well_cover",
	},
	{
		nepali: "खुला इनार / कुवा",
		english: "Well",
		value: "well_uncover",
	},
	{
		nepali: "मूलधारा",
		english: "Well uncover",
		value: "main_water",
	},
	{
		nepali: "नदी खोला",
		english: "Mulko Pani",
		value: "river",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "others",
	},
];

export const drinkingwaterplaceLists = [
	{
		nepali: "घर आँगनमा",
		english: "Home courtyard",
		value: "home_courtyard",
	},
	{
		nepali: "१० मिनेट सम्मको पैदला",
		english: "10 Min walk ",
		value: "10_min_walk",
	},
	{
		nepali: "आधा घण्टा सम्मको दुरी",
		english: "Half hour walk",
		value: "half_hour_walk",
	},
	{
		nepali: "१ घण्टा वा सो भन्दा माथि",
		english: "1 hour or more than",
		value: "1_hour_walk",
	},
];
export const cookingresourceLists = [
	{
		nepali: "दाउरा",
		english: "Wood9sadharan chulo)",
		value: "1",
	},
	{
		nepali: "मट्टीतेल",
		english: "Wood(sudhariyeko chulo",
		value: "2",
	},
	{
		nepali: "एल.पी.ग्याँस",
		english: "Kerosene",
		value: "3",
	},
	{
		nepali: "गुइठा/गोबर ग्याँस",
		english: "LGP Gas",
		value: "4",
	},
	{
		nepali: "बिजुली",
		english: "Bio gas",
		value: "5",
	},
	// {
	// 	nepali: "विधुत",
	// 	english: "Electricity",
	// 	value: "electricity",
	// },
	// {
	// 	nepali: "सौर्य उर्जा",
	// 	english: "Solar energy",
	// 	value: "solar",
	// },
	// {
	// 	nepali: "गुइठा",
	// 	english: "Dung",
	// 	value: "guithaa",
	// },
	{
		nepali: "अन्य",
		english: "Other",
		value: "6",
	},
];

export const waterPurification = [
	{
		nepali: "शुद्दिकरण गरिदैन",
		english: "",
		value: "no_purificatio",
	},
	{
		nepali: "उमालेर",
		english: "",
		value: "boil",
	},
	{
		nepali: "फिल्टर गरेर",
		english: "",
		value: "filter",
	},
	{
		nepali: "औषधि हालेर",
		english: "",
		value: "checimal",
	},
	{
		nepali: "सोडीस प्रविधि",
		english: "",
		value: "solar",
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other",
	},
];

export const dirtyWaterManagement = [
	{
		nepali: "करेसाबारीमा",
		english: "",
		value: "gardenfiel",
	},
	{
		nepali: "ढलमा मिसाएको",
		english: "",
		value: "drain",
	},
	{
		nepali: "खाडलमा जम्मा गर्ने",
		english: "",
		value: "ponding",
	},
	{
		nepali: "अब्यबस्थित छाड्ने",
		english: "",
		value: "unmanaged",
	},
];

export const lightresourceLists = [
	{
		nepali: "बिजुली",
		english: "Electric line",
		value: "1",
	},
	{
		nepali: "मट्टीतेल",
		english: "Kerosene",
		value: "2",
	},
	{
		nepali: "बायो ग्याँस",
		english: "Solar",
		value: "3",
	},
	{
		nepali: "सोलार",
		english: "Bio Gas",
		value: "4",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "5",
	},
];
export const isToilet = [
	{
		nepali: "छ",
		english: "Yes",
		value: "yes",
	},
	{
		nepali: "छैन (शौचालय नभएको )",
		english: "No toilet",
		value: "no",
	},
	{
		nepali: "छ तर संचालन नभएको",
		english: "No use",
		value: "yes_but_not_us",
	},
];
export const noToilet = [
	{
		nepali: "जग्गा नभएर",
		english: "No Land",
		value: "no_land",
	},
	{
		nepali: "बनाउने पैसा नभएर",
		english: "No Money",
		value: "no_money",
	},
	{
		nepali: "छिमेकीको प्रयोग गरेर",
		english: "Neighbour Use",
		value: "neighbour_use",
	},
	{
		nepali: "नजिकै खुला ठाँउ वा जंगल उपलब्ध भएर",
		english: "Open Space ",
		value: "open_space",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "others_toilet",
	},
];
export const toiletresourceLists = [
	{
		nepali: "फ्लस भएको(सार्वजनिक ढल)",
		english: "Public tank",
		value: "public_tank",
	},
	{
		nepali: "फ्लस भएको(सेफ्टी ट्याङ्क)",
		english: "Private tank",
		value: "private_tank",
	},
	{
		nepali: "साधारण गोबर ग्यास प्लान्टमा मिसिने",
		english: "Ordinary tank",
		value: "ordinary_tankgas",
	},
	{
		nepali: "साधारण",
		english: "Ordinary",
		value: "ordinary",
	},
];
export const roadLists = [
	{
		nepali: "पक्कि/कालोपत्रो सडक",
		english: "Black pitched",
		value: "black_pitched",
	},
	{
		nepali: "ग्रावेल सडक",
		english: "Gravel",
		value: "gravel",
	},
	{
		nepali: "धुले सडक (कच्ची)",
		english: "Dusty road",
		value: "dusty",
	},
	{
		nepali: "गोरेटो बाटो जोडिएको",
		english: "Goreto",
		value: "goreto",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const wastemanagementLists = [
	{
		nepali: "जैविक मल बनाउने",
		english: "",
		value: "organic_mal",
	},
	{
		nepali: "संकलित फोहोर जलाउने",
		english: "Burn the waste",
		value: "garbage_burn",
	},
	{
		nepali: "गोबर ग्यास प्लान्ट प्रायोग गर्ने",
		english: "Use gobar gas",
		value: "gobar_gas",
	},
	{
		nepali: "निजि संस्थाले घरबाटै उठाउने",
		english: "Collect by private organization",
		value: "private_org",
	},
	{
		nepali: "अव्यवस्थित तबरले बाहिर जताभावी फ्याल्ने",
		english: "Throw unmanaged way",
		value: "unmanaged",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const salttypeLists = [
	{
		english: "",
		nepali: "धुलो खुल्ला - नुन",
		value: "no",
	},
	{
		english: "",
		nepali: "आयोडिन भएको नुन  ( दुई बच्चाको छाप भएको )",
		value: "yes",
	},
];

export const bankAccount = [
	{
		nepali: "छ",
		english: "Yes",
		value: "account_yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "account_no",
	},
];
export const trueFalse = [
	{
		nepali: "छ",
		english: "Yes",
		value: true,
	},
	{
		nepali: "छैन",
		english: "No",
		value: false,
	},
];

export const illTreament = [
	{
		nepali: "धामी झाक्री",
		english: "dhami jhakri",
		value: "dhaami_jhakri",
	},
	{
		nepali: "औषधि पसल",
		english: "pharmecy",
		value: "medical",
	},
	{
		nepali: "क्लिनिक",
		english: "clinic",
		value: "clinic",
	},
	{
		nepali: "स्वास्थ्य चौकी",
		english: "health post",
		value: "health_post",
	},
	{
		nepali: "जिल्लाका निजि अस्पताल",
		english: "district private hospital",
		value: "private_hospit",
	},
	{
		nepali: "जिल्ला बाहिर सरकारी अस्पताल",
		english: "public hospital outside the valley",
		value: "public_hospita",
	},
	{
		nepali: "स्थानीय स्वास्थ्य कर्मी",
		english: "health worker",
		value: "healthworker",
	},
	{
		nepali: "जिल्लाका सरकारी अस्पताल",
		english: "District Government",
		value: "district_govt_",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "others_ill",
	},
];
export const bhattaLists = [
	{
		nepali: "छैन",
		english: "Not Taken",
		value: "not_taken",
	},
	{
		nepali: "पेन्सन नेपाल सरकार",
		english: "Pension Nepal Gov",
		value: "pension",
	},
	{
		nepali: "पेन्सन अन्य देश",
		english: "Pension Other Country",
		value: "pension_other",
	},
	{
		nepali: "बृद्ध भत्ता",
		english: "Old age allowance",
		value: "old",
	},
	{
		nepali: "विधुवा भत्ता",
		english: "Widow Allowance",
		value: "bidhuwa",
	},
	{
		nepali: "दलित बृद्ध भत्ता",
		english: "Dalit Allowance",
		value: "dalit",
	},
	{
		nepali: "अपाङ्गता भत्ता",
		english: "Disablity Allowance",
		value: "handicap",
	},
	{
		nepali: "सरकारी छात्रवृत्ति",
		english: "Scholarship",
		value: "scholarship",
	},
];
export const businessLists = [
	{
		nepali: "होमस्टे ब्यबसाय संचालन",
		english: "Home Stay",
		value: "home_stay",
	},
	{
		nepali: "भाडाकुडाका सामान बनाउने बेच्ने",
		english: "karuwa",
		value: "karuwa",
	},
	{
		nepali: "खोलामा जालबाट माछा मार्ने तथा बिक्रि वितरण गर्ने",
		english: "fishing",
		value: "river_fish_col",
	},
	{
		nepali: "हातेकागज",
		english: "Hand paper",
		value: "hand_paper",
	},
	{
		nepali: "खुकुरी",
		english: "khukuri",
		value: "khukuri",
	},
	{
		nepali: "राडीपाखी",
		english: "raadi pakhi",
		value: "raadi_pakhi",
	},
	{
		nepali: "कुराउनी",
		english: "kurauni",
		value: "kurauni",
	},
	{
		nepali: "फर्निचर",
		english: "furniture",
		value: "furniture",
	},
	{
		nepali: "अन्य",
		english: "other",
		value: "other",
	},
];
// export const herbLists = [
// 	{
// 		nepali: "अदामचल",
// 		english: "adaamchal",
// 		value: "adaamchal"
// 	},
// 	{
// 		nepali: "आखनबेद",
// 		english: "aakhanbed",
// 		value: "ankhanbed"
// 	},
// 	{
// 		nepali: "कुट्की",
// 		english: "kutki",
// 		value: "kutki"
// 	},
// 	{
// 		nepali: "ठुलो ओखती",
// 		english: "thulo okhati",
// 		value: "thulo_okhati"
// 	},
// 	{
// 		nepali: "हर्रो",
// 		english: "harro",
// 		value: "harro"
// 	},
// 	{
// 		nepali: "अमला",
// 		english: "amala",
// 		value: "aamla"
// 	},
// 	{
// 		nepali: "टिमुर",
// 		english: "timur",
// 		value: "timur"
// 	},
// 	{
// 		nepali: "चिरैतो",
// 		english: "chairato",
// 		value: "chirauto"
// 	},
// 	{
// 		nepali: "चानो",
// 		english: "chano",
// 		value: "chano"
// 	},
// 	{
// 		nepali: "बर्रो",
// 		english: "barro",
// 		value: "barro"
// 	},
// 	{
// 		nepali: "बोझो",
// 		english: "bojho",
// 		value: "bojho"
// 	},
// 	{
// 		nepali: "अन्य",
// 		english: "other",
// 		value: "other"
// 	}
// ];
export const landUsedliving = [
	{
		nepali: "निजि/नम्बरी",
		english: "Self",
		value: "self",
	},
	{
		nepali: "गुठि",
		english: "Guthi",
		value: "guthi",
	},
	{
		nepali: "सार्वजनिक",
		english: "Public",
		value: "public_land",
	},
	{
		nepali: "ऐलानी",
		english: "Yelani",
		value: "yelani",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const housetype = [
	{
		nepali: "आरसीसी",
		english: "rcc",
		value: "aarcc",
	},
	{
		nepali: "ढुंगाको घर (सिमेन्टको जोडाइ)",
		english: "Stone house",
		value: "stone_house",
	},
	{
		nepali: "पक्कि इँटाको घर सिमेन्टको जोडाइ",
		english: "Break house",
		value: "break_house",
	},
	{
		nepali: "ढुंगाको घर (माटोको जोड़ाई)",
		english: "Stone house",
		value: "stone_mud_house",
	},
	{
		nepali: "जस्ताले बेरेको",
		english: "tin",
		value: "tin",
	},
	{
		nepali: "बास /टाटि घर",
		english: "Bamboo house",
		value: "bamboo_house",
	},
	{
		nepali: "सेन्टर बेरा",
		english: "senta house",
		value: "sentar_beraa",
	},
	{
		nepali: "काठको खम्बा (काठको घर )",
		english: "Wooden",
		value: "wooden",
	},
	{
		nepali: "ब्लक र सिमेन्टको जोडाइ",
		english: "Block and Cement",
		value: "block_and_cement",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const rooftype = [
	{
		nepali: "खर / पराल / छावाली",
		english: "Jasta pata",
		value: "roof_grass",
	},
	{
		nepali: "जस्ता / टिन / च्यादर",
		english: "Fush / khar",
		value: "steel",
	},
	{
		nepali: "टायल / कपडा / झिगटी / ढुंगा",
		english: "Tiles",
		value: "tiles",
	},
	{
		nepali: "सीमेंट / ढलान",
		english: "RCC Cement",
		value: "rcc",
	},
	{
		nepali: "काठ / फल्याक",
		english: "Wooden flake",
		value: "wooden",
	},
	{
		nepali: "माटो",
		english: "Mato",
		value: "mud",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "others",
	},
];
export const hasLanddocument = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];
export const dhukutiKarobar = [
	{
		nepali: "छ",
		english: "Yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "No",
		value: "no",
	},
];
export const doHouseCriteriafullfill = [
	{
		nepali: "छ",
		english: "yes",
		value: "stadard_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "standard_no",
	},
];
export const hasHouseMappass = [
	{
		nepali: "नक्सा मात्र पास भएको",
		english: "Yes",
		value: "1",
	},
	{
		nepali: "सम्पन्नताको प्रमाणपत्र भएको",
		english: "No",
		value: "2",
	},
	{
		nepali: "घर नक्सा पास बनाउनु पर्ने",
		english: "No",
		value: "3",
	},
];
export const title = [
	{
		nepali:
			"सूचना तथा प्रविधि (इलेक्ट्रोनिक कम्प्युटर/मोबाइल/रेडियो घडी आदि मर्मत)",
		english: "IT",
		value: "it",
	},
	{
		nepali: "इलेक्ट्रिकसियन",
		english: "electrician",
		value: "electrician",
	},
	{
		nepali: "होटल सम्बन्धि",
		english: "hotel",
		value: "hotel",
	},
	{
		nepali: "पेंटिंग",
		english: "painting",
		value: "painting",
	},
	{
		nepali: "प्लम्बर",
		english: "plumber",
		value: "plumber",
	},
	{
		nepali: "सिलाई,बुनाई,बुटिक,सृंगर,पार्लर आदि",
		english: "sewing",
		value: "sewing",
	},
	{
		nepali: "कृषि प्राविधिक तालिम तथा अध्ययन  (जेटिए , खाध्य प्रसोधन आदि )",
		english: "agri",
		value: "agri",
	},
	{
		nepali: "निर्माण सम्बन्धी  सिकर्मी / डकर्मी",
		english: "construction",
		value: "construction",
	},
	{
		nepali: "इन्जीनियरिङ्,अटो मोबाइल  र मेकानिक्स",
		english: "engineering",
		value: "engineering",
	},
	{
		nepali: "जनस्वास्थ्य सम्बन्धी",
		english: "public health",
		value: "public_health",
	},
	{
		nepali: "पशु स्वास्थ्य सम्बन्धी",
		english: "animal health",
		value: "animal_health",
	},
	{
		nepali: "वन सम्बन्धी",
		english: "forest",
		value: "forest",
	},
	{
		nepali: "पर्यटन टुर गाइड , ट्राभल र सत्कार",
		english: "tourism guide",
		value: "tourism_guide",
	},
	{
		nepali: "कला सम्बन्धी",
		english: "art",
		value: "art",
	},
	{
		nepali: "कानुनी साक्षरता",
		english: "law",
		value: "law",
	},
	{
		nepali: "विपदब्यबस्थापन सम्बन्धि",
		english: "disaster",
		value: "disaster",
	},
	{
		nepali: "अन्य (खुलाउनु होस्)",
		english: "other",
		value: "other",
	},
];
export const birthCertificate = [
	{
		nepali: "छ",
		english: "yes",
		value: "birth_cert_yes",
	},
	{ nepali: "छैन", english: "no", value: "birth_cert_no" },
];
export const contraceptiveUse = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];
export const deliveryFromdoctor = [
	{
		nepali: "छ",
		english: "yes",
		value: "delivery_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "delivery_no",
	},
];
export const childDeath = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];
export const pregnanctcheck = [
	{
		nepali: "छ",
		english: "yes",
		value: "check_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "check_no",
	},
];
// export const childnutrition = [
// 	{
// 		nepali: "छ",
// 		english: "yes",
// 		value: "mal_nutri_yes"
// 	},
// 	{
// 		nepali: "छैन",
// 		english: "no",
// 		value: "mal_nutri_no"
// 	}
// ];
export const childlabour = [
	{
		english: "",
		nepali: "छन्",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैनन्",
		value: "no",
	},
];

export const childlabor = [
	{
		nepali: "छ",
		english: "yes",
		value: "yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "no",
	},
];

export const earlymarriage = [
	{
		nepali: "छ",
		english: "yes",
		value: "e_marry_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "e_marry_no",
	},
];
export const pregnanttetanus = [
	{
		nepali: "छ",
		english: "yes",
		value: "tetanus_yes",
	},
	{
		nepali: "छैन",
		english: "no",
		value: "tetanus_no",
	},
];
export const preprimary = [
	{
		nepali: "१",
		english: "1",
		value: "class_1",
	},
	{
		nepali: "२",
		english: "2",
		value: "class_2",
	},
	{
		nepali: "३",
		english: "3",
		value: "class_3",
	},
	{
		nepali: "४",
		english: "4",
		value: "class_4",
	},
	{
		nepali: "५",
		english: "5",
		value: "class_5",
	},
];

export const answererRelation = [
	{
		nepali: "श्रीमान",
		english: "Husband",
		value: "husband",
	},
	{
		nepali: "श्रीमती",
		english: "Wife",
		value: "wife",
	},
	{
		nepali: "आमा",
		english: "Mother",
		value: "mother",
	},
	{
		nepali: "बुवा",
		english: "Father",
		value: "father",
	},
	{
		nepali: "छोरा",
		english: "Son",
		value: "son",
	},
	{
		nepali: "छोरी",
		english: "Daughter",
		value: "daughter",
	},
	{
		nepali: "बुहारी",
		english: "Buhari",
		value: "buhari",
	},
	{
		nepali: "ज्वाई",
		english: "Jwai",
		value: "jwai",
	},
	{
		nepali: "सासु",
		english: "Sasu",
		value: "sashu",
	},
	{
		nepali: "ससुरा",
		english: "Sasura",
		value: "sasura",
	},
	{
		nepali: "काका",
		english: "Kaka",
		value: "kaka",
	},
	{
		nepali: "काकी",
		english: "Kaki",
		value: "kaki",
	},
	{
		nepali: "फुपू",
		english: "Fupu",
		value: "fupu",
	},
	{
		nepali: "फुपाजु",
		english: "Fupaju",
		value: "fupaju",
	},
	{
		nepali: "मामा",
		english: "Mama",
		value: "mama",
	},
	{
		nepali: "माइजु",
		english: "Maiju",
		value: "maiju",
	},
	{
		nepali: "नाती",
		english: "Nati",
		value: "nati",
	},
	{
		nepali: "नातिनी",
		english: "Natini",
		value: "natini",
	},
	{
		nepali: "दाजु",
		english: "Daju",
		value: "daju",
	},
	{
		nepali: "भाई",
		english: "Bhai",
		value: "bhai",
	},
	{
		nepali: "दिदि",
		english: "Didi",
		value: "didi",
	},
	{
		nepali: "बहिनी",
		english: "Bahini",
		value: "bahini",
	},
	{
		nepali: "भाउजु",
		english: "Bhauju",
		value: "bhauju",
	},
	{
		nepali: "भाई बुहारी",
		english: "Bhai Buhari",
		value: "bhai_buhari",
	},
	{
		nepali: "जेठान",
		english: "Jethan",
		value: "jethan",
	},
	{
		nepali: "ठुलो बुवा",
		english: "Thulo Buwa",
		value: "thulo_bbuwa",
	},
	{
		nepali: "ठुलो आमा",
		english: "Thulo aama",
		value: "thulo_aama",
	},
	{
		nepali: "हजुर बुवा",
		english: "",
		value: "grand_father",
	},
	{
		nepali: "हजुर आमा",
		english: "Grand mother",
		value: "grand_mother",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const agriCropTypeList = [
	{
		nepali: "धान",
		english: "Paddy",
		value: "paddy",
	},
	{
		nepali: "मकै",
		english: "Maize",
		value: "maize",
	},
	{
		nepali: "कोदो/फापर",
		english: "Kodo",
		value: "kodo",
	},
	{
		nepali: "गहू/ जौ",
		english: "Gahu",
		value: "gahu",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const agriDaalType = [
	{
		nepali: "कालो दाल",
		english: "Black daal",
		value: "black_daal",
	},
	{
		nepali: "सिमि",
		english: "Simi",
		value: "simi",
	},
	{
		nepali: "राजमा",
		english: "Rajma",
		value: "musuri",
	},
	{
		nepali: "मटर /केराउ",
		english: "Matar/Kerau",
		value: "chana",
	},
	{
		nepali: "भट्मास",
		english: "Bhatmas",
		value: "bhatmas",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const agriOilType = [
	{
		nepali: "तोरी/सर्स्यु",
		english: "Tori",
		value: "tori",
	},
	{
		nepali: "आलस/तिल",
		english: "Aalash",
		value: "aalash",
	},
	{
		nepali: "फिलुंगे",
		english: "Filunge",
		value: "filunge",
	},
	{
		nepali: "सिलाम",
		english: "Sunflower",
		value: "sunflower",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const agriVegetableType = [
	{
		nepali: "आलु",
		english: "Potato",
		value: "potato",
	},
	{
		nepali: "बन्दा/काउली /बोडी",
		english: "Cabbage",
		value: "cabbage",
	},
	{
		nepali: "गोलभेडा",
		english: "Tomato",
		value: "tomato",
	},
	{
		nepali: "काक्रो/लौका /मुला",
		english: "Cucumber",
		value: "cucumber",
	},
	{
		nepali: "रायो (साग सब्जी )",
		english: "Saag",
		value: "saag",
	},
	{
		nepali: "करेला/घिरौला/फर्सी",
		english: "Ghiaraula",
		value: "bittergroud",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const agriFruitType = [
	{
		nepali: "आप/लिचि",
		english: "Mango",
		value: "mango",
	},
	{
		nepali: "केरा",
		english: "Banana",
		value: "banana",
	},
	{
		nepali: "सुन्तला/जुनार /कागती (सुन्तला जात )",
		english: "Orange",
		value: "orange",
	},
	{
		nepali: "स्याउ",
		english: "Apple",
		value: "apple",
	},
	{
		nepali: "किवी",
		english: "Kiwi",
		value: "kiwi",
	},
	{
		nepali: "अभोगाड़ो",
		english: "Avagado",
		value: "avogado",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const cashCropType = [
	{
		nepali: "लसुन/प्याज",
		english: "garlic",
		value: "garlic",
	},
	{
		nepali: "अदुवा/बेसार",
		english: "ginger",
		value: "ginger",
	},
	{
		nepali: "उखु",
		english: "sugarcane",
		value: "sugarcane",
	},
	{
		nepali: "कफी",
		english: "Coffee",
		value: "coffee",
	},
	{
		nepali: "चिया",
		english: "Tea",
		value: "tea",
	},
	{
		nepali: "च्याउ",
		english: "Mushroom",
		value: "mushroom",
	},
	{
		nepali: "अलैची",
		english: "Alainchi",
		value: "chilly",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other_cash_cro",
	},
];
export const autoSuggetionString = [
	{ nepali: "वडा", english: "Ward", value: "wardNumber" },
	{ nepali: "लिङ्ग", english: "Gender", value: "gender" },

	{ nepali: "उमेर", english: "Age", value: "age" },
	{
		nepali: "सरदर गैर कृषिमा वार्षिक आम्दानी",
		english: "Annual Expenditure",
		value: "nonAgriIncome",
	},
	{ nepali: "जात/जाती", english: "Ethnics", value: "caste" },
	{ nepali: "धर्म", english: "Religion", value: "religion" },
	{ nepali: "शिक्षा", english: "Education", value: "educationLevel" },
	{ nepali: "पेशा", english: "Occupation", value: "levelOneJob" },
	{
		nepali: "खेतिवाली को किसिम",
		english: "Agriculture Type",
		value: "agricultureProductSalesType",
	},
	{
		nepali: "अन्न वाली को किसिम",
		english: "Agriculture Type",
		value: "agricultureProductionSalesType_crops",
	},
	{
		nepali: "दालको किसिम",
		english: "Agriculture Type",
		value: "agricultureProductionSalesType_daal",
	},
	{
		nepali: "तेलहनको किसिम",
		english: "Agriculture Type",
		value: "agricultureProductionSalesType_oilseed",
	},
	{
		nepali: "तरकारीको किसिम",
		english: "Agriculture Type",
		value: "agricultureProductionSalesType_vegetable",
	},
	{
		nepali: "फलफुलको किसिम",
		english: "Agriculture Type",
		value: "agricultureProductionSalesType_fruit",
	},
	{
		nepali: "नगदेवालीको किसिम",
		english: "Agriculture Type",
		value: "agricultureProductionSalesType_cash_crops",
	},

	{
		nepali: "स्वास्थ्य",
		english: "Health Condition",
		value: "healthCondition",
	},
	{
		nepali: "दिर्घ रोग",
		english: "Unhealthy Disease",
		value: "unhealthyDisease",
	},
	{ nepali: "अपांगताको किसिम", english: "Disable", value: "disableType" },
	{
		nepali: "अपांगताको स्थिति",
		english: "Disable Condition",
		value: "condition",
	},
	{ nepali: "अपांगताको कार्ड", english: "Disable Card", value: "hasCard" },
	{ nepali: "कार्डको किसिम", english: "", value: "cardType" },
	{ nepali: "मातृभाषा", english: "MotherTongue", value: "motherTongue" },
	{
		nepali: "बैवाहिक स्थिति",
		english: "MartialStatus",
		value: "martialStatus",
	},
	{
		nepali: "खानेपानीको श्रोत",
		english: "Drinking Water Resource",
		value: "waterSource",
	},
	{
		nepali: "खाना पकाउने प्रमुख इन्धन/चुलो",
		english: "Cooking Resource",
		value: "cookingSource",
	},
	{
		nepali: "घरबाट निस्किने ठोस फोहोरको व्यवस्थापन",
		english: "Dirty Water Management",
		value: "wasteDisposal",
	},
	// {
	// 	nepali: "पिउने पानीको शुद्धीकरण",
	// 	english: "Water Purification",
	// 	value: "waterPurification",
	// },
	{
		nepali: "वत्तिको मुख्य स्रोत",
		english: "Light Resource",
		value: "electricSource",
	},
	{ nepali: "घरमा शौचालय छ", english: "Toilet Type", value: "toilet_isToilet" },
	{
		nepali: "शौचालय छैन भने किन नभएको",
		english: "Toilet Type",
		value: "toilet_toiletNo",
	},

	{
		nepali: "शौचालयको किसिम",
		english: "Toilet Type",
		value: "toilet_toiletYes",
	},
	{ nepali: "सडक/बाटोको अवस्था", english: "Road Type", value: "road" },
	// {
	// 	nepali: "फोहर मैलाको व्यवस्थापन",
	// 	english: "Waste Management",
	// 	value: "wasteDisposal",
	// },
	{ nepali: "नून प्रयोग", english: "Salt Type", value: "saltUsed" },
	{ nepali: "घरको सुबिधा", english: "House Facility", value: "item_title" },
	{ nepali: "पशुपंक्षी", english: "live Stock", value: "detail_name" },
	{ nepali: "बिरामी जाँच ", english: "illTreament", value: "illTreament" },
	{ nepali: "घरको छानाको किसिम", english: "Roof Type", value: "roofType" },
	{
		nepali: "घरको नक्सा पास",
		english: "House Map Pass",
		value: "hasHouseMapPass",
	},
	{ nepali: "शिप र तालिम", english: "Skill Detail", value: "title" },
	// {
	// 	nepali: "परिवारको कुनै १६ वर्ष मुनिका वालवालिका श्रम",
	// 	english: "Child Labour",
	// 	value: "childLabour",
	// },
];

export const abroadCountryList = [
	{
		nepali: "भारत",
		english: "India",
		value: "india",
	},
	{
		nepali: "कतार",
		english: "Qatar",
		value: "quatar",
	},
	{
		nepali: "भारत बाहेक अन्य सार्क राष्ट्र",
		english: "SAARC Country except India",
		value: "saarc",
	},
	{
		nepali: "साउदी अरब",
		english: "Saudi arab",
		value: "saudi_arab",
	},
	{
		nepali: "मलेसिया",
		english: "Malaysia",
		value: "malaysia",
	},
	{
		nepali: "जापान",
		english: "Japan",
		value: "japan",
	},
	{
		nepali: "यु.ए.ई. (दुबई)",
		english: "UAE",
		value: "uae",
	},
	{
		nepali: "दक्षिण कोरिया",
		english: "South korea",
		value: "south_korea",
	},
	{
		nepali: "अष्ट्रेलिया",
		english: "Australia",
		value: "australia",
	},
	{
		nepali: "अमेरिका",
		english: "USA",
		value: "usa",
	},
	{
		nepali: "इराक",
		english: "Iraq",
		value: "iraq",
	},
	{
		nepali: "बेलायत",
		english: "England",
		value: "england",
	},
	{
		nepali: "क्यानाडा",
		english: "Canada",
		value: "canada",
	},
	{
		nepali: "जर्मनी",
		english: "Germany",
		value: "germany",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];
export const abroadReasonList = [
	{
		nepali: "शिक्षा",
		english: "Education",
		value: "education",
	},
	{
		nepali: "रोजगार",
		english: "Employment",
		value: "employment",
	},
	{
		nepali: "घरेलु कामदार (महिला)",
		english: "Houser worker(female)",
		value: "house_worker",
	},
	{
		nepali: "डी.भी. चिठ्ठा",
		english: "DV Lottery",
		value: "dv_lottery",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const livestockDiffType = [
	{
		nepali: "गाई/बाछी",
		english: "Cow",
		value: "cow",
	},
	{
		nepali: "भैसी",
		english: "Bufffalo",
		value: "buffallo",
	},
	{
		nepali: "खसी /बोका/बाख्रा",
		english: "Goat/boka",
		value: "goat/boka",
	},
	{
		nepali: "राँगा/पाडा/पाडी",
		english: "Raanga/paada/padi",
		value: "ranga",
	},
	{
		nepali: "सुँगुर/बंगुर",
		english: "Pig",
		value: "pig",
	},
	{
		nepali: "भेडा/च्यांग्रा",
		english: "Sheep",
		value: "sheep",
	},
	{
		nepali: "गोरु",
		english: "Goru",
		value: "garu",
	},
	{
		nepali: "गधा/घोडा/ खच्चड",
		english: "Donkey",
		value: "gadha",
	},
	{
		nepali: "याक",
		english: "Yak",
		value: "yak",
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other",
	},
];

export const livestockType = [
	{
		nepali: "पशु",
		english: "Animal",
		value: "animal",
	},
	{
		nepali: "पन्छी",
		english: "Bird",
		value: "bird",
	},
];
export const wardSelect = [...Array(totalWard).keys()].map((each) => ({
	nepali: `वडा न ${each + 1}`,
	english: `Ward Number ${each + 1}`,
	value: `${each + 1}`,
}));
export const searchOption = {
	gender: [
		{
			nepali: "पुरुष",
			english: "Male",
			value: "male",
		},
		{
			nepali: "महिला",
			english: "Female",
			value: "female",
		},
		{
			nepali: "तेश्रो लिंगी",
			english: "Third Gender",
			value: "third_gender",
		},
	],
	wardNumber: [...Array(totalWard).keys()].map((each) => ({
		nepali: `वडा न ${each + 1}`,
		english: `Ward Number ${each + 1}`,
		value: each + 1,
	})),
	agricultureProductSalesType: [
		{ nepali: "अन्न वाली", english: "", value: "crops" },
		{ nepali: "दाल वाली", english: "", value: "daal" },
		{ nepali: "तेलहन उत्पादन", english: "", value: "oilseed" },
		{ nepali: "तरकारी उत्पादन", english: "", value: "vegetable" },
		{ nepali: "फलफुल", english: "", value: "fruit" },
		{ nepali: "नगदेवाली", english: "", value: "cash_crops" },
		{ nepali: "फुलखेती", english: "", value: "flower" },
	],

	// detail_name: [
	// 	{ nepali: "पशुि", english: "Animal", value: "animal" },
	// 	{ nepali: "पन्छी", english: "Bird", value: "bird" },
	//
	// ],
	// agricultureProductionSalesType_crops: [
	// 	{ nepali: "अन्न वालि", english: "", value: "crops" },
	// 	{ nepali: "दाल वालि", english: "", value: "daal" },
	// 	{ nepali: "तेलहन उत्पादन", english: "", value: "oilseed" },
	// 	{ nepali: "तरकारी उत्पादन", english: "", value: "vegetable" },
	// 	{ nepali: "फलफुल", english: "", value: "fruit" },
	// 	{ nepali: "नगदेवाली", english: "", value: "cash_crops" },
	// 	{ nepali: "फुलखेती", english: "", value: "flower" }
	// ],
	item_title: [
		{
			nepali: "टि.भी",
			english: "T.V",
			value: "TV",
		},
		{
			nepali: "रेडियो",
			english: "Radio",
			value: "Radio",
		},
		{
			nepali: "टेलिफोन",
			english: "Telephone",
			value: "Telephone",
		},
		{
			nepali: "मोबाइल",
			english: "Mobile",
			value: "Mobile",
		},
		{
			nepali: "ल्यापटप",
			english: "Laptop",
			value: "Laptop",
		},
		{
			nepali: "कम्प्युटर",
			english: "Computer",
			value: "Computer",
		},
		{
			nepali: "मोटरसाइकल",
			english: "Motor Cycle",
			value: "Motorcycle",
		},
		{
			nepali: "स्कुटर",
			english: "Scooter",
			value: "Scooter",
		},
		{
			nepali: "कार",
			english: "Car",
			value: "Car",
		},
		{
			nepali: "जिप",
			english: "Jeep",
			value: "Jeep",
		},
		{
			nepali: "ट्राकटर",
			english: "Tractor",
			value: "Tractor",
		},
		{
			nepali: "ट्रक",
			english: "Truck",
			value: "Truck",
		},
		{
			nepali: "अटो",
			english: "Auto",
			value: "Auto",
		},
		{
			nepali: "टेम्पो",
			english: "Tempo",
			value: "Tempo",
		},
		{
			nepali: "डोज़र",
			english: "Dozar",
			value: "Dozar",
		},
		{
			nepali: "टिप्पर",
			english: "Tipper",
			value: "Tipper",
		},
		{
			nepali: "स्काभेटर",
			english: "Skavater",
			value: "Skavater",
		},
	],

	answererRelation: answererRelation,
	caste: casteList,
	ethnics: [
		{
			nepali: "राई",
			english: "Rai",
			value: "rai",
		},
		{
			nepali: "मगर",
			english: "Magar",
			value: "magar",
		},
		{
			nepali: "शेर्पा",
			english: "Sherpa",
			value: "sherpa",
		},
		{
			nepali: "गुरुङ",
			english: "Gurung",
			value: "gurung",
		},
		{
			nepali: "तामाङ्ग",
			english: "Tamang",
			value: "tamang",
		},
		{
			nepali: "नेवार",
			english: "Newar",
			value: "newar",
		},
		{
			nepali: "थकाली",
			english: "Thakali",
			value: "thakali",
		},
		{
			nepali: "लिम्बु",
			english: "Limbu",
			value: "limbu",
		},
		{
			nepali: "भुजेल",
			english: "Bhujel",
			value: "bhujel",
		},
		{
			nepali: "घले",
			english: "ghale",
			value: "ghale",
		},
		// {
		// 	nepali: "गिरि",
		// 	english: "Giri",
		// 	value: "giri"
		// },
		// {
		// 	nepali: "पुरी",
		// 	english: "Puri",
		// 	value: "puri"
		// },
		// {
		// 	nepali: "भारती",
		// 	english: "Bharati",
		// 	value: "bharati"
		// },
		// {
		// 	nepali: "सन्यासी/दशनामी",
		// 	english: "Sansayi",
		// 	value: "sansayi"
		// },
		// {
		// 	nepali: "योगी",
		// 	english: "Yogi",
		// 	value: "yogi"
		// },
		// {
		// 	nepali: "कुँवर",
		// 	english: "Kunwar",
		// 	value: "kunwar"
		// }
	],

	religion: religionList,
	educationLevel: educationList,
	// prePrimaryDetails: preprimaryList,
	primaryDetails: primaryList,
	secondaryDetails: secondaryList,
	levelOneJob: occupationList,
	levelTwoJob: jobList,
	// levelThreeJob: teacherList,
	healthCondition: healthconditionList,
	unhealthyDisease: unhealthy,
	insurance_hasInsurance: hasInsuranceList,
	insurance_insuranceType: insuranceType,
	condition: mentalcondition,
	disableType: mentalType,
	hasCard: mentalCardYesNo,
	cardType: mentalCardtype,
	motherTongue: mothertongue,
	bloodGroup_bloodType: bloodgroup,
	voterId: voterIdYesNo,
	martialStatus: marriage,
	livingType: livingTypeList,
	permanentLivingAddress: permanent,
	waterSource: drinkingwaterLists,
	distanceToWaterSource: drinkingwaterplaceLists,
	cookingSource: cookingresourceLists,
	dirtyWaterManagement: dirtyWaterManagement,
	waterPurification: waterPurification,
	electricSource: lightresourceLists,
	toilet_isToilet: isToilet,
	toilet_toiletNo: noToilet,
	toilet_toiletYes: toiletresourceLists,
	road: roadLists,
	wasteDisposal: wastemanagementLists,
	saltUsed: salttypeLists,
	bankAccount: bankAccount,
	illTreament: illTreament,
	welfare_title: bhattaLists,
	home_homeBusinessName: businessLists,
	// herb_herbBusinessName: herbLists,
	landUsedLiving: landUsedliving,
	houseType: housetype,
	roofType: rooftype,
	hasLandDocument: hasLanddocument,
	dhukutiKarobar: dhukutiKarobar,
	doHouseCriteriaFullfill: doHouseCriteriafullfill,
	hasHouseMapPass: hasHouseMappass,
	title: title,
	birthCertificate: birthCertificate,
	contraceptiveUse: contraceptiveUse,
	deliveryFromDoctor: deliveryFromdoctor,
	childDeath: childDeath,
	pregnanctCheck: pregnanctcheck,
	// childNutrition: childnutrition,
	childLabour: childlabour,
	childLabor: childlabor,
	earlyMarriage: earlymarriage,
	pregnantTetanus: pregnanttetanus,
	agricultureProductionSalesType_crops: agriCropTypeList,
	agricultureProductionSalesType_daal: agriDaalType,
	agricultureProductionSalesType_oilseed: agriOilType,
	agricultureProductionSalesType_vegetable: agriVegetableType,
	agricultureProductionSalesType_fruit: agriFruitType,
	agricultureProductionSalesType_cash_crops: cashCropType,
	detail_name: livestockType,
	foreignCountry: abroadCountryList,
	abroadReason: abroadReasonList,
};
export const tableTitles = [
	{ english: "S.N", nepali: "क्र.स" },
	{ english: "", nepali: "वडा नं", value: "wardNumber" },
	{ english: "", nepali: "घर नं", value: "houseNumber" },
	{ english: "", nepali: "नाम थर", value: "fullName" },
	{ english: "", nepali: "नाम थर", value: "answererName" },
	{ english: "", nepali: "लिङ्ग", value: "gender" },
	{ english: "", nepali: "उमेर", value: "age" },
	{ english: "", nepali: "घरमुलीसंगको नाता", value: "answererRelation" },
	{ english: "", nepali: "फोन नम्बर", value: "contactNumber" },
	{ nepali: "बसोबासको अवस्था", english: "Living Type", value: "livingType" },
	{
		nepali: "बार्षिक आम्दानी",
		english: "Annual Income",
		value: "nonAgriIncome",
	},
];

// Naya aako

export const agriLandOwner = [
	{
		english: "",
		nepali: "पुरुषको नाममा भएको",
		value: "1",
	},
	{
		english: "",
		nepali: "महिलाको नाममा भएको",
		value: "2",
	},
	{
		english: "",
		nepali: "पुरूष र महिलाको संयुक्त स्वामित्व भएको",
		value: "3",
	},
	{
		english: "",
		nepali: "आफै कमाएको",
		value: "4",
	},
	{
		english: "",
		nepali: "अरुलाई कमाउन दिएको",
		value: "5",
	},
	{
		english: "",
		nepali: "अरुको कमाएको",
		value: "6",
	},
	{
		english: "",
		nepali: "बाँझो राखेको",
		value: "7",
	},
];

export const isAgriLandType = [
	{
		english: "",
		nepali: "खेत",
		value: "farming",
	},
	{
		english: "",
		nepali: "पाखोबारी",
		value: "pakhobari",
	},
	{
		english: "",
		nepali: "बन (निजि)",
		value: "forest",
	},
	{
		english: "",
		nepali: "ऐलानी",
		value: "yelani",
	},
];

export const hasSichaiSubhidha = [
	{
		english: "",
		nepali: "छ",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैन",
		value: "no",
	},
];

export const previousCropType = [
	{
		english: "",
		nepali: "धान",
		value: "paddy",
	},
	{
		english: "",
		nepali: "मकै",
		value: "maize",
	},
	{
		english: "",
		nepali: "गहुँ",
		value: "gahu",
	},
	{
		english: "",
		nepali: "कोदो",
		value: "kodo",
	},
	{
		english: "",
		nepali: "आलु",
		value: "potato",
	},
	{
		english: "",
		nepali: "दलहन",
		value: "daal",
	},
	{
		english: "",
		nepali: "तेलहन",
		value: "oilseed",
	},
	{
		english: "",
		nepali: "तरकारी",
		value: "vegetable",
	},
	{
		english: "",
		nepali: "फलफुल",
		value: "fruits",
	},
	{
		english: "",
		nepali: "नगदे - बाली",
		value: "cash_crops",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "others",
	},
];

export const familyExpense = [
	{
		english: "",
		nepali: "खाद्यान्न खरिद",
		value: "1",
	},
	{
		english: "",
		nepali: "विद्यालय शिक्षा",
		value: "2",
	},
	{
		english: "",
		nepali: "स्वास्थ्य उपचार",
		value: "3",
	},
	{
		english: "",
		nepali: "लत्ताकपडा खरिद",
		value: "4",
	},
	{
		english: "",
		nepali: "सामाजिक चाडपर्व/धर्म संस्कार",
		value: "5",
	},
	{
		english: "",
		nepali: "कर, तिरो, सेवा शुल्क",
		value: "6",
	},

	{
		english: "",
		nepali: "पानी, विजुली, इन्धन",
		value: "7",
	},
	{
		english: "",
		nepali: "घडी रेडियो टेलिफोन आदि खरिद",
		value: "8",
	},
	{
		english: "",
		nepali: "सञ्चार खर्च",
		value: "9",
	},
	{
		english: "",
		nepali: "ऋण फिर्ता",
		value: "10",
	},
	{
		english: "",
		nepali: "ब्याज",
		value: "11",
	},
	{
		english: "",
		nepali: "लघु - उद्योग",
		value: "12",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "13",
	},
];

export const hasChildWorker = [
	{
		english: "",
		nepali: "छन्",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैनन्",
		value: "no",
	},
];

export const houseRoofFarm = [
	{
		english: "",
		nepali: "छ",
		value: "yes",
	},
	{
		english: "",
		nepali: "छैन",
		value: "no",
	},
];

export const roofFarmMal = [
	{
		english: "",
		nepali: "जैविक",
		value: "1",
	},
	{
		english: "",
		nepali: "रसयेनिक",
		value: "2",
	},
	{
		english: "",
		nepali: "कम्पोस्ट / गोवर",
		value: "3",
	},
	{
		english: "",
		nepali: "छासमिस",
		value: "4",
	},
];

export const ifDaura = [
	{
		english: "",
		nepali: "निजी वन",
		value: "1",
	},
	{
		english: "",
		nepali: "सामुदायिक वन",
		value: "2",
	},
	{
		english: "",
		nepali: "कबुलियती वन",
		value: "3",
	},
	{
		english: "",
		nepali: "सरकारी वन",
		value: "4",
	},
	{
		english: "",
		nepali: "अन्यः",
		value: "5",
	},
];

export const cookingStove = [
	{
		english: "",
		nepali: "अगेनो वा परम्परागत चुल्हो",
		value: "1",
	},
	{
		english: "",
		nepali: "धुवाँ रहित चुल्हो",
		value: "2",
	},
	{
		english: "",
		nepali: "भुसे चुल्हो",
		value: "3",
	},
	{
		english: "",
		nepali: "ग्याँस चुल्हो",
		value: "4",
	},
	{
		english: "",
		nepali: "विद्युतीय चुल्हो",
		value: "5",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "6",
	},
];

export const houseCommunication = [
	{
		english: "",
		nepali: "रेडियो",
		value: "radio",
	},
	{
		english: "",
		nepali: "टेलिभिजन",
		value: "television",
	},
	{
		english: "",
		nepali: "टेलिफोन/मोबाइल",
		value: "mobile",
	},
	{
		english: "",
		nepali: "इन्टरनेट",
		value: "internet",
	},
	{
		english: "",
		nepali: "फ्याक्स",
		value: "fax",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "other",
	},
];

export const homeCleaningStatus = [
	{
		english: "",
		nepali: "साबुन पानीले हात धुने",
		value: "1",
	},
	{
		english: "",
		nepali: "फोहर व्यवस्थापन खाल्डो",
		value: "2",
	},
	{
		english: "",
		nepali: "भाँडा सुकाउने ठाउँ",
		value: "3",
	},
	{
		english: "",
		nepali: "घर आगन सरसफाई",
		value: "4",
	},
	{
		english: "",
		nepali: "गाइ भैंसी वस्तुभाउ राख्ने छुट्टै गोठ भएको",
		value: "5",
	},
	{
		english: "",
		nepali: "घरको भित्री भित्ता लिपपोत गरिएको",
		value: "6",
	},
	{
		english: "",
		nepali: "घरको बाहिरी भित्ता लिपेको",
		value: "7",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "8",
	},
];

export const selfFoodingStatus = [
	{
		english: "",
		nepali: "३ महिनाभन्दा कम खान पुग्ने",
		value: "1",
	},
	{
		english: "",
		nepali: "४-६ महिना  खान पुग्ने",
		value: "2",
	},
	{
		english: "",
		nepali: "७-९ महिना  खान पुग्ने",
		value: "3",
	},
	{
		english: "",
		nepali: "१०-१२ महिना  खान पुग्ने",
		value: "4",
	},
	{
		english: "",
		nepali: "आफ्नो खेतबारीको उत्पादनबाट खान पुगेर बेचबिखन गर्ने",
		value: "5",
	},
];

export const ifFoodNotEnough = [
	{
		english: "",
		nepali: "ज्याला मजदुरी",
		value: "labour",
	},
	{
		english: "",
		nepali: "जागिर/पेन्सन",
		value: "salary",
	},
	{
		english: "",
		nepali: "वैदेशिक रोजगारी",
		value: "foreign_employment",
	},
	{
		english: "",
		nepali: "कृषि उपज बिक्री",
		value: "agriculture",
	},
	{
		english: "",
		nepali: "पशुपक्षी बिक्री",
		value: "livestock",
	},
	{
		english: "",
		nepali: "अधिया/साझे कमाएर",
		value: "shared_earning",
	},
	{
		english: "",
		nepali: "ऋण सापटी",
		value: "loan",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "other",
	},
];

export const migratedUser = [
	{
		nepali: "हो",
		english: "",
		value: "yes",
	},
	{
		nepali: "होइन",
		english: "",
		value: "no",
	},
];

export const livingManagement = [
	{
		nepali: "सुकुम्बासी",
		english: "",
		value: "1",
	},
	{
		nepali: "विस्थापित",
		english: "",
		value: "2",
	},
	{
		nepali: "आरक्ष - विस्थापित",
		english: "",
		value: "3",
	},
	{
		nepali: "कुनैपनि होईन",
		english: "",
		value: "4",
	},
];

export const migrationReason = [
	{
		english: "",
		nepali: "द्वन्द्व",
		value: "11",
	},
	{
		english: "",
		nepali: "आर्थिक उन्नती",
		value: "12",
	},
	{
		english: "",
		nepali: "सामाजिक",
		value: "13",
	},
	{
		english: "",
		nepali: "व्यपार",
		value: "14",
	},
	{
		english: "",
		nepali: "नोकरी",
		value: "15",
	},
	{
		english: "",
		nepali: "अध्ययन",
		value: "16",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "17",
	},
];

export const loanTakenInFamily = [
	{
		english: "",
		nepali: "छ",
		value: "1",
	},
	{
		english: "",
		nepali: "छैन",
		value: "2",
	},
];

export const familyLoanSource = [
	{
		english: "",
		nepali: "बैंक वित्तीय संस्थाबाट",
		value: "bank",
	},
	{
		english: "",
		nepali: "सहकारी",
		value: "cooperative",
	},
	{
		english: "",
		nepali: "अन्य कोष",
		value: "other_funds",
	},
	{
		english: "",
		nepali: "अन्य",
		value: "other",
	},
];

export const selectIkai = [
	{
		english: "",
		nepali: "विघा र कटठा र धुर",
		value: "1",
	},
	{
		english: "",
		nepali: "रोपनी र आना र पैसा",
		value: "2",
	},
];

export const nowLivingAgri = [
	{
		english: "",
		nepali: "घर मात्र",
		value: "1",
	},
	{
		english: "",
		nepali: "खेति मात्र",
		value: "2",
	},
	{
		english: "",
		nepali: "घर खेत दुवै",
		value: "3",
	},
];
