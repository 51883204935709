import React, { useEffect, useState } from "react";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";
import {
	isAgriLandQn,
	isAgriLand,
	isAgriLandTypeQn,
	isAgriLandType,
	agriLandOwnerQn,
	agriLandOwner,
	jaggaMapIkaiQn,
	jaggaMapIkai,
	howMuchKhetQn,
	howMuchBanQn,
	howMuchPokhariQn,
	howMuchAelaniQn,
	hasSichaiSubhidhaQn,
	hasSichaiSubhidha,
	sichaiSubhidhaTypeQn,
	sichaiSubhidhaType,
	sichaiKhetQn,
	sichaiKhet,
	sichaiPokhariQn,
	sichaiPokhari,
	previousCropTypeQn,
	previousCropType,
	DhanQn,
	MakaiQn,
	GahuQn,
	KodoQn,
	AaluQn,
	DhalhanQn,
	TelhanQn,
	TarkariQn,
	FalfulQn,
	productionQntity,
	UkhuQn,
	ukhuQntity,
	SanpatQn,
	sanpatQntity,
	alaichiQn,
	alaichQntity,
	otherQn,
	annaBaliBikriQn,
	annaBaliBikri,
	salesQntity,
	khetMaleWorkerQn,
	khetFemaleWorkerQn,
	isAnimalRearingQn,
	isAnimalRearing,
	cowQn,
	buffaloQn,
	horseQn,
	sheepQn,
	pigQn,
	rabbitQn,
	duckQn,
	chickenQn,
	fishQn,
	beeQn,
	producedAnimalProductQn,
	producedAnimalProduct,
	milkQn,
	meatQn,
	woolQn,
	honeyQn,
	eggQn,
} from "../data/newHouseSurvey";

const AgriAnimal = (props) => {
	useEffect(() => {}, []);
	console.log("form props", props);
	const { formik } = props;
	const { agriLivestock } = formik.values;

	return (
		<>
			<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<div class="col-xl-12 col-xxl-8">
					<div
						class="pb-5"
						data-wizard-type="step-content"
						data-wizard-state="current"
					>
						<h3 class="mb-10 font-weight-bold text-dark">कृषी तथा पशु</h3>
						<div className="row">
							<div className="col-lg-12">
								<div class="form-group">
									<label>{isAgriLandQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{isAgriLand.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`agriLivestock.hasAgricultureLand`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === agriLivestock.hasAgricultureLand
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>

							{agriLivestock[`hasAgricultureLand`] === "1" && (
								<>
									<div className="col-lg-6">
										<div class="form-group">
											<label>{isAgriLandTypeQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={agriLivestock[`landType`]}
												isMulti={true}
												list={isAgriLandType}
												field={`agriLivestock.landType`}
											/>
										</div>
									</div>
									{agriLivestock[`landType`]?.includes("farming") && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{howMuchKhetQn[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name="agriLivestock.khetArea"
													onChange={formik.handleChange}
													value={agriLivestock.khetArea}
												/>
											</div>
										</div>
									)}
									{agriLivestock[`landType`]?.includes("pakhobari") && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{howMuchPokhariQn[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name="agriLivestock.pakhobariArea"
													onChange={formik.handleChange}
													value={agriLivestock.pakhobariArea}
												/>
											</div>
										</div>
									)}
									{agriLivestock[`landType`]?.includes("forest") && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{howMuchBanQn[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name="agriLivestock.forestArea"
													onChange={formik.handleChange}
													value={agriLivestock.forestArea}
												/>
											</div>
										</div>
									)}
									{agriLivestock[`landType`]?.includes("yelani") && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{howMuchAelaniQn[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name="agriLivestock.yelaniArea"
													onChange={formik.handleChange}
													value={agriLivestock.yelaniArea}
												/>
											</div>
										</div>
									)}

									<div className="col-lg-6">
										<div class="form-group">
											<label>{agriLandOwnerQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={agriLivestock[`landOwnership`]}
												isMulti={true}
												list={agriLandOwner}
												field={`agriLivestock.landOwnership`}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{jaggaMapIkaiQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="landMeasurementType"
												name={`agriLivestock.landMeasurementType`}
												value={agriLivestock[`landMeasurementType`]}
												formik={formik}
												selectList={jaggaMapIkai}
											/>
										</div>
									</div>

									<div className="col-lg-12">
										<h3 class="mb-10 font-weight-bold text-dark">सिचाई</h3>
									</div>

									<div className="col-lg-12">
										<div class="form-group">
											<label>{hasSichaiSubhidhaQn[selectedLanguage]}</label>
											<div class="radio-inline">
												{hasSichaiSubhidha.map((val, i) => {
													return (
														<label class="radio radio-rounded">
															<input
																type="radio"
																name={`agriLivestock.irrigationHasFacility`}
																value={val.value}
																onChange={formik.handleChange}
																defaultChecked={
																	val.value ===
																	agriLivestock[`irrigationHasFacility`]
																}
															/>
															<span></span>
															{val[selectedLanguage]}
														</label>
													);
												})}
											</div>
										</div>
									</div>

									{agriLivestock[`irrigationHasFacility`] == "yes" && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{sichaiSubhidhaTypeQn[selectedLanguage]}</label>
												<SingleSelectInput
													id="irrigationFacilityType"
													name={`agriLivestock.irrigationFacilityType`}
													value={agriLivestock[`irrigationFacilityType`]}
													formik={formik}
													selectList={sichaiSubhidhaType}
												/>
											</div>
										</div>
									)}

									<div className="col-lg-6">
										<div class="form-group">
											<label>{sichaiKhetQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="irrigationFarm"
												name={`agriLivestock.irrigationFarm`}
												value={agriLivestock[`irrigationFarm`]}
												formik={formik}
												selectList={sichaiKhet}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{sichaiPokhariQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="irrigationPakhobari"
												name={`agriLivestock.irrigationPakhobari`}
												value={agriLivestock[`irrigationPakhobari`]}
												formik={formik}
												selectList={sichaiPokhari}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{previousCropTypeQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={agriLivestock[`cropsType`]}
												isMulti={true}
												list={previousCropType}
												field={`agriLivestock.cropsType`}
											/>
										</div>
									</div>

									<div className="col-lg-12">
										<h3 class="mb-10 font-weight-bold text-dark">
											गत वर्षको विभिन्न बाली उत्पादन (स्थानीय परिमाणलाई के.जी.
											मा रुपान्तर गरेर)
										</h3>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{DhanQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="dhanProduction"
												name={`agriLivestock.dhanProduction`}
												value={agriLivestock[`dhanProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{MakaiQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="maizeProduction"
												name={`agriLivestock.maizeProduction`}
												value={agriLivestock[`maizeProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{GahuQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="gahuProduction"
												name={`agriLivestock.gahuProduction`}
												value={agriLivestock[`gahuProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{KodoQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="kodoProduction"
												name={`agriLivestock.kodoProduction`}
												value={agriLivestock[`kodoProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{AaluQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="potatoProduction"
												name={`agriLivestock.potatoProduction`}
												value={agriLivestock[`potatoProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{DhalhanQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="daalProduction"
												name={`agriLivestock.daalProduction`}
												value={agriLivestock[`daalProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{TelhanQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="oilseedProduction"
												name={`agriLivestock.oilseedProduction`}
												value={agriLivestock[`oilseedProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{TarkariQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="vegetableProduction"
												name={`agriLivestock.vegetableProduction`}
												value={agriLivestock[`vegetableProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{FalfulQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="fruitsProduction"
												name={`agriLivestock.fruitsProduction`}
												value={agriLivestock[`fruitsProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{UkhuQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="sugarcaneProduction"
												name={`agriLivestock.sugarcaneProduction`}
												value={agriLivestock[`sugarcaneProduction`]}
												formik={formik}
												selectList={ukhuQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{SanpatQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="sanpatProduction"
												name={`agriLivestock.sanpatProduction`}
												value={agriLivestock[`sanpatProduction`]}
												formik={formik}
												selectList={sanpatQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{alaichiQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="alaichiProduction"
												name={`agriLivestock.alaichiProduction`}
												value={agriLivestock[`alaichiProduction`]}
												formik={formik}
												selectList={alaichQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{otherQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="othersProduction"
												name={`agriLivestock.othersProduction`}
												value={agriLivestock[`othersProduction`]}
												formik={formik}
												selectList={productionQntity}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{annaBaliBikriQn[selectedLanguage]}</label>
											<div class="radio-inline">
												{annaBaliBikri.map((val, i) => {
													return (
														<label class="radio radio-rounded">
															<input
																type="radio"
																name={`agriLivestock.hasSales`}
																value={val.value}
																onChange={formik.handleChange}
																defaultChecked={
																	val.value === agriLivestock.hasSales
																}
															/>
															<span></span>
															{val[selectedLanguage]}
														</label>
													);
												})}
											</div>
										</div>
									</div>
									{agriLivestock[`hasSales`] === "1" && (
										<>
											<div className="col-lg-6">
												<div class="form-group">
													<label>{DhanQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="dhanSales"
														name={`agriLivestock.dhanSales`}
														value={agriLivestock[`dhanSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{MakaiQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="maizeSales"
														name={`agriLivestock.maizeSales`}
														value={agriLivestock[`maizeSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{GahuQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="gahuSales"
														name={`agriLivestock.gahuSales`}
														value={agriLivestock[`gahuSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{KodoQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="kodoSales"
														name={`agriLivestock.kodoSales`}
														value={agriLivestock[`kodoSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{AaluQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="potatoSales"
														name={`agriLivestock.potatoSales`}
														value={agriLivestock[`potatoSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{DhalhanQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="daalSales"
														name={`agriLivestock.daalSales`}
														value={agriLivestock[`daalSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{TelhanQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="oilseedSales"
														name={`agriLivestock.oilseedSales`}
														value={agriLivestock[`oilseedSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											{/* <div className="col-lg-6">
                        <div class="form-group">
                          <label>{TarkariQn[selectedLanguage]}</label>
                          <SingleSelectInput
                            id="salesTarkari"
                            name={`agriLivestock.salesTarkari`}
                            value={agriLivestock[`salesTarkari`]}
                            formik={formik}
                            selectList={salesQntity}
                          />
                        </div>
                      </div> */}

											<div className="col-lg-6">
												<div class="form-group">
													<label>{FalfulQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="fruitsSales"
														name={`agriLivestock.fruitsSales`}
														value={agriLivestock[`fruitsSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{UkhuQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="sugarcaneSales"
														name={`agriLivestock.sugarcaneSales`}
														value={agriLivestock[`sugarcaneSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{SanpatQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="sanpatSales"
														name={`agriLivestock.sanpatSales`}
														value={agriLivestock[`sanpatSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{alaichiQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="alaichiSales"
														name={`agriLivestock.alaichiSales`}
														value={agriLivestock[`alaichiSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>{otherQn[selectedLanguage]}</label>
													<SingleSelectInput
														id="othersSales"
														name={`agriLivestock.othersSales`}
														value={agriLivestock[`othersSales`]}
														formik={formik}
														selectList={salesQntity}
													/>
												</div>
											</div>
										</>
									)}

									<div className="col-lg-6">
										<div class="form-group">
											<label>{khetMaleWorkerQn[selectedLanguage]}</label>
											<input
												type="text"
												class="form-control"
												name="agriLivestock.manpowerMale"
												onChange={formik.handleChange}
												value={agriLivestock.manpowerMale}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{khetFemaleWorkerQn[selectedLanguage]}</label>
											<input
												type="text"
												class="form-control"
												name="agriLivestock.manpowerFemale"
												onChange={formik.handleChange}
												value={agriLivestock.manpowerFemale}
											/>
										</div>
									</div>
								</>
							)}

							<div className="col-lg-12">
								<div class="form-group">
									<label>{isAnimalRearingQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{isAnimalRearing.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`agriLivestock.hasAnimal`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === agriLivestock.hasAnimal
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>
							{agriLivestock[`hasAnimal`] === "yes" && (
								<>
									<div className="col-lg-6">
										<div class="form-group">
											<label>{cowQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.cowCount"
												onChange={formik.handleChange}
												value={agriLivestock.cowCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{buffaloQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.buffaloCount"
												onChange={formik.handleChange}
												value={agriLivestock.buffaloCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{horseQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.horseCount"
												onChange={formik.handleChange}
												value={agriLivestock.horseCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{sheepQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.goatCount"
												onChange={formik.handleChange}
												value={agriLivestock.goatCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{pigQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.pigCount"
												onChange={formik.handleChange}
												value={agriLivestock.pigCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{rabbitQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.rabbitCount"
												onChange={formik.handleChange}
												value={agriLivestock.rabbitCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{duckQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.duckCount"
												onChange={formik.handleChange}
												value={agriLivestock.duckCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{chickenQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.chickenCount"
												onChange={formik.handleChange}
												value={agriLivestock.chickenCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{fishQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.fishPondCount"
												onChange={formik.handleChange}
												value={agriLivestock.fishPondCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{beeQn[selectedLanguage]}</label>
											<input
												type="number"
												class="form-control"
												name="agriLivestock.beeHiveCount"
												onChange={formik.handleChange}
												value={agriLivestock.beeHiveCount}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{producedAnimalProductQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={agriLivestock[`productionType`]}
												isMulti={true}
												list={producedAnimalProduct}
												field={`agriLivestock.productionType`}
											/>
										</div>
									</div>

									{agriLivestock[`productionType`]?.includes("milk") && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{milkQn[selectedLanguage]}</label>
												<input
													type="number"
													class="form-control"
													name="agriLivestock.milkUnit"
													onChange={formik.handleChange}
													value={agriLivestock.milkUnit}
												/>
											</div>
										</div>
									)}
									{agriLivestock[`productionType`]?.includes("meat") && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{meatQn[selectedLanguage]}</label>
												<input
													type="number"
													class="form-control"
													name="agriLivestock.meatUnit"
													onChange={formik.handleChange}
													value={agriLivestock.meatUnit}
												/>
											</div>
										</div>
									)}
									{agriLivestock[`productionType`]?.includes("wool") && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{woolQn[selectedLanguage]}</label>
												<input
													type="number"
													class="form-control"
													name="agriLivestock.woolUnit"
													onChange={formik.handleChange}
													value={agriLivestock.woolUnit}
												/>
											</div>
										</div>
									)}
									{agriLivestock[`animalProduction.productionType`]?.includes(
										"honey"
									) && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{honeyQn[selectedLanguage]}</label>
												<input
													type="number"
													class="form-control"
													name="agriLivestock.honeyUnit"
													onChange={formik.handleChange}
													value={agriLivestock.honeyUnit}
												/>
											</div>
										</div>
									)}
									{agriLivestock[`productionType`]?.includes("egg") && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{eggQn[selectedLanguage]}</label>
												<input
													type="number"
													class="form-control"
													name="agriLivestock.eggUnit"
													onChange={formik.handleChange}
													value={agriLivestock.eggUnit}
												/>
											</div>
										</div>
									)}
								</>
							)}

							{/*  */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default AgriAnimal;
