import React from "react";
import {
	doHouseCriteriaFullfill,
	doHouseCriteriaFullfillQuestion,
	familyRiskPlan,
	familyRiskPlanQn,
	familyRiskPlanYesQn,
	hasLandDocument,
	hasLandDocumentQuestion,
	houseAge,
	houseCount,
	houseFoundation,
	houseFoundationQn,
	houseMapPass,
	houseType,
	houseTypeQuestion,
	houseUsedLiving,
	houseUsedLivingOption,
	ifOtherValueQuestion,
	isHouseOnRent,
	isHouseOnRentQuestion,
	otherHouseLocation,
	otherHouseLocationQn,
	rentGivenTo,
	rentGivenToQuestion,
	riskAreaAroundHouse,
	riskAreaAroundHouseQn,
	roofType,
	roofTypeQuestion,
	totalRoom,
	totalStorey,
	womanOwnedHome,
	womanOwnedHomeQn,
} from "../data/houseSurvey";
import {
	userHasHousemapPassQuestion,
	userHasHousemapPass,
	totalFamilyOnRentQn,
	rentMaleQn,
	rentFemaleQn,
	migratedUserQn,
	migratedUser,
	livingManagementQn,
	livingManagement,
	migratedTotalPeopleQn,
	migratedTotalPeople,
	migratedMaleQn,
	migratedFemaleQn,
	migrationDateQn,
	migrationReasonQn,
	migrationReason,
	previousLocationQn,
	nameFatherQn,
	nameGrandFatherQn,
	landFamilyMemberQn,
	whereAnyLandQn,
	selectIkaiQn,
	selectIkai,
	totalOwnedLand,
	whenBhogChalanQn,
	nowLivingAgriQn,
	nowLivingAgri,
	onWhoseNameQn,
	isNapiQn,
	isNapi,
	aelaniJaggaKisim,
	aelaniJaggaKisimQn,
	isconflictVictimQn,
	isconflictVictim,
	yesConflictVictimQn,
	conflictVictimDeathQn,
	conflictVictimInjured,
	conflictVictimPainQn,
	conflictVictimOtherQn,
	conflictVictim,
	houseStoreyQn,
	houseStorey,
} from "../data/newHouseSurvey";

import { TotalKittaOfLandQn, landFamilyMember } from "../data/demo";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";

const PrathamikGharForm = (props) => {
	const { formik } = props;
	const { houseDetail } = formik.values;
	return (
		<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
			<div class="col-xl-12 col-xxl-8">
				<div
					class="pb-5"
					data-wizard-type="step-content"
					data-wizard-state="current"
				>
					<h3 class="mb-10 font-weight-bold text-dark">प्रथामिक घर विवरण</h3>
					<div className="row">
						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseUsedLiving[selectedLanguage]}</label>
								<div class="radio-inline">
									{houseUsedLivingOption.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.houseUsedLiving`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`houseUsedLiving`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{houseDetail.houseUsedLiving == "other" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="houseDetail.houseUsedLivingOther"
										onChange={formik.handleChange}
										value={houseDetail.houseUsedLivingOther}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{userHasHousemapPassQuestion[selectedLanguage]}</label>
								<SingleSelectInput
									id="hasHouseMapPass"
									name={`houseDetail.hasHouseMapPass`}
									value={houseDetail[`hasHouseMapPass`]}
									formik={formik}
									selectList={userHasHousemapPass}
								/>
							</div>
						</div>

						<div className="col-lg-6">
							<div class="form-group">
								<label>{isHouseOnRentQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{isHouseOnRent.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.isHouseOnRent`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`isHouseOnRent`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{houseDetail.isHouseOnRent == "yes" && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{totalFamilyOnRentQn[selectedLanguage]}</label>
										<input
											type="number"
											class="form-control"
											name="houseDetail.totalFamily"
											onChange={formik.handleChange}
											value={houseDetail.totalFamily}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{rentMaleQn[selectedLanguage]}</label>
										<input
											type="number"
											class="form-control"
											name="houseDetail.maleCount"
											onChange={formik.handleChange}
											value={houseDetail.maleCount}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{rentFemaleQn[selectedLanguage]}</label>
										<input
											type="number"
											class="form-control"
											name="houseDetail.femaleCount"
											onChange={formik.handleChange}
											value={houseDetail.femaleCount}
										/>
									</div>
								</div>
							</>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{migratedUserQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{migratedUser.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.isMigrated`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`isMigrated`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{houseDetail[`isMigrated`] === "no" && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{livingManagementQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="notMigratedCondition"
											name={`houseDetail.notMigratedCondition`}
											value={houseDetail[`notMigratedCondition`]}
											formik={formik}
											selectList={livingManagement}
										/>
									</div>
								</div>
								{(houseDetail[`notMigratedCondition`] === "1" ||
									houseDetail[`notMigratedCondition`] === "2" ||
									houseDetail[`notMigratedCondition`] === "3") && (
									<>
										<div className="col-lg-6">
											<div class="form-group">
												<label>{nameFatherQn[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name="houseDetail.squatterFatherName"
													onChange={formik.handleChange}
													value={houseDetail.squatterFatherName}
												/>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>{nameGrandFatherQn[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name="houseDetail.squatterGrandFatherName"
													onChange={formik.handleChange}
													value={houseDetail.squatterGrandFatherName}
												/>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>{landFamilyMemberQn[selectedLanguage]}</label>
												<SingleSelectInput
													id="memberLand"
													name={`houseDetail.squatterMemberLand`}
													value={houseDetail[`squatterMemberLand`]}
													formik={formik}
													selectList={landFamilyMember}
												/>
											</div>
										</div>
										{(houseDetail[`memberLand`] === "2" ||
											houseDetail[`memberLand`] === "3" ||
											houseDetail[`memberLand`] === "4") && (
											<>
												<div className="col-lg-6">
													<div class="form-group">
														<label>{whereAnyLandQn[selectedLanguage]}</label>
														<input
															type="text"
															class="form-control"
															name="houseDetail.squatterLandDistrict"
															onChange={formik.handleChange}
															value={houseDetail.squatterLandDistrict}
														/>
													</div>
												</div>
												<div className="col-lg-6">
													<div class="form-group">
														<label>{selectIkaiQn[selectedLanguage]}</label>
														<div class="radio-inline">
															{selectIkai.map((value, i) => (
																<label class="radio radio-rounded">
																	<input
																		type="radio"
																		name={`houseDetail.squatterDetailUnit`}
																		value={value.value}
																		onChange={formik.handleChange}
																		defaultChecked={
																			houseDetail[`squatterDetailUnit`] ==
																			value.value
																		}
																	/>
																	<span></span>
																	{value[selectedLanguage]}
																</label>
															))}
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div class="form-group">
														<label>{totalOwnedLand[selectedLanguage]}</label>
														<input
															type="text"
															class="form-control"
															name="houseDetail.squatterTotalLand"
															onChange={formik.handleChange}
															value={houseDetail.squatterTotalLand}
														/>
													</div>
												</div>
												<div className="col-lg-6">
													<div class="form-group">
														<label>{whenBhogChalanQn[selectedLanguage]}</label>
														<input
															type="number"
															class="form-control"
															name="houseDetail.squatterIndulgenceYear"
															onChange={formik.handleChange}
															value={houseDetail.squatterIndulgenceYear}
														/>
													</div>
												</div>
											</>
										)}
										<div className="col-lg-6">
											<div class="form-group">
												<label>{nowLivingAgriQn[selectedLanguage]}</label>
												<SingleSelectInput
													id="livingCondition"
													name={`houseDetail.squatterMoreLivingCondition`}
													value={houseDetail[`squatterMoreLivingCondition`]}
													formik={formik}
													selectList={nowLivingAgri}
												/>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>{whenBhogChalanQn[selectedLanguage]}</label>
												<input
													type="number"
													class="form-control"
													name="houseDetail.squatterMoreIndulgenceYear"
													onChange={formik.handleChange}
													value={houseDetail.squatterMoreIndulgenceYear}
												/>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>{onWhoseNameQn[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name="houseDetail.squatterMoreOwnerShip"
													onChange={formik.handleChange}
													value={houseDetail.squatterMoreOwnerShip}
												/>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>{TotalKittaOfLandQn[selectedLanguage]}</label>
												<input
													type="number"
													class="form-control"
													name="houseDetail.squatterMoreKittaNumber"
													onChange={formik.handleChange}
													value={houseDetail.squatterMoreKittaNumber}
												/>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>{isNapiQn[selectedLanguage]}</label>
												<div class="radio-inline">
													{isNapi.map((value, i) => (
														<label class="radio radio-rounded">
															<input
																type="radio"
																name={`houseDetail.squatterMoreLandMeasurement`}
																value={value.value}
																onChange={formik.handleChange}
																defaultChecked={
																	houseDetail[`squatterMoreLandMeasurement`] ==
																	value.value
																}
															/>
															<span></span>
															{value[selectedLanguage]}
														</label>
													))}
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>{aelaniJaggaKisimQn[selectedLanguage]}</label>
												<SingleSelectInput
													id="yelaniType"
													name={`houseDetail.squatterMoreYelaniType`}
													value={houseDetail[`squatterMoreYelaniType`]}
													formik={formik}
													selectList={aelaniJaggaKisim}
												/>
											</div>
										</div>
									</>
								)}
							</>
						)}
						{houseDetail[`isMigrated`] === "yes" && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{migratedTotalPeopleQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="totalMember"
											name={`houseDetail.migratedTotalMember`}
											value={houseDetail[`migratedTotalMember`]}
											formik={formik}
											selectList={migratedTotalPeople}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{migratedMaleQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="migratedDetail.male"
											name={`houseDetail.migratedMale`}
											value={houseDetail[`migratedMale`]}
											formik={formik}
											selectList={migratedTotalPeople}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{migratedFemaleQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="migratedDetail.female"
											name={`houseDetail.migratedFemale`}
											value={houseDetail[`migratedFemale`]}
											formik={formik}
											selectList={migratedTotalPeople}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{migrationDateQn[selectedLanguage]}</label>
										<input
											type="date"
											class="form-control"
											name="houseDetail.migratedDate"
											onChange={formik.handleChange}
											value={houseDetail.migratedDate}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{migrationReasonQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="migrationReason"
											name={`houseDetail.migratedDetailReason`}
											value={houseDetail[`migratedDetailReason`]}
											formik={formik}
											selectList={migrationReason}
										/>
									</div>
								</div>
								{houseDetail[`migratedDetailReason`] === "17" && (
									<div className="col-lg-6">
										<div class="form-group">
											<label>यदी अन्य भय ?</label>
											<input
												type="text"
												class="form-control"
												name="houseDetail.migratedDetailReasonOther"
												onChange={formik.handleChange}
												value={houseDetail.migratedDetailReasonOther}
											/>
										</div>
									</div>
								)}
								<div className="col-lg-6">
									<div class="form-group">
										<label>{previousLocationQn[selectedLanguage]}</label>
										<input
											type="text"
											class="form-control"
											name="houseDetail.migratedPreviousLocation"
											onChange={formik.handleChange}
											value={houseDetail.migratedPreviousLocation}
										/>
									</div>
								</div>
							</>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{isconflictVictimQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{isconflictVictim.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.effectFromProtest`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`effectFromProtest`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>

						{houseDetail[`effectFromProtest`] === "yes" && (
							<>
								<div className="col-lg-12">
									<h3 class="mb-10 font-weight-bold text-dark">
										{yesConflictVictimQn[selectedLanguage]}
									</h3>
								</div>

								<div className="col-lg-6">
									<div class="form-group">
										<label>{conflictVictimDeathQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="protestDetailDeath"
											name={`houseDetail.protestDetailDeath`}
											value={houseDetail[`protestDetailDeath`]}
											formik={formik}
											selectList={conflictVictim}
										/>
									</div>
								</div>

								<div className="col-lg-6">
									<div class="form-group">
										<label>{conflictVictimInjured[selectedLanguage]}</label>
										<SingleSelectInput
											id="protestDetailInjured"
											name={`houseDetail.protestDetailInjured`}
											value={houseDetail[`protestDetailInjured`]}
											formik={formik}
											selectList={conflictVictim}
										/>
									</div>
								</div>

								<div className="col-lg-6">
									<div class="form-group">
										<label>{conflictVictimPainQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="protestDetailDisable"
											name={`houseDetail.protestDetailDisable`}
											value={houseDetail[`protestDetailDisable`]}
											formik={formik}
											selectList={conflictVictim}
										/>
									</div>
								</div>

								<div className="col-lg-6">
									<div class="form-group">
										<label>{conflictVictimOtherQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="protestDetailOthers"
											name={`houseDetail.protestDetailOthers`}
											value={houseDetail[`protestDetailOthers`]}
											formik={formik}
											selectList={conflictVictim}
										/>
									</div>
								</div>
							</>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{roofTypeQuestion[selectedLanguage]}</label>
								<CustomSelectMulti
									value={houseDetail.roofType}
									isMulti={true}
									list={roofType}
									formik={formik}
									field={"houseDetail.roofType"}
								/>
							</div>
						</div>

						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseStoreyQn[selectedLanguage]}</label>
								<SingleSelectInput
									id="totalStorey"
									name={`houseDetail.totalStorey`}
									value={houseDetail[`totalStorey`]}
									formik={formik}
									selectList={conflictVictim}
								/>
							</div>
						</div>

						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseAge[selectedLanguage]}</label>
								<input
									type="number"
									class="form-control"
									name="houseDetail.houseAge"
									onChange={formik.handleChange}
									value={houseDetail.houseAge}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrathamikGharForm;
