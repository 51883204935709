import React from 'react'
import { Card, Table } from 'react-bootstrap';
import { deathCertificate, deathReason } from '../../data/houseSurvey';
import { selectedLanguage } from '../../data/institute';

const DeathDetail = ({ death }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    मृत्यु सम्बन्धी
                </Card.Title>

                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>नाम थर</th>
                            <th>लिङ</th>
                            <th>उमेर</th>
                            <th>मृत्यु कारण</th>
                            <th>मृत्यु दर्ता</th>
                            {/* <th>सम्पर्क नम्बर</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            death.map((person, i) => (
                                <>

                                    <tr
                                    >
                                        <td>{person.deathName}</td>
                                        <td>{person.deathGender}</td>
                                        <td>{person.deathAge}</td>
                                        <td>{deathReason.find(i => i.value === person.deathReason) ? deathReason.find(i => i.value === person.deathReason)[selectedLanguage] : ""}</td>
                                        <td>{deathCertificate.find(i => i.value === person.deathCertificate) ? deathCertificate.find(i => i.value === person.deathCertificate)[selectedLanguage] : ""}</td>
                                    </tr>
                                </>
                            ))
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export default DeathDetail;