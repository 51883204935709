import React, { useState } from 'react'
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import GharKoParichaya from '../components/HouseDetailSections/GharKoParichaya';
import NaksaBibaran from '../components/HouseDetailSections/NaksaBibaran';
import PariwarSadasya from '../components/HouseDetailSections/PariwarSadasya';
import { getHouseDetailsById } from '../modules/HomeSurvey/homeSurveyCrud';
import "bootstrap/js/src/collapse.js";
import EducationDetails from '../components/HouseDetailSections/EducationDetails';
import FamilyResourceUsage from '../components/HouseDetailSections/FamilyResourceUsage';
import GharJagga from '../components/HouseDetailSections/GharJagga';
import AarthikKshetraJankari from '../components/HouseDetailSections/AarthikKhsetraJankari';
import HouseFacility from '../components/HouseDetailSections/HouseFacility';
import DeathDetail from '../components/HouseDetailSections/DeathDetail';
import AgricultureDetail from '../components/HouseDetailSections/AgricultureDetail';
import AnimalDetail from '../components/HouseDetailSections/AnimalDetail';
import BeeFishSilkDetail from '../components/HouseDetailSections/BeeFishSilkDetail';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
const HomeSurveyDetail = (props) => {
    const [houseDetail, setHouseDetail] = useState(undefined)
    const [loading, setLoading] = useState(true)

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`
    });
    useEffect(() => {
        if (props.houseDetail) {
            setLoading(false)
            setHouseDetail(props.houseDetail)
        }
        else {
            const houseId = props.match.params.id;
            getHouseDetailsById(houseId).then((res) => {
                if (res.data) {
                    const data = res.data;
                    console.log(data)
                    setHouseDetail(data)
                }
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            })
        }
    }, [])
    return (
        <>
            {
                loading && (
                    <>
                        <Card style={{ height: "80vh" }}>
                        </Card>
                    </>
                )
            }
            {
                !loading && houseDetail && (
                    
                    <>
                        <div className="d-flex justify-content-end">
                            <button className="float-right btn btn-primary mb-4" onClick={handlePrint}><i className="flaticon2-printer"></i> प्रिन्ट गर्नुहोस </button>

                        </div>
                        <div className="all-house-detail" ref={componentRef}>
                            <GharKoParichaya houseDetails={houseDetail.houseDetails} />
                            <PariwarSadasya member={houseDetail.member} />
                            <div className="row mb-5 align-items-stretch">
                                <div className="col-lg-6 mb-5">
                                    <EducationDetails member={houseDetail.member} />

                                </div>
                                {houseDetail.secondaryHouseDetails && (<div className="col-lg-6 mb-5">
                                    <FamilyResourceUsage secondaryHouseDetails={houseDetail.secondaryHouseDetails} />

                                </div>)}
                                {houseDetail.houseLand && (<div className="col-lg-6 mb-5">
                                    <GharJagga houseLand={houseDetail.houseLand} />

                                </div>)}
                                {houseDetail.agriculture.hasAgricultureLand && (<div className="col-lg-6 mb-5">
                                    <AgricultureDetail agriculture={houseDetail.agriculture} />

                                </div>)}
                                {houseDetail.livestock.hasAnimal && (<div className="col-lg-6 mb-5">
                                    <AnimalDetail livestock={houseDetail.livestock} />

                                </div>)}
                                {houseDetail.beefishsilk.fishBeeSilk && (<div className="col-lg-6 mb-5">
                                    <BeeFishSilkDetail beefishsilk={houseDetail.beefishsilk} />

                                </div>)}
                                {houseDetail.economics && (<div className="col-lg-6 mb-5">
                                    <AarthikKshetraJankari economics={houseDetail.economics} />

                                </div>)}
                                {houseDetail.houseFacility && (<div className="col-lg-6 mb-5">
                                    <HouseFacility houseFacility={houseDetail.houseFacility} />

                                </div>)}
                                {houseDetail.death.anyDeath && (<div className="col-lg-6 mb-5">
                                    <DeathDetail death={houseDetail.death.detail} />

                                </div>)}
                            </div>
                            <NaksaBibaran houseDetails={houseDetail.houseDetails} />

                        </div>
                    </>
                )
            }
        </>
    );
}

export default HomeSurveyDetail;