import React from "react";
import { Card, Table } from "react-bootstrap";
import {
  answererRelation,
  bloodgroup,
  casteList,
  childVaccination,
  occupation,
  gender,
  healthcondition,
  healthconditionQuesiton,
  marriage,
  mothertongue,
  religionList,
  socialSecurityAllowanceDetail,
  socialSecurityAllowanceList,
  unhealthy,
  votercard,
} from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const PariwarSadasya = ({ member }) => {
  return (
    <Card className="mb-5">
      <Card.Body>
        <Card.Title>परिवार सदस्य व्यक्तिगत विवरण</Card.Title>
        <Table bordered hover>
          <thead>
            <tr>
              <th>पुरा नाम</th>
              <th>लिंग</th>
              <th>उमेर</th>
              <th>जात</th>
              <th>धर्म</th>
              <th>मातृभाषा</th>
              <th>अपांगता</th>
              <th>पेशा</th>
              <th>कुपोषण</th>
              <th>बैबाहिक स्तिथि</th>
              <th>बच्‍चाहरुको आधारभूत खोपको अवस्था</th>
              <th>व्यवसायिक तालिम</th>
              <th>जनचेतनामूलक तालिम</th>
              {/* <th>सम्पर्क नम्बर</th> */}
            </tr>
          </thead>
          <tbody>
            {member.map((familyMember, i) => (
              <>
                <tr
                  data-toggle="collapse"
                  data-target={`.multi-collapse${i}`}
                  aria-controls={`collapsible${i}`}
                >
                  <td>
                    <img
                      className="mr-2"
                      src={toAbsoluteUrl(
                        "/media/svg/hamropalika-icons/caret.svg"
                      )}
                      alt=""
                    />
                    {familyMember.detail.fullName}
                  </td>
                  <td>
                    {gender.find((i) => i.value === familyMember.detail.gender)
                      ? gender.find(
                          (i) => i.value === familyMember.detail.gender
                        )[selectedLanguage]
                      : ""}
                  </td>
                  <td>{familyMember.detail.age}</td>
                  <td>
                    {casteList.find(
                      (i) => i.value === familyMember.detail.caste
                    )
                      ? casteList.find(
                          (i) => i.value === familyMember.detail.caste
                        )[selectedLanguage]
                      : ""}
                  </td>
                  <td>
                    {religionList.find(
                      (i) => i.value === familyMember.detail.religion
                    )
                      ? religionList.find(
                          (i) => i.value === familyMember.detail.religion
                        )[selectedLanguage]
                      : ""}
                  </td>
                  <td>
                    {mothertongue.find(
                      (i) => i.value === familyMember.detail.motherTongue
                    )
                      ? mothertongue.find(
                          (i) => i.value === familyMember.detail.motherTongue
                        )[selectedLanguage]
                      : ""}
                  </td>
                  <td>{familyMember.disable.isDisable ? "छ" : "छैन"}</td>
                  <td>
                    {familyMember.occupation.levelOneJob?.map((job) => {
                      return (
                        <>
                          {occupation.find((i) => i.value === job)
                            ? occupation.find((i) => i.value === job)[selectedLanguage]
                            : ""}
                        </>
                      );
                    })}
                  </td>
                  <td>{familyMember.detail.malnutrition}</td>
                  <td>{familyMember.detail.isMArried ? "छ" : "छैन"}</td>
                  {/* <td>{marriage.find(i => i.value === familyMember.detail.martialStatus) ? marriage.find(i => i.value === familyMember.detail.martialStatus)[selectedLanguage] : ""}</td> */}
                  {/* <td>{familyMember.detail.bloodGroup.knowBloodGroup && bloodgroup.find(i => i.value === familyMember.detail.bloodGroup?.bloodType) ? bloodgroup.find(i => i.value === familyMember.detail.bloodGroup?.bloodType)[selectedLanguage] : ""}</td> */}
                  <td>
                  {familyMember.detail.childVaccination?.map((vaccine) => {
                      return (
                        <>
                          {childVaccination.find((i) => i.value === vaccine)
                            ? childVaccination.find((i) => i.value === vaccine)[selectedLanguage]
                            : ""}
                        </>
                      );
                    })}
                  </td>
                  <td>{familyMember.detail.skillTraining ? "छ" : "छैन"}</td>
                  <td>{familyMember.detail.hasPublicAwarenessTraining ? "छ" : "छैन"}</td>
                  {/* <td>{votercard.find(i => i.value === familyMember.detail.voterId) ? votercard.find(i => i.value === familyMember.detail.voterId)[selectedLanguage] : ""}</td> */}
                  {/* <td>सम्पर्क नम्बर</td> */}
                </tr>
                <tr
                  class={`collapse multi-collapse${i}`}
                  id={`collapsible${i}`}
                >
                  <td colSpan={11} style={{ textAlign: "left" }}>
                    {familyMember.detail.unhealthyDisease.length === 0 && (
                      <h6>
                        {healthconditionQuesiton[selectedLanguage]} :{" "}
                        {healthcondition.find(
                          (i) => i.value === familyMember.detail.healthCondition
                        )
                          ? healthcondition.find(
                              (i) =>
                                i.value === familyMember.detail.healthCondition
                            )[selectedLanguage]
                          : ""}
                      </h6>
                    )}
                    {familyMember.detail.unhealthyDisease.length !== 0 &&
                      familyMember.detail.unhealthyDisease.map((disease) => (
                        <>
                          {unhealthy.find((i) => i.value === disease)
                            ? unhealthy.find((i) => i.value === disease)[
                                selectedLanguage
                              ]
                            : ""}
                        </>
                      ))}
                    <h6>
                      {socialSecurityAllowanceDetail[selectedLanguage]} :{" "}
                      {socialSecurityAllowanceList.find(
                        (i) => i.value === familyMember.detail.socialAllowance
                      )
                        ? socialSecurityAllowanceList.find(
                            (i) =>
                              i.value === familyMember.detail.socialAllowance
                          )[selectedLanguage]
                        : ""}
                    </h6>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default PariwarSadasya;
