import React, { useEffect, useState } from "react";
import {
	anyDeath,
	deathAge,
	deathCertificate,
	deathCertificateQn,
	deathDiseaseList,
	deathGender,
	deathInYeargenderQuestion,
	deathInYearReasonQuestion,
	deathName,
	deathQuestion,
	deathReason,
	ifDiseaseDeathQn,
	ifOtherValueQuestion,
} from "../data/houseSurvey";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";

const MrityuBibaran = (props) => {
	const [memberCount, setMemberCount] = useState(0);
	const [deathData, setDeathData] = useState(false);
	const [repeaterForm, setRepeaterForm] = useState([]);
	const { formik } = props;
	const { death } = formik.values;
	const handleDeathYesNo = (e) => {
		formik.handleChange(e);
		console.log(e.target);
		setDeathData(e.target.value);
	};
	const handleDeathCountChange = (e) => {
		formik.handleChange(e);
		if (parseInt(e.target.value)) {
			setMemberCount(parseInt(e.target.value));
		} else {
			setMemberCount(0);
		}
		// setMemberCount(parseInt(e.target.value))
	};
	useEffect(() => {
		if (death.anyDeath == "death") {
			setDeathData("death");
		}
		const formMemberCount = formik.values.death.deathCount || 0;
		const repeaterForms = [...Array(formMemberCount).keys()];
		// const repeaterForms = [...Array(death.deathCount).keys()]
		console.log("death", death);
		setRepeaterForm(repeaterForms);
	}, [death.deathCount]);
	useEffect(() => {
		console.log(deathData);
	}, [deathData]);
	return (
		<>
			<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<div class="col-xl-12 col-xxl-8">
					<div
						class="pb-5"
						data-wizard-type="step-content"
						data-wizard-state="current"
					>
						<h3 class="mb-10 font-weight-bold text-dark">मृत्यु</h3>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{deathQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{anyDeath.map((val, i) => {
										console.log(val.value, death.anyDeath);
										return (
											<label class="radio radio-rounded">
												<input
													type="radio"
													name={`death.anyDeath`}
													value={val.value}
													onChange={handleDeathYesNo}
													defaultChecked={val.value === death.anyDeath}
												/>
												<span></span>
												{val[selectedLanguage]}
											</label>
										);
									})}
								</div>
							</div>
						</div>
						{deathData == "yes" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{deathQuestion[selectedLanguage]}</label>
									<input
										type="number"
										class="form-control"
										name="death.deathCount"
										onChange={handleDeathCountChange}
										value={death.deathCount}
									/>
								</div>
							</div>
						)}
						{deathData == "yes" &&
							repeaterForm.map((ind) => (
								<>
									<div className="row mb-7">
										<div className="col-lg-6">
											<div class="form-group">
												<label>
													{deathInYearReasonQuestion[selectedLanguage]}
												</label>
												<SingleSelectInput
													id="ifDisease"
													name={`death.deathReason${ind}`}
													value={death[`deathReason${ind}`]}
													formik={formik}
													selectList={deathReason}
												/>
											</div>
										</div>
										{death[`deathReason${ind}`] === "other" && (
											<div className="col-lg-6">
												<div class="form-group">
													<label>यदी अन्य भए ?</label>
													<input
														type="text"
														class="form-control"
														name={`death.deathReasonOther${ind}`}
														onChange={formik.handleChange}
														value={death[`deathReasonOther${ind}`]}
													/>
												</div>
											</div>
										)}
										<div className="col-lg-6">
											<div class="form-group">
												<label>{deathAge[selectedLanguage]}</label>
												<input
													type="number"
													class="form-control"
													name={`death.deathAge${ind}`}
													onChange={formik.handleChange}
													value={death[`deathAge${ind}`]}
												/>
											</div>
										</div>
									</div>
									<hr />
								</>
							))}
					</div>
				</div>
			</div>
		</>
	);
};

export default MrityuBibaran;
