import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import DeclareDeath from "../components/DeclareDeath";
import SingleSelectInput from "../components/SingleSelectInput";
// import { answererRelation, bloodgroup, casteList, marriage, mothertongue, occupationList, religionList } from '../data/advanceSearch';
import {
	visionDisablityQn,
	visionDisablity,
	hearingDisabilityQn,
	hearingDisability,
	diseaseMentalQuestion,
	isMalnutritionQn,
	isMalnutrition,
	isMarriedQn,
	isMarried,
	marriedAgeQn,
	childVaccinationQn,
	childVaccination,
	isDiseasedQn,
	isDiseased,
	ifDiseaseQn,
	ifDisease,
	isPregnantQn,
	isPregnant,
	childGenderQn,
	childGender,
	childCheckUpQn,
	trainingNameQn,
	publicAwarnessTrainingQn,
	publicAwarnessTraining,
	familyInLocalOrganQuestion,
	familyInvolvementLocalOrgan,
	postInLocalOrganQn,
	localOrganDetailQn,
	abroadCountry,
	abroadCountryQuestion,
	abroadReason,
	abroadReasonQuestion,
	abroadYearQuestion,
	ageQuestion,
	answererRelation,
	answererRelationQuestion,
	bloodgroup,
	bloodgroupQuestion,
	casteList,
	casteQuestion,
	contactNo,
	dateQuestion,
	disabilitytype,
	disabilitytypeQuestion,
	education,
	educationQuestion,
	firstMarriageAgeQn,
	gender,
	genderQuestion,
	healthcondition,
	healthconditionQuesiton,
	ifOtherValueQuestion,
	insuranceListQuestion,
	insuranceLists,
	insuranceQuestion,
	insuranceYesNo,
	isGoingToSchool,
	isGoingToSchoolQn,
	livingStatus,
	livingStatusQuestion,
	marriage,
	marriageQuestion,
	memberName,
	mentalCardQuestion,
	mentalCardtype,
	mentalCardTypeQuestion,
	mentalCardYesNo,
	mentaltype,
	mentaltypeQuestion,
	mentalyesno,
	mentalYesNoQuestion,
	mothertongue,
	mothertongueQuestion,
	notGoingSchool,
	notGoingSchoolQn,
	occupation,
	occupationQuestion,
	other,
	primary,
	primaryQuestion,
	religionList,
	religionQuestion,
	secondary,
	secondaryQuestion,
	skillDetail,
	skillDetailQuestion,
	skillJobNoList,
	skillJobNoQn,
	skillJobQn,
	skillJobYesNo,
	skillTraining,
	skillTrainingDurationQn,
	skillTrainingQuestion,
	skillYesNo,
	socialSecurityAllowanceList,
	socialSecurityAllowanceQn,
	studyField,
	studyFieldQn,
	totalMember,
	unhealthy,
	unhealthyQuestion,
	votercard,
	votercardQuestion,
	mentalSitutationQn,
	mentalSitutation,
} from "../data/houseSurvey";
import { selectedLanguage, ward, wardQuestion } from "../data/institute";

const PariwarikBibaran = (props) => {
	const [memberCount, setMemberCount] = useState(0);
	const [repeaterForm, setRepeaterForm] = useState([]);
	const [showDeclareDeath, setShowDeclareDeath] = useState(false);
	const [familyId, setFamilyId] = useState("");
	const { formik } = props;
	const { familyDetail } = formik.values;
	const handleFamilyCountChange = (e) => {
		formik.handleChange(e);
		if (parseInt(e.target.value)) {
			setMemberCount(parseInt(e.target.value));
		} else {
			setMemberCount(0);
		}
	};
	useEffect(() => {
		console.log(formik.values.totalMember);
		const formMemberCount = formik.values.totalMember || 0;
		const repeaterForms = [...Array(formMemberCount).keys()];
		setRepeaterForm(repeaterForms);
	}, [formik.values.totalMember]);
	useEffect(() => {
		if (familyDetail.totalMember > 0) {
			setMemberCount(familyDetail.totalMember);
			formik.setValues({
				...formik.values,
				totalMember: familyDetail.totalMember,
			});
		}
	}, []);
	const showDeathForm = (familyId) => {
		setShowDeclareDeath(true);
		setFamilyId(familyId);
	};
	const removeRepeaterForm = (index) => {
		var array = [...repeaterForm]; // make a separate copy of the array
		if (index !== -1) {
			array.splice(index, 1);
			setRepeaterForm(array);
			const newMemberCount = memberCount - 1;
			setMemberCount(newMemberCount);
		}
	};
	const getSingleFamilyMember = (ind) => (
		<>
			<div className="row">
				<div className="col-12 d-flex justify-content-end mb-4 align-items-center">
					<i className="flaticon2-trash mr-3"></i>
					<Button
						variant="light-danger"
						onClick={() => showDeathForm(familyDetail[`_familyId${ind}`])}
					>
						Declare death
					</Button>
				</div>
			</div>
			<div className="row mb-7">
				<div className="col-lg-6">
					<div class="form-group">
						<label>{memberName[selectedLanguage]}</label>
						<input
							type="text"
							class="form-control"
							name={`familyDetail.fullName${ind}`}
							placeholder={memberName[selectedLanguage]}
							onChange={formik.handleChange}
							value={familyDetail[`fullName${ind}`]}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{genderQuestion[selectedLanguage]}</label>
						<div class="radio-inline">
							{gender.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.gender${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={familyDetail[`gender${ind}`] == value.value}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
						{/* <SingleSelectInput
							id="gender"
							name={`familyDetail.gender${ind}`}
							value={familyDetail[`gender${ind}`]}
							formik={formik}
							selectList={gender}
						/> */}
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{ageQuestion[selectedLanguage]}</label>
						<input
							type="number"
							class="form-control"
							name={`familyDetail.age${ind}`}
							placeholder=""
							onChange={formik.handleChange}
							value={familyDetail[`age${ind}`]}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{casteQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="caste"
							name={`familyDetail.caste${ind}`}
							value={familyDetail[`caste${ind}`]}
							formik={formik}
							selectList={casteList}
						/>
					</div>
				</div>
				{familyDetail[`caste${ind}`] === "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.casteOther${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`casteOther${ind}`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{religionQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="religion"
							name={`familyDetail.religion${ind}`}
							value={familyDetail[`religion${ind}`]}
							formik={formik}
							selectList={religionList}
						/>
					</div>
				</div>
				{familyDetail[`religion${ind}`] === "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.religionOther${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`religionOther${ind}`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{mothertongueQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="mothertongue"
							name={`familyDetail.motherTongue${ind}`}
							value={familyDetail[`motherTongue${ind}`]}
							formik={formik}
							selectList={mothertongue}
						/>
					</div>
				</div>
				{familyDetail[`motherTongue${ind}`] === "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.motherTongueOther${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`motherTongueOther${ind}`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{educationQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="education"
							name={`familyDetail.educationLevel${ind}`}
							value={familyDetail[`educationLevel${ind}`]}
							formik={formik}
							selectList={education}
						/>
					</div>
				</div>

				<div className="col-lg-6">
					<div class="form-group">
						<label>{mentalYesNoQuestion[selectedLanguage]}</label>
						<div class="radio-inline">
							{mentalyesno.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.isDisable${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`isDisable${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`isDisable${ind}`] === "yes" && (
					<>
						{/* <div className="col-lg-6">
              <div class="form-group">
                <label>{mentaltypeQuestion[selectedLanguage]}</label>
                <select
                  className="form-control"
                  id="mentalType"
                  name={`familyDetail.mentaltype${ind}`}
                  onChange={formik.handleChange}
                  value={familyDetail[`mentaltype${ind}`]}
                >
                  {mentaltype.map((value) => (
                    <option value={value.value}>
                      {value[selectedLanguage]}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{disabilitytypeQuestion[selectedLanguage]}</label>
								<select
									className="form-control"
									id="disableType"
									name={`familyDetail.disableType${ind}`}
									onChange={formik.handleChange}
									value={familyDetail[`disableType${ind}`]}
								>
									{disabilitytype.map((value) => (
										<option value={value.value}>
											{value[selectedLanguage]}
										</option>
									))}
								</select>
							</div>
						</div>
						{familyDetail[`disableType${ind}`] === "2" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{visionDisablityQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="visionType"
										name={`familyDetail.visionType${ind}`}
										value={familyDetail[`visionType${ind}`]}
										formik={formik}
										selectList={visionDisablity}
									/>
								</div>
							</div>
						)}
						{familyDetail[`disableType${ind}`] === "3" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{hearingDisabilityQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="hearingType"
										name={`familyDetail.hearingType${ind}`}
										value={familyDetail[`hearingType${ind}`]}
										formik={formik}
										selectList={hearingDisability}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{mentalCardQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{mentalCardYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`familyDetail.hasCard${ind}`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													familyDetail[`hasCard${ind}`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>

						{familyDetail[`hasCard${ind}`] === "yes" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{mentalCardTypeQuestion[selectedLanguage]}</label>
									<select
										className="form-control"
										id="cardType"
										name={`familyDetail.cardType${ind}`}
										onChange={formik.handleChange}
										value={familyDetail[`cardType${ind}`]}
									>
										{mentalCardtype.map((value) => (
											<option value={value.value}>
												{value[selectedLanguage]}
											</option>
										))}
									</select>
								</div>
							</div>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{mentalSitutationQn[selectedLanguage]}</label>
								<SingleSelectInput
									id="condition"
									name={`familyDetail.condition${ind}`}
									value={familyDetail[`condition${ind}`]}
									formik={formik}
									selectList={mentalSitutation}
								/>
							</div>
						</div>
						{familyDetail[`condition${ind}`] === "3" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{diseaseMentalQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name={`familyDetail.diseaseTypeDetail${ind}`}
										onChange={formik.handleChange}
										placeholder=""
										value={familyDetail[`diseaseTypeDetail${ind}`]}
									/>
								</div>
							</div>
						)}
					</>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{occupationQuestion[selectedLanguage]}</label>
						<CustomSelectMulti
							formik={formik}
							value={familyDetail[`levelOneJob${ind}`]}
							isMulti={true}
							list={occupation}
							field={`familyDetail.levelOneJob${ind}`}
						/>
					</div>
				</div>
				{familyDetail[`levelOneJob${ind}`]?.includes("other") && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.levelOneJobOther${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`levelOneJobOther${ind}`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{isMalnutritionQn[selectedLanguage]}</label>
						<div class="radio-inline">
							{isMalnutrition.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.malnutrition${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`malnutrition${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{isMarriedQn[selectedLanguage]}</label>
						<div class="radio-inline">
							{isMarried.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.isMarried${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`isMarried${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`isMarried${ind}`] === "yes" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{marriedAgeQn[selectedLanguage]}</label>
							<input
								type="number"
								class="form-control"
								name={`familyDetail.marriageAge${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`marriageAge${ind}`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{childVaccinationQn[selectedLanguage]}</label>
						<CustomSelectMulti
							formik={formik}
							value={familyDetail[`childVaccination${ind}`]}
							isMulti={true}
							list={childVaccination}
							field={`familyDetail.childVaccination${ind}`}
						/>
					</div>
				</div>
				{familyDetail[`childVaccination${ind}`]?.includes("8") && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>यदी अन्य हो भने</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.childVaccinationOther${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`childVaccinationOther${ind}`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{isDiseasedQn[selectedLanguage]}</label>
						<div class="radio-inline">
							{isDiseased.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.isDisease${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`isDisease${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`isDisease${ind}`] === "yes" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{ifDiseaseQn[selectedLanguage]}</label>
							<CustomSelectMulti
								formik={formik}
								value={familyDetail[`unhealthyDisease${ind}`]}
								isMulti={true}
								list={ifDisease}
								field={`familyDetail.unhealthyDisease${ind}`}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{isPregnantQn[selectedLanguage]}</label>
						<div class="radio-inline">
							{isPregnant.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.isMaternity${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`isMaternity${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`isMaternity${ind}`] === "yes" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{childGenderQn[selectedLanguage]}</label>
							<SingleSelectInput
								id="childGender"
								name={`familyDetail.anyChildBirthInYear${ind}`}
								value={familyDetail[`anyChildBirthInYear${ind}`]}
								formik={formik}
								selectList={childGender}
							/>
						</div>
					</div>
				)}
				{(familyDetail[`anyChildBirthInYear${ind}`] === "1" ||
					familyDetail[`anyChildBirthInYear${ind}`] === "2") && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{childCheckUpQn[selectedLanguage]}</label>
							<input
								type="number"
								class="form-control"
								name={`familyDetail.childCheckup${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`childCheckup${ind}`]}
							/>
						</div>
					</div>
				)}

				<div className="col-lg-6">
					<div class="form-group">
						<label>{skillTrainingQuestion[selectedLanguage]}</label>
						<div class="radio-inline">
							{skillTraining.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.skillTraining${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`skillTraining${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`skillTraining${ind}`] === "yes" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{trainingNameQn[selectedLanguage]}</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.skillTitle${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`skillTitle${ind}`]}
							/>
						</div>
					</div>
				)}

				<div className="col-lg-6">
					<div class="form-group">
						<label>{publicAwarnessTrainingQn[selectedLanguage]}</label>
						<div class="radio-inline">
							{publicAwarnessTraining.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.hasPublicAwarenessTraining${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`hasPublicAwarenessTraining${ind}`] ==
											value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`hasPublicAwarenessTraining${ind}`] === "yes" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{trainingNameQn[selectedLanguage]}</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.publicAwarenessTrainingName${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`publicAwarenessTrainingName${ind}`]}
							/>
						</div>
					</div>
				)}

				<div className="col-lg-6">
					<div class="form-group">
						<label>{familyInLocalOrganQuestion[selectedLanguage]}</label>
						<div class="radio-inline">
							{familyInvolvementLocalOrgan.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.hasInvolveInOrganization${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`hasInvolveInOrganization${ind}`] ==
											value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`hasInvolveInOrganization${ind}`] === "yes" && (
					<>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{postInLocalOrganQn[selectedLanguage]}</label>
								<input
									type="text"
									class="form-control"
									name={`familyDetail.involveInOrgDesignation${ind}`}
									placeholder=""
									onChange={formik.handleChange}
									value={familyDetail[`involveInOrgDesignation${ind}`]}
								/>
							</div>
						</div>

						<div className="col-lg-6">
							<div class="form-group">
								<label>{localOrganDetailQn[selectedLanguage]}</label>
								<input
									type="text"
									class="form-control"
									name={`familyDetail.involveInOrganizationName${ind}`}
									placeholder=""
									onChange={formik.handleChange}
									value={familyDetail[`involveInOrganizationName${ind}`]}
								/>
							</div>
						</div>
					</>
				)}
			</div>
			<hr />
		</>
	);
	return (
		<>
			<DeclareDeath
				familyId={familyId}
				show={showDeclareDeath}
				setShow={setShowDeclareDeath}
			/>
			<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<div class="col-xl-12 col-xxl-8">
					<div
						class="pb-5"
						data-wizard-type="step-content"
						data-wizard-state="current"
					>
						<h3 class="mb-10 font-weight-bold text-dark">परिवार विवरण</h3>
						<div className="row">
							<div className="col-lg-6">
								<div class="form-group">
									<label>{totalMember[selectedLanguage]}</label>
									<input
										min="0"
										type="number"
										class="form-control"
										name="totalMember"
										onChange={(e) => handleFamilyCountChange(e)}
										placeholder={totalMember[selectedLanguage]}
										value={formik.values.totalMember}
									/>
								</div>
							</div>
						</div>
						{repeaterForm.map((ind) => (
							<Accordion defaultActiveKey={0}>
								<Card className="mb-2">
									<Card.Header>
										<Accordion.Toggle
											className="w-100 d-flex justify-content-between"
											as={Button}
											variant="light-primary"
											eventKey={ind}
											title="Click to expand/collapse"
										>
											<h5 className="mb-0">{familyDetail[`fullName${ind}`]}</h5>
										</Accordion.Toggle>
									</Card.Header>
									<Accordion.Collapse eventKey={ind}>
										<Card.Body>{getSingleFamilyMember(ind)}</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default PariwarikBibaran;
