import React, { useState } from "react";
import {
	drinkingwaterQuestion,
	drinkingwaterLists,
	cookingresourceLists,
	cookingresourceQuestion,
	houseFacility,
	houseFacilityQuestion,
	lightresourceQuestion,
	lightresourceLists,
	bankAccountFemale,
	bankAccountMale,
	bankaccountQuestion,
	bankaccountYesNo,
	car,
	drinkingwaterplaceLists,
	drinkingwaterplaceQuestion,
	houseMeter,
	houseMeterQn,
	ifOtherValueQuestion,
	motorcycle,
	noToiletLists,
	roadConditionHouse,
	roadConditionHouseQn,
	safetyTankCleaningIntervalTimeQn,
	salttypeLists,
	salttypeQuestion,
	scooter,
	toiletNoQuestion,
	toiletQuestion,
	toiletresourceLists,
	toilettypeQuestion,
	toiletYesNo,
	toiletYesNoUseQn,
	wasteProducedPerWeekQn,
	waterPurification,
	waterPurificationQn,
} from "../data/houseSurvey";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";

import {
	cookingStoveQn,
	cookingStove,
	wasteManagementQn,
	wasteManagement,
	wasteOrganicYesNoQn,
	wasteOrganic,
	houseRoofFarmQn,
	houseRoofFarm,
	roofFarmMalQn,
	roofFarmMal,
	roofFarmBisadiQn,
	roofFarmBisadi,
	drinkingWaterOtherQn,
	drinkingWaterOther,
	ifDauraQn,
	ifDaura,
	houseCommunicationQn,
	houseCommunication,
	toiletStatusQn,
	toiletStatus,
	homeCleaningStatusQn,
	homeCleaningStatus,
} from "../data/newHouseSurvey";

const MadhyamikGharForm = (props) => {
	const [toiletValue, settoiletValue] = useState(null);
	const { formik } = props;
	const { secondaryHouse } = formik.values;
	// const handleDeathYesNo = (e) => {
	//     console.log(e.target)
	//     setDeathData(e.target.value);
	// }
	// const handleDeathCountChange = (e) => {
	//     formik.handleChange(e)
	//     setMemberCount(parseInt(e.target.value))
	// }
	// useEffect(() => {
	//     const repeaterForms = [...Array(memberCount).keys()]
	//     console.log("death", death)
	//     setRepeaterForm(repeaterForms)
	// }, [memberCount])
	// useEffect(() => {
	//     console.log(deathData)
	// }, [deathData])
	const getToiletList = () => {
		switch (toiletValue) {
			case "yes":
				return (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{toilettypeQuestion[selectedLanguage]}</label>
							<SingleSelectInput
								id="toiletYes"
								name={`secondaryHouse.toiletYes`}
								value={secondaryHouse[`toiletYes`]}
								formik={formik}
								selectList={toiletresourceLists}
							/>
						</div>
					</div>
				);
				break;
			case "no":
				return (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{toiletNoQuestion[selectedLanguage]}</label>
							<SingleSelectInput
								id="toiletNo"
								name={`secondaryHouse.toiletNo`}
								value={secondaryHouse[`toiletNo`]}
								formik={formik}
								selectList={noToiletLists}
							/>
						</div>
					</div>
				);

			case "yes_but_not_us":
				return (
					<>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{toiletYesNoUseQn[selectedLanguage]}</label>
								<SingleSelectInput
									id="toiletYesButNoUse"
									name={`secondaryHouse.toiletYesButNoUse`}
									value={secondaryHouse[`toiletYesButNoUse`]}
									formik={formik}
									selectList={toiletYesNo}
								/>
							</div>
						</div>
						{secondaryHouse.toiletYesButNoUse == "other" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.toiletYesButNoUseOther"
										onChange={formik.handleChange}
										value={secondaryHouse.toiletYesButNoUseOther}
									/>
								</div>
							</div>
						)}
					</>
				);

			default:
				break;
		}
	};
	const setToiletOptions = (e) => {
		settoiletValue(e.target.value);
		formik.handleChange(e);
	};
	return (
		<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
			<div class="col-xl-12 col-xxl-8">
				<div
					class="pb-5"
					data-wizard-type="step-content"
					data-wizard-state="current"
				>
					<h3 class="mb-10 font-weight-bold text-dark">माध्यमिक घर विवरण</h3>
					<div className="row">
						<div className="col-lg-6">
							<div class="form-group">
								<label>{wasteManagementQn[selectedLanguage]}</label>
								<SingleSelectInput
									id="wasteDisposal"
									name={`secondaryHouse.wasteDisposal`}
									value={secondaryHouse[`wasteDisposal`]}
									formik={formik}
									selectList={wasteManagement}
								/>
							</div>
						</div>

						<div className="col-lg-6">
							<div class="form-group">
								<label>{wasteOrganicYesNoQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{wasteOrganic.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`secondaryHouse.organicInorganicWasteManaged`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													secondaryHouse.organicInorganicWasteManaged ===
													value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>

						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseRoofFarmQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{houseRoofFarm.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`secondaryHouse.hasCultivationInHome`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													secondaryHouse.hasCultivationInHome === value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{secondaryHouse[`hasCultivationInHome`] === "yes" && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{roofFarmMalQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="cultivationFertilizerUse"
											name={`secondaryHouse.cultivationFertilizerUse`}
											value={secondaryHouse[`cultivationFertilizerUse`]}
											formik={formik}
											selectList={roofFarmMal}
										/>
									</div>
								</div>

								<div className="col-lg-6">
									<div class="form-group">
										<label>{roofFarmBisadiQn[selectedLanguage]}</label>
										<div class="radio-inline">
											{roofFarmBisadi.map((value, i) => (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`secondaryHouse.cultivationInsecticides`}
														value={value.value}
														onChange={formik.handleChange}
														defaultChecked={
															secondaryHouse.cultivationInsecticides ===
															value.value
														}
													/>
													<span></span>
													{value[selectedLanguage]}
												</label>
											))}
										</div>
									</div>
								</div>
							</>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{drinkingwaterQuestion[selectedLanguage]}</label>
								<SingleSelectInput
									id="waterSource"
									name={`secondaryHouse.waterSource`}
									value={secondaryHouse[`waterSource`]}
									formik={formik}
									selectList={drinkingwaterLists}
								/>
							</div>
						</div>
						{secondaryHouse[`waterSource`] === "others" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{drinkingWaterOtherQn[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.waterSourceOther"
										onChange={formik.handleChange}
										value={secondaryHouse.waterSourceOther}
									/>
								</div>
							</div>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{cookingresourceQuestion[selectedLanguage]}</label>
								<CustomSelectMulti
									formik={formik}
									value={secondaryHouse[`cookingSource`]}
									isMulti={true}
									list={cookingresourceLists}
									field={`secondaryHouse.cookingSource`}
								/>
							</div>
						</div>
						{secondaryHouse.cookingSource?.includes("6") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.cookingSourceOther"
										onChange={formik.handleChange}
										value={secondaryHouse.cookingSourceOther}
									/>
								</div>
							</div>
						)}
						{secondaryHouse.cookingSource?.includes("1") && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{ifDauraQn[selectedLanguage]}</label>
										<CustomSelectMulti
											formik={formik}
											value={secondaryHouse[`woodSource`]}
											isMulti={true}
											list={ifDaura}
											field={`secondaryHouse.woodSource`}
										/>
									</div>
								</div>
								{secondaryHouse.woodSource?.includes("5") && (
									<div className="col-lg-6">
										<div class="form-group">
											<label>{ifOtherValueQuestion[selectedLanguage]}</label>
											<input
												type="text"
												class="form-control"
												name="secondaryHouse.woodSourceOther"
												onChange={formik.handleChange}
												value={secondaryHouse.woodSourceOther}
											/>
										</div>
									</div>
								)}
							</>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{cookingStoveQn[selectedLanguage]}</label>
								<CustomSelectMulti
									formik={formik}
									value={secondaryHouse[`stoveDetail`]}
									isMulti={true}
									list={cookingStove}
									field={`secondaryHouse.stoveDetail`}
								/>
							</div>
						</div>
						{secondaryHouse.stoveDetail?.includes("6") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.stoveDetailOther"
										onChange={formik.handleChange}
										value={secondaryHouse.stoveDetailOther}
									/>
								</div>
							</div>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{lightresourceQuestion[selectedLanguage]}</label>
								<CustomSelectMulti
									formik={formik}
									value={secondaryHouse[`electricSource`]}
									isMulti={true}
									list={lightresourceLists}
									field={`secondaryHouse.electricSource`}
								/>
							</div>
						</div>
						{secondaryHouse.electricSource?.includes("5") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.electricSourceOther"
										onChange={formik.handleChange}
										value={secondaryHouse.electricSourceOther}
									/>
								</div>
							</div>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseCommunicationQn[selectedLanguage]}</label>
								<CustomSelectMulti
									formik={formik}
									value={secondaryHouse[`communicationFacility`]}
									isMulti={true}
									list={houseCommunication}
									field={`secondaryHouse.communicationFacility`}
								/>
							</div>
						</div>
						{secondaryHouse.communicationFacility?.includes("other") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.communicationFacilityOther"
										onChange={formik.handleChange}
										value={secondaryHouse.communicationFacilityOther}
									/>
								</div>
							</div>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseFacilityQuestion[selectedLanguage]}</label>
								<CustomSelectMulti
									formik={formik}
									value={secondaryHouse[`houseStuff`]}
									isMulti={true}
									list={houseFacility}
									field={`secondaryHouse.houseStuff`}
								/>
							</div>
						</div>
						{secondaryHouse.houseStuff?.includes("12") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.houseStuffOther"
										onChange={formik.handleChange}
										value={secondaryHouse.houseStuffOther}
									/>
								</div>
							</div>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{toiletStatusQn[selectedLanguage]}</label>
								<CustomSelectMulti
									formik={formik}
									value={secondaryHouse[`toiletCondition`]}
									isMulti={true}
									list={toiletStatus}
									field={`secondaryHouse.toiletCondition`}
								/>
							</div>
						</div>
						{secondaryHouse.toiletCondition?.includes("6") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.toiletConditionOther"
										onChange={formik.handleChange}
										value={secondaryHouse.toiletConditionOther}
									/>
								</div>
							</div>
						)}

						<div className="col-lg-6">
							<div class="form-group">
								<label>{homeCleaningStatusQn[selectedLanguage]}</label>
								<CustomSelectMulti
									formik={formik}
									value={secondaryHouse[`houseAndPersonalSanitation`]}
									isMulti={true}
									list={homeCleaningStatus}
									field={`secondaryHouse.houseAndPersonalSanitation`}
								/>
							</div>
						</div>
						{secondaryHouse.houseAndPersonalSanitation?.includes("8") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="secondaryHouse.houseAndPersonalSanitationOther"
										onChange={formik.handleChange}
										value={secondaryHouse.houseAndPersonalSanitationOther}
									/>
								</div>
							</div>
						)}

						{/*  */}

						{/* <div className="col-lg-6">
              <div class="form-group">
                <label>{drinkingwaterplaceQuestion[selectedLanguage]}</label>
                <SingleSelectInput
                  id="distanceToWaterSource"
                  name={`secondaryHouse.distanceToWaterSource`}
                  value={secondaryHouse[`distanceToWaterSource`]}
                  formik={formik}
                  selectList={drinkingwaterplaceLists}
                />
              </div>
            </div> */}

						{/* <div className="col-lg-6">
              <div class="form-group">
                <label>{toiletQuestion[selectedLanguage]}</label>
                <div class="radio-inline">
                  {toiletYesNo.map((value, i) => (
                    <label class="radio radio-rounded">
                      <input
                        type="radio"
                        name={`secondaryHouse.isToilet`}
                        value={value.value}
                        onChange={setToiletOptions}
                        defaultChecked={secondaryHouse.isToilet === value.value}
                      />
                      <span></span>
                      {value[selectedLanguage]}
                    </label>
                  ))}
                </div>
              </div>
            </div> */}

						{/* {getToiletList()}
            <div className="col-lg-6">
              <div class="form-group">
                <label>{bankaccountQuestion[selectedLanguage]}</label>
                <div class="radio-inline">
                  {bankaccountYesNo.map((value, i) => (
                    <label class="radio radio-rounded">
                      <input
                        type="radio"
                        name={`secondaryHouse.bankAccount`}
                        value={value.value}
                        onChange={formik.handleChange}
                        defaultChecked={
                          secondaryHouse.bankAccount === value.value
                        }
                      />
                      <span></span>
                      {value[selectedLanguage]}
                    </label>
                  ))}
                </div>
              </div>
            </div> */}
						{/* {secondaryHouse.bankAccount == "account_yes" && (
              <>
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>{bankAccountMale[selectedLanguage]}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="secondaryHouse.bankAccountMale"
                      onChange={formik.handleChange}
                      value={secondaryHouse.bankAccountMale}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>{bankAccountFemale[selectedLanguage]}</label>
                    <input
                      type="number"
                      class="form-control"
                      name="secondaryHouse.bankAccountFemale"
                      onChange={formik.handleChange}
                      value={secondaryHouse.bankAccountFemale}
                    />
                  </div>
                </div>
              </>
            )} */}
						{/* <div className="col-lg-6">
                            <div class="form-group">
                                <label>{salttypeQuestion[selectedLanguage]}</label>
                                <div class="radio-inline">
                                    {
                                        salttypeLists.map((value, i) => (
                                            <label class="radio radio-rounded">
                                                <input type="radio" name={`secondaryHouse.saltUsed`} value={value.value} onChange={formik.handleChange} defaultChecked={secondaryHouse.saltUsed === value.value} />
                                                <span></span>
                                                {value[selectedLanguage]}
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                        </div> */}

						{/* {secondaryHouse.houseFacility?.includes("car") && (
              <div className="col-lg-6">
                <div class="form-group">
                  <label>{car[selectedLanguage]}</label>
                  <input
                    type="number"
                    class="form-control"
                    name="secondaryHouse.car"
                    onChange={formik.handleChange}
                    value={secondaryHouse.car}
                  />
                </div>
              </div>
            )} */}
						{/* {secondaryHouse.houseFacility?.includes("scooter") && (
              <div className="col-lg-6">
                <div class="form-group">
                  <label>{scooter[selectedLanguage]}</label>
                  <input
                    type="number"
                    class="form-control"
                    name="secondaryHouse.scooter"
                    onChange={formik.handleChange}
                    value={secondaryHouse.scooter}
                  />
                </div>
              </div>
            )} */}
						{/* {secondaryHouse.houseFacility?.includes("motorcycle") && (
              <div className="col-lg-6">
                <div class="form-group">
                  <label>{motorcycle[selectedLanguage]}</label>
                  <input
                    type="number"
                    class="form-control"
                    name="secondaryHouse.motorcycle"
                    onChange={formik.handleChange}
                    value={secondaryHouse.motorcycle}
                  />
                </div>
              </div>
            )} */}
						{/* <div className="col-lg-6">
              <div class="form-group">
                <label>{houseMeterQn[selectedLanguage]}</label>
                <div class="radio-inline">
                  {houseMeter.map((value, i) => (
                    <label class="radio radio-rounded">
                      <input
                        type="radio"
                        name={`secondaryHouse.isElectricityMeter`}
                        value={value.value}
                        onChange={formik.handleChange}
                        defaultChecked={
                          secondaryHouse.isElectricityMeter === value.value
                        }
                      />
                      <span></span>
                      {value[selectedLanguage]}
                    </label>
                  ))}
                </div>
              </div>
            </div> */}
						{/* <div className="col-lg-6">
              <div class="form-group">
                <label>
                  {safetyTankCleaningIntervalTimeQn[selectedLanguage]}
                </label>
                <input
                  type="number"
                  class="form-control"
                  name="secondaryHouse.safetyTankCleaningIntervalTime"
                  onChange={formik.handleChange}
                  value={secondaryHouse.safetyTankCleaningIntervalTime}
                />
              </div>
            </div> */}
						{/* <div className="col-lg-6">
              <div class="form-group">
                <label>{wasteProducedPerWeekQn[selectedLanguage]}</label>
                <input
                  type="number"
                  class="form-control"
                  name="secondaryHouse.wasteProducedPerWeek"
                  onChange={formik.handleChange}
                  value={secondaryHouse.wasteProducedPerWeek}
                />
              </div>
            </div> */}
						{/* <div className="col-lg-6">
              <div class="form-group">
                <label>{roadConditionHouseQn[selectedLanguage]}</label>
                <SingleSelectInput
                  id="roadConditionHouse"
                  name="secondaryHouse.road"
                  value={secondaryHouse.road}
                  formik={formik}
                  selectList={roadConditionHouse}
                />
              </div>
            </div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MadhyamikGharForm;
