import React, { useEffect } from 'react'
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { baseURLAPI } from '../../../redux/axiosHandler';
import { livingType, toleWardSix, } from '../../data/houseSurvey';
import { toleWardEight, toleWardEighteen, toleWardEleven, toleWardFifteen, toleWardFive, toleWardFour, toleWardFourteen, toleWardNine, toleWardNineteen, toleWardOne, toleWardSeven, toleWardSeventeen, toleWardSixteen, toleWardTen, toleWardThirteen, toleWardThree, toleWardTwelve, toleWardTwo } from '../../data/ToleList';
import { toAbsoluteUrl } from "../../../../src/_metronic/_helpers/AssetsHelpers";
import { selectedLanguage } from '../../data/institute';

const GharKoParichaya = ({ houseDetails }) => {
    const [toleName, setToleName] = useState(undefined)
    useEffect(() => {
        switch (houseDetails.wardNumber) {
            case 1:
                setToleName(toleWardOne.find(i => i.value === houseDetails.toleName) ? toleWardOne.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 2:
                setToleName(toleWardTwo.find(i => i.value === houseDetails.toleName) ? toleWardTwo.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 3:
                setToleName(toleWardThree.find(i => i.value === houseDetails.toleName) ? toleWardThree.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 4:
                setToleName(toleWardFour.find(i => i.value === houseDetails.toleName) ? toleWardFour.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 5:
                setToleName(toleWardFive.find(i => i.value === houseDetails.toleName) ? toleWardFive.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 6:
                setToleName(toleWardSix.find(i => i.value === houseDetails.toleName) ? toleWardSix.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 7:
                setToleName(toleWardSeven.find(i => i.value === houseDetails.toleName) ? toleWardSeven.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 8:
                setToleName(toleWardEight.find(i => i.value === houseDetails.toleName) ? toleWardEight.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 9:
                setToleName(toleWardNine.find(i => i.value === houseDetails.toleName) ? toleWardNine.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 10:
                setToleName(toleWardTen.find(i => i.value === houseDetails.toleName) ? toleWardTen.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 11:
                setToleName(toleWardEleven.find(i => i.value === houseDetails.toleName) ? toleWardEleven.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 12:
                setToleName(toleWardTwelve.find(i => i.value === houseDetails.toleName) ? toleWardTwelve.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 13:
                setToleName(toleWardThirteen.find(i => i.value === houseDetails.toleName) ? toleWardThirteen.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 14:
                setToleName(toleWardFourteen.find(i => i.value === houseDetails.toleName) ? toleWardFourteen.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 15:
                setToleName(toleWardFifteen.find(i => i.value === houseDetails.toleName) ? toleWardFifteen.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 16:
                setToleName(toleWardSixteen.find(i => i.value === houseDetails.toleName) ? toleWardSixteen.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 17:
                setToleName(toleWardSeventeen.find(i => i.value === houseDetails.toleName) ? toleWardSeventeen.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 18:
                setToleName(toleWardEighteen.find(i => i.value === houseDetails.toleName) ? toleWardEighteen.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            case 19:
                setToleName(toleWardNineteen.find(i => i.value === houseDetails.toleName) ? toleWardNineteen.find(i => i.value === houseDetails.toleName)[selectedLanguage] : "")
                break;

            default:
                break;
        }
    }, [])
    return (

        <Card className="mb-5" style={{ minHeight: "20vh" }}>
            <Card.Body>
                <Card.Title>
                    <h3>घरको परिचय</h3>
                </Card.Title>
                <div className="row">
                    <div className="col-6 col-lg-5">
                        <img className="house-image mb-3" src={`${baseURLAPI}/imageUpload/image/${houseDetails.houseId}_house.jpg`} alt=""
                            onError={(event) => {
                                event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
                                event.onerror = null;
                            }} />
                    </div>
                    <div className="col-6 col-lg-7">
                        <div className="detail-content">

                            <div className="row">

                                <div className="col-lg-7 mb-3">
                                    <h6>वडा नं <span>: {houseDetails.wardNumber}</span></h6>
                                    {/* <h6>साविक गा.वि.स : <span>{houseDetails.}</span></h6> */}
                                    <h6>टोल वा वस्तीको नाम : <span>{houseDetails.toleName}</span></h6>
                                    <h6>घरमुलीको सम्पर्क नम्बर : <span>{houseDetails.houseOwnerMobile}</span></h6>
                                    <h6>घरमुलीको पुरा नाम : <span>{houseDetails.houseOwner}</span></h6>
                                    <h6>लिङ्ग : <span>{houseDetails.selfHouseOwnGender}</span></h6>
                                    <h6>घरमुलीको उमेर: <span>{houseDetails.selfHouseOwnAge}</span></h6>
                                    {/* <h6>परिवारको यहाको बसोबास स्थिती : <span>{livingType.find(i => i.value == houseDetails.livingType) ? livingType.find(i => i.value == houseDetails.livingType)[selectedLanguage] : ""}</span></h6> */}
                                </div>
                                <div className="col-lg-5  mb-3">
                                    <h6>घर नं <span>: {houseDetails.houseNumber}</span></h6>
                                    <h6>साविक वडा नं: <span> {houseDetails.wardNumber}</span></h6>
                                    {/* <h6>टोल संकेत नं: <span> {houseDetails.}</span></h6> */}

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h3>परिवार सदस्य  जानकारी</h3>
                                </div>
                                <div className="col-lg-4 d-flex align-items-center mb-2">
                                    <img height="40" className="mr-3" src={toAbsoluteUrl("/media/svg/hamropalika-icons/Male.svg")} alt="" />
                                    <h6>पुरुष : {houseDetails.maleNumber}</h6>
                                </div>
                                <div className="col-lg-4 d-flex align-items-center mb-2">
                                    <img height="40" className="mr-3" src={toAbsoluteUrl("/media/svg/hamropalika-icons/assistant.svg")} alt="" />
                                    <h6>महिला : {houseDetails.femaleNumber}</h6>
                                </div>
                                <div className="col-lg-4 d-flex align-items-center mb-2">
                                    <h6 className="ml-18">जम्मा : {houseDetails.totalFamilyNumber}</h6>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Card.Body>

        </Card>
    );
}

export default GharKoParichaya;