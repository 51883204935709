export const TotalKittaOfLandQn = {
	english: "",
	nepali: "कित्ता नं. कति हो?",
};
export const landFamilyMember = [
	{
		english: "",
		nepali: "छैन",
		value: "1",
	},
	{
		english: "",
		nepali: "ऐलेनी मात्र छ",
		value: "2",
	},
	{
		english: "",
		nepali: "नम्वरी मात्र छ",
		value: "3",
	},
	{
		english: "",
		nepali: "दुवै छ",
		value: "4",
	},
];

export const howManyAffectedTotalQn = {
	english: "",
	nepali: "क्षती  भएको थियो भने कति जना प्रभवित  हुनु भएको थियो ?",
};