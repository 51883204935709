import React from "react";
import { Table } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { selectedLanguage } from "../data/institute";

const PopulationDetailTable = ({ population }) => {
	console.log(population);
	const icons = [
		"home_icon.png",
		"people_icon.png",
		"male_icon.png",
		"female_icon.png",
		"male_female_icon.png",
		"",
	];
	const displayData = population.map((pop, i) => {
		return {
			title: pop.title,
			icon: icons[i],
			count: pop.count,
		};
	});
	return (
		<>
			<Table>
				<tbody>
					{displayData.map((datum) => (
						<tr>
							<td>
								{datum.icon !== "" && (
									<img
										height="40"
										width="40"
										src={toAbsoluteUrl(
											`/media/hamropalika/png-icons/${datum.icon}`
										)}
										alt=""
									/>
								)}
								{/* <i className={`icon-xl ${datum.icon}`}></i> */}
							</td>
							<td className="font-weight-bolder">
								{datum.title[selectedLanguage]}
							</td>
							<td className="font-weight-bolder">{datum.count}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
};

export default PopulationDetailTable;
