import axiosHandler from "../../../redux/axiosHandler";

export function getReportData(base, detailName) {
	return axiosHandler.get(`/analysis/${base}/${detailName}`);
}

export function getTableData() {
	return axiosHandler.get(`/house/list/0`);
}
export function getHouseById(id) {
	return axiosHandler.get(`/house/listhouse/${id}`);
}
export function getHouseDetailsById(id) {
	return axiosHandler.get(`/searchhouseById/housesearchId/${id}`);
}
export function checkIfHouseExists(id) {
	return axiosHandler.get(`/house/isHouseNumberExist/${id}`);
}
export function homeSurveyDataPost(data) {
	return axiosHandler.post(`/housesurvey/survey`, data);
}
export function deleteHouseById(id) {
	return axiosHandler.get(`/house/delete/${id}`);
}
export function getAnalysisData(detail, body) {
	return axiosHandler.post(`/analysis/generic/${detail}`, body);
}
export function DeclareDeathSave(deathDetails) {
	return axiosHandler.post("/house/memberDeath", deathDetails);
}

// export function getLogData(page, limit) {
// 	return axiosHandler.get(`/log/all/${page}/${limit}`);
// }

export function getLogData() {
	return axiosHandler.get("/log/all");
}

export function getUsersListData() {
	return axiosHandler.get("/users/list");
}

export function deleteUsersById(id) {
	return axiosHandler.post(`/users/remove`, { userId: id });
}

// export const deleteUsersById = (id) => {
// 	console.log("deleteId", id);
// 	return axiosHandler
// 		.post("/users/remove", id)
// 		.then((res) => {
// 			console.log("res", res.data);
// 			return Promise.resolve(true);
// 		})
// 		.catch((err) => {
// 			return Promise.reject(false);
// 		});
// };
