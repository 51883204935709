import React, { useState } from "react";
import { useEffect } from "react";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";
import { ifOtherValueQuestion } from "../data/houseSurvey";
import {
	naturalDisasterPiditQn,
	naturalDisasterPidit,
	whichDisasterPiditQn,
	harmTypeQn,
	harmType,
	howManyHumanAffectedQn,
	howMuchDhanAffectedQn,
	whereGoingIfSickQn,
	whereGoingIfSick,
	whereGoingFamilyDeliveryQn,
	whereGoingFamilyDelivery,
	childrenGoingSchoolQn,
	childrenGoingSchool,
	whichSaltUsingQn,
	whichSaltUsing,
	whereGoingSchoolQn,
	whereGoingSchool,
	isNotMentalChildMaleQn,
	isNotMentalChildMale,
	isMentalChildMaleQn,
	isMentalChildFemaleQn,
	isNotMentalChildFemaleQn,
	mentalChild,
	reasonNotGoingSchoolQn,
	playGroupQn,
	prePrimaryQn,
	primaryQn,
	schoolReachingTime,
	wardQn,
	serviceCenterQn,
	palikaQn,
	districtSadarmukanQn,
	communityQn,
	healthQn,
	hospitalQn,
	officeReachingTime,
	houseDecisionQn,
	houseWorkQn,
	bankRelatedQn,
	consumerQn,
	businessRelatedQn,
	schoolActivitiesQn,
	houseExpenseDecisionQn,
	maleFemaleOption,
} from "../data/newHouseSurvey";

import { howManyAffectedTotalQn } from "../data/demo";
import { selectedLanguage } from "../data/institute";

const SamajikForm = (props) => {
	useEffect(() => {}, []);
	console.log("form props", props);
	const { formik } = props;
	const { socialWelfare } = formik.values;
	return (
		<>
			<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<div class="col-xl-12 col-xxl-8">
					<div
						class="pb-5"
						data-wizard-type="step-content"
						data-wizard-state="current"
					>
						<h3 class="mb-10 font-weight-bold text-dark">सामाजिक विवरण</h3>
						<div className="row">
							<div className="col-lg-12">
								<div class="form-group">
									<label>{naturalDisasterPiditQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{naturalDisasterPidit.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.isVictim`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.isVictim
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>
							{socialWelfare[`isVictim`] === "yes" && (
								<>
									<div className="col-lg-6">
										<div class="form-group">
											<label>{whichDisasterPiditQn[selectedLanguage]}</label>
											<input
												type="text"
												class="form-control"
												name={`socialWelfare.disasterType`}
												onChange={formik.handleChange}
												value={socialWelfare[`disasterType`]}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{harmTypeQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={socialWelfare.damageType}
												isMulti={true}
												list={harmType}
												field={`socialWelfare.damageType`}
											/>
										</div>
									</div>
									{socialWelfare[`damageType`] === "3" && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{ifOtherValueQuestion[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name={`socialWelfare.damageTypeOther`}
													onChange={formik.handleChange}
													value={socialWelfare[`damageTypeOther`]}
												/>
											</div>
										</div>
									)}

									{socialWelfare[`damageType`] === "1" && (
										<>
											<div className="col-lg-6">
												<div class="form-group">
													<label>
														{howManyAffectedTotalQn[selectedLanguage]}
													</label>
													<input
														type="number"
														class="form-control"
														name={`socialWelfare.effectFromDamage`}
														onChange={formik.handleChange}
														value={socialWelfare[`effectFromDamage`]}
													/>
												</div>
											</div>

											<div className="col-lg-6">
												<div class="form-group">
													<label>
														{howManyHumanAffectedQn[selectedLanguage]}
													</label>
													<input
														type="number"
														class="form-control"
														name={`socialWelfare.memberInjured`}
														onChange={formik.handleChange}
														value={socialWelfare[`memberInjured`]}
													/>
												</div>
											</div>
										</>
									)}

									{socialWelfare[`damageType`] === "2" && (
										<div className="col-lg-6">
											<div class="form-group">
												<label>{howMuchDhanAffectedQn[selectedLanguage]}</label>
												<input
													type="text"
													class="form-control"
													name={`socialWelfare.propertyDamage`}
													onChange={formik.handleChange}
													value={socialWelfare[`propertyDamage`]}
												/>
											</div>
										</div>
									)}
								</>
							)}

							<div className="col-lg-6">
								<div class="form-group">
									<label>{whereGoingIfSickQn[selectedLanguage]}</label>
									<CustomSelectMulti
										formik={formik}
										value={socialWelfare[`illTreament`]}
										isMulti={true}
										list={whereGoingIfSick}
										field={`socialWelfare.illTreament`}
									/>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{whereGoingFamilyDeliveryQn[selectedLanguage]}</label>
									<CustomSelectMulti
										formik={formik}
										value={socialWelfare[`deliveryTakingPlace`]}
										isMulti={true}
										list={whereGoingFamilyDelivery}
										field={`socialWelfare.deliveryTakingPlace`}
									/>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{whichSaltUsingQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="whichSaltUsing"
										name="socialWelfare.saltUsed"
										value={socialWelfare.saltUsed}
										formik={formik}
										selectList={whichSaltUsing}
									/>
								</div>
							</div>

							<div className="col-lg-12">
								<div class="form-group">
									<label>{childrenGoingSchoolQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{childrenGoingSchool.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.isChildGoingSchool`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.isChildGoingSchool
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>
							{socialWelfare[`isChildGoingSchool`] === "yes" && (
								<>
									<div className="col-lg-6">
										<div class="form-group">
											<label>{whereGoingSchoolQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={socialWelfare[`studyingWhere`]}
												isMulti={true}
												list={whereGoingSchool}
												field={`socialWelfare.studyingWhere`}
											/>
										</div>
									</div>

									<div className="col-lg-12">
										<h3 class="mb-10 font-weight-bold text-dark">
											विद्यालय जान लाग्ने समयः
										</h3>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{playGroupQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="kinderGarden"
												name="socialWelfare.kinderGarden"
												value={socialWelfare.kinderGarden}
												formik={formik}
												selectList={schoolReachingTime}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{prePrimaryQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="primary"
												name="socialWelfare.primary"
												value={socialWelfare.primary}
												formik={formik}
												selectList={schoolReachingTime}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{primaryQn[selectedLanguage]}</label>
											<SingleSelectInput
												id="secondary"
												name="socialWelfare.secondary"
												value={socialWelfare.secondary}
												formik={formik}
												selectList={schoolReachingTime}
											/>
										</div>
									</div>
								</>
							)}

							{socialWelfare[`isChildGoingSchool`] === "no" && (
								<>
									<div className="col-lg-12">
										<h3 class="mb-10 font-weight-bold text-dark">
											परिवारमा विद्यालय भर्ना नभएका बालक |
										</h3>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{isNotMentalChildMaleQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={socialWelfare[`maleNotDisable`]}
												isMulti={true}
												list={isNotMentalChildMale}
												field={`socialWelfare.maleNotDisable`}
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div class="form-group">
											<label>{isMentalChildMaleQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={socialWelfare[`maleDisable`]}
												isMulti={true}
												list={mentalChild}
												field={`socialWelfare.maleDisable`}
											/>
										</div>
									</div>

									<div className="col-lg-12">
										<h3 class="mb-10 font-weight-bold text-dark">
											{" "}
											परिवारमा विद्यालय भर्ना नभएका बालिका |
										</h3>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>
												{isNotMentalChildFemaleQn[selectedLanguage]}
											</label>
											<CustomSelectMulti
												formik={formik}
												value={socialWelfare[`femaleNotDisable`]}
												isMulti={true}
												list={mentalChild}
												field={`socialWelfare.femaleNotDisable`}
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div class="form-group">
											<label>{isMentalChildFemaleQn[selectedLanguage]}</label>
											<CustomSelectMulti
												formik={formik}
												value={socialWelfare[`femaleDisable`]}
												isMulti={true}
												list={mentalChild}
												field={`socialWelfare.femaleDisable`}
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div class="form-group">
											<label>{reasonNotGoingSchoolQn[selectedLanguage]}</label>
											<input
												type="text"
												class="form-control"
												name={`socialWelfare.reasonForLeaving`}
												onChange={formik.handleChange}
												value={socialWelfare[`reasonForLeaving`]}
											/>
										</div>
									</div>
								</>
							)}

							<div className="col-lg-12">
								<h3 class="mb-10 font-weight-bold text-dark">
									सार्वजनिक सेवा प्राप्त गर्न लाग्‍ने समय
								</h3>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{wardQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="wardOffice"
										name="socialWelfare.wardOffice"
										value={socialWelfare.wardOffice}
										formik={formik}
										selectList={officeReachingTime}
									/>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{serviceCenterQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="nearByService"
										name="socialWelfare.nearByService"
										value={socialWelfare.nearByService}
										formik={formik}
										selectList={officeReachingTime}
									/>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{palikaQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="municipalityOffice"
										name="socialWelfare.municipalityOffice"
										value={socialWelfare.municipalityOffice}
										formik={formik}
										selectList={officeReachingTime}
									/>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{districtSadarmukanQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="districtHeadquarter"
										name="socialWelfare.districtHeadquarter"
										value={socialWelfare.districtHeadquarter}
										formik={formik}
										selectList={officeReachingTime}
									/>
								</div>
							</div>

							<div className="col-lg-12">
								<h3 class="mb-10 font-weight-bold text-dark">
									स्वास्थ्य चौकी जान लाग्ने समयः
								</h3>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{communityQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="communityHealthPost"
										name="socialWelfare.communityHealthPost"
										value={socialWelfare.communityHealthPost}
										formik={formik}
										selectList={officeReachingTime}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{healthQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="healthPost"
										name="socialWelfare.healthPost"
										value={socialWelfare.healthPost}
										formik={formik}
										selectList={officeReachingTime}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{hospitalQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="hospital"
										name="socialWelfare.hospital"
										value={socialWelfare.hospital}
										formik={formik}
										selectList={officeReachingTime}
									/>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{districtSadarmukanQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="healthPostDistrictHeadquarter"
										name="socialWelfare.healthPostDistrictHeadquarter"
										value={socialWelfare.healthPostDistrictHeadquarter}
										formik={formik}
										selectList={officeReachingTime}
									/>
								</div>
							</div>

							<div className="col-lg-12">
								<h3 class="mb-10 font-weight-bold text-dark">
									तपाइको परिवारमा निम्न कार्यहरु प्राय कसले गर्छ ?
								</h3>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseDecisionQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{maleFemaleOption.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.importanceDecision`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.importanceDecision
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseWorkQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{maleFemaleOption.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.householdWork`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.householdWork
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{bankRelatedQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{maleFemaleOption.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.bankAccountMgmt`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.bankAccountMgmt
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{consumerQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{maleFemaleOption.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.consumerCommittee`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.consumerCommittee
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{businessRelatedQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{maleFemaleOption.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.involveInIndustry`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.involveInIndustry
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{schoolActivitiesQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{maleFemaleOption.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.schoolManagement`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.schoolManagement
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseExpenseDecisionQn[selectedLanguage]}</label>
									<div class="radio-inline">
										{maleFemaleOption.map((val, i) => {
											return (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`socialWelfare.expenseDecision`}
														value={val.value}
														onChange={formik.handleChange}
														defaultChecked={
															val.value === socialWelfare.expenseDecision
														}
													/>
													<span></span>
													{val[selectedLanguage]}
												</label>
											);
										})}
									</div>
								</div>
							</div>

							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{unhealthyTreatmentQn[selectedLanguage]}</label>
                  <SingleSelectInput
                    id="unhealthyTreatment"
                    name="socialWelfare.unhealthyTreatment"
                    value={socialWelfare.unhealthyTreatment}
                    formik={formik}
                    selectList={unhealthyTreatment}
                  />
                </div>
              </div> */}

							{/* {socialWelfare[`unhealthyTreatment`] === "others_ill" && (
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                    <input
                      type="text"
                      class="form-control"
                      name={`socialWelfare.unhealthyTreatmentOther`}
                      onChange={formik.handleChange}
                      value={socialWelfare[`unhealthyTreatmentOther`]}
                    />
                  </div>
                </div>
              )} */}
							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{familyInOrgQn[selectedLanguage]}</label>
                  <CustomSelectMulti
                    formik={formik}
                    value={socialWelfare[`familyInOrg`]}
                    isMulti={true}
                    list={familyInOrg}
                    field={`socialWelfare.familyInOrg`}
                  />
                </div>
              </div> */}
							{/* {socialWelfare[`familyInOrg`]?.includes("others_club") && (
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                    <input
                      type="text"
                      class="form-control"
                      name={`socialWelfare.familyInOrgOther`}
                      onChange={formik.handleChange}
                      value={socialWelfare[`familyInOrgOther`]}
                    />
                  </div>
                </div>
              )} */}
							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{disputeResolveQn[selectedLanguage]}</label>
                  <SingleSelectInput
                    id="disputeResolve"
                    name="socialWelfare.disputeResolve"
                    value={socialWelfare.disputeResolve}
                    formik={formik}
                    selectList={disputeResolve}
                  />
                </div>
              </div> */}
							{/* {socialWelfare[`disputeResolve`] === "other_complain" && (
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                    <input
                      type="text"
                      class="form-control"
                      name={`socialWelfare.disputeResolveOther`}
                      onChange={formik.handleChange}
                      value={socialWelfare[`disputeResolveOther`]}
                    />
                  </div>
                </div>
              )} */}
							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{covidEffectQn[selectedLanguage]}</label>
                  <SingleSelectInput
                    id="covidEffect"
                    name="socialWelfare.covidEffect"
                    value={socialWelfare.covidEffect}
                    formik={formik}
                    selectList={covidEffect}
                  />
                </div>
              </div> */}

							{/* {socialWelfare[`covidEffect`] === "others" && (
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                    <input
                      type="text"
                      class="form-control"
                      name={`socialWelfare.covidEffectOther`}
                      onChange={formik.handleChange}
                      value={socialWelfare[`covidEffectOther`]}
                    />
                  </div>
                </div>
              )} */}
							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{suggestionQn[selectedLanguage]}</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    onChange={formik.handleChange}
                    name="socialWelfare.suggestion"
                    value={socialWelfare.suggestion}
                  />
                </div>
              </div> */}
							{/* <div className="col-lg-6">
                <div class="form-group">
                  <label>{newsMediumQn[selectedLanguage]}</label>
                  <CustomSelectMulti
                    formik={formik}
                    value={socialWelfare[`newsMedium`]}
                    isMulti={true}
                    list={newsMedium}
                    field={`socialWelfare.newsMedium`}
                  />
                </div>
              </div> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SamajikForm;
